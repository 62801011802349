import axios from 'axios';
import { backEndHost } from './ServerTools';

export function queryUSTreasuryYield() {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/economy/us_treasury`;
    axios.get(path).then((result) => {
      const year1 = [];
      const year3 = [];
      const year5 = [];
      const year10 = [];
      result.data.data.forEach((it) => {
        year1.push([it.date, it['1year']]);
        year3.push([it.date, it['3year']]);
        year5.push([it.date, it['5year']]);
        year10.push([it.date, it['10year']]);
      });
      resolve([year1, year3, year5, year10]);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function queryCNTreasuryYield() {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/economy/cn_treasury`;
    axios.get(path).then((result) => {
      const year1 = [];
      const year3 = [];
      const year5 = [];
      const year10 = [];
      result.data.data.forEach((it) => {
        year1.push([it.date, it['1year']]);
        year3.push([it.date, it['3year']]);
        year5.push([it.date, it['5year']]);
        year10.push([it.date, it['10year']]);
      });
      resolve([year1, year3, year5, year10]);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function queryChinaAFRE() {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/economy/china_afre`;
    axios.get(path).then((result) => {
      const stock = [];
      const flow = [];
      const growthRate = [];
      const rmbLoans = [];
      result.data.data.forEach((it) => {
        stock.push([it.date, it.stock]);
        flow.push([it.date, it.flow]);
        growthRate.push([it.date, it.growthRate]);
        rmbLoans.push([it.date, it.rmb_loans]);
      });
      resolve([stock, flow, growthRate, rmbLoans]);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function queryChinaFinancing() {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/economy/china_financing`;
    axios.get(path).then((result) => {
      const m0 = [];
      const m1 = [];
      const m2 = [];
      const m0GrowthRate = [];
      const m1GrowthRate = [];
      const m2GrowthRate = [];
      result.data.data.forEach((it) => {
        m0.push([it.date, it.m0]);
        m1.push([it.date, it.m1]);
        m2.push([it.date, it.m2]);
        m0GrowthRate.push([it.date, it.m0_yoy]);
        m1GrowthRate.push([it.date, it.m1_yoy]);
        m2GrowthRate.push([it.date, it.m2_yoy]);
      });
      resolve([m0, m1, m2, m0GrowthRate, m1GrowthRate, m2GrowthRate]);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}
