<template>
  <div style="height: 100%; max-height: 100%; padding: 4px;" class="flxcolumn">
    <div class="flxcolumn flx1" style="height: 0" >

      <div class="flxrow">
        <div style="width: 50%;">
          <!---------------- basic ---------------->
          <div class="chart-title title-dec pad">Basic</div>
          <div class="flxrow vtaligncenter commargin2">
            <!-- Category -->
            <div class="label">Type: </div>
            <a-select v-model="journal.type"
                :options="typeOptions" placeholder="Please select"
                style="width: 140px; margin-left: 18px;"
                @change="onSelectChange"
                >
            </a-select>
            <div class="label" style="margin-left: 20px;">Occur: </div>
            <a-date-picker style="width: 240px; margin-left: 8px;" v-model="journal.occur"
              @change="onSelectChange"
              />
          </div>
          <div class="flxrow vtaligncenter commargin2">
            <div class="label">Series: </div>
            <a-select v-model="journal.series"
              :options="seriesOptions"
              :allowClear="true"
              placeholder="Please select"
              style="width: 140px; margin-left: 8px;"
              @change="onSelectChange"
              >
            </a-select>
            <div class="label" style="margin-left: 20px;">Tags: </div>
            <a-select v-model="journal.tags"
              :options="tagOptions"
              mode="tags"
              placeholder="Please select"
              style="width: 240px; margin-left: 18px;"
              @change="onSelectChange"
              >
            </a-select>
          </div>
        </div>
        <!--------------- image ----------------->
        <div class="flxcolumn" style="width: 50%; height: 150px;">
            <div class="chart-title title-dec pad" @paste="onPastImage">Images</div>
            <div class="item-wrapper img-container flxrow minipadding" style="height: 130px;" @paste="onPastImage">
              <div class="img-wrapper" v-for="(url, index) in journal.images" :key="index">
                <div v-viewer>
                  <img class="jour-img" :src="url" />
                </div>
                <button class="img-x" @click="onRemoveImage(url)">X</button>
              </div>
            </div>
        </div>
      </div>

      <!--------------- summary ----------------->
      <div class="flxcolumn" :style="{width: '100%', height: summaryExpand ? '350px' : '40px'}">
          <div class="flxrow title-dec pad">
            <div class="flxrow">
              <div class="chart-title">Summary</div>
              <div style="display: table; margin-left: 4px;">
                <CaretUpOutlined v-if="summaryExpand" style="display: table-cell; vertical-align: middle;" @click="expandSummary"/>
                <CaretDownOutlined v-else style="display: table-cell; vertical-align: middle;" @click="expandSummary"/>
              </div>
            </div>
            <button v-if="editingSummary"
                class="btn btn-p1 bg-gradient-dark flxalignright"
                style="margin-right: 0;"
                @click="saveSummary" > Done </button>
            <button v-else
                class="btn btn-p1 bg-gradient-info flxalignright"
                style="margin-right: 0;"
                @click="editSummary" > Edit </button>
          </div>
          <div class="flx1 flxcolumn yscroll margin-b2" style="height: 0;">
            <EditorToolbar v-if="editingSummary" id="toolbar" class="bt-sd"></EditorToolbar>
            <div class="flx1 yscroll" style="height: 0">
              <mr-quill-editor v-if="editingSummary" v-model="journal.summary" :clear="clearTigger" />
              <div v-else class="ql-editor doc" v-html="journal.summary"> </div>
            </div>
          </div>
      </div>

      <!--------------- detail ----------------->
      <div class="flxrow title-dec pad">
        <div class="chart-title">Detail</div>
        <div class="flxalignright">
          <button v-if="editingDetail" class="btn btn-p1 bg-gradient-dark" @click="saveDetail" > Done </button>
          <button v-else class="btn btn-p1 bg-gradient-info" @click="editDetail" > Edit </button>
          <button class="btn btn-p1 bg-gradient-info marginlr" @click="showPasteModal" > Paste Html </button>
        </div>
      </div>
      <EditorToolbar v-if="editingDetail" id="toolbar" class="bt-sd"></EditorToolbar>
      <div class="flx1 yscroll" style="height: 0">
        <mr-quill-editor v-if="editingDetail" v-model="journal.detail" :clear="clearTigger" />
        <div v-else class="ql-editor doc" v-html="journal.detail"> </div>
      </div>
    </div>

    <!---------------- 底部按钮 ---------------->
    <div>
      <div class="flxrow pad">
        <div class="flxalignright">
          <button class="btn btn-p1 bg-gradient-secondary" @click="onTryCancel" > Cancel </button>
          <button
              v-if="mode==='creating'"
              class="btn btn-p1 bg-gradient-dark"
              style="margin-left: 12px;"
              @click="onCreate"> Create </button>
          <button
            v-if="mode === 'view' && editing"
            style="margin-left: 12px;"
            class="btn btn-p1 bg-gradient-dark" @click="onUpdate" > Save </button>
          <a-popconfirm
            title="Are you sure delete this?"
            ok-text="Yes" cancel-text="No"
            @confirm="onDelete" >
            <button
              v-if="mode==='view' || editing"
              style="margin-left: 12px;"
              class="btn btn-p1 bg-gradient-danger" > Delete </button>
          </a-popconfirm>
        </div>
      </div>
    </div>

    <a-modal
        :visible="modalVisible"
        :closable="false"
        :maskClosable="false"
        :footer="null"
        >
      <a-textarea v-model="pasteContent" placeholder="Paste" :rows="8" />
      <div class="flxrow margintb2">
        <div class="flxalignright">
          <button class="btn btn-p1 bg-gradient-secondary" @click="onCancel" > Cancel </button>
          <button style="margin-left: 24px;"
            class="btn btn-p1 bg-gradient-dark" @click="onPasteHtml" > Save </button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import MrQuillEditor from '@/components/MrQuillEditor.vue';
import EditorToolbar from '@/components/doc/EditorToolbar.vue';
import { Message } from 'element-ui';
import { uploadImage, } from '@/script/ImageAPIs';
import { doGet, doPost, doGetN, doPostN, } from '@/script/ServerTools';
import { timestampToFormatDateTime, } from '@/script/DateTools';
import moment from 'moment';
import 'viewerjs/dist/viewer.css';
import { directive as viewer } from "v-viewer";
import { CaretUpOutlined, CaretDownOutlined, } from '@ant-design/icons-vue';

export default {
  name: 'Journal',
  props: [ 'mode', 'id', 'createSignal', ],
  components: { MrQuillEditor, EditorToolbar, CaretUpOutlined, CaretDownOutlined, },
  directives: {
    viewer: viewer({
      debug: false,
    }),
  },
  data() {
    return {
      editing: false,
      editingSummary: false,
      editingDetail: false,
      journal: {
        type: null,
        occur: null,
        series: null,
        tags: [ ],
        images: [ ],
        summary: '<p></p>',
        detail: '<p></p>',
      },
      clearTigger: 0,
      typeOptions: [ ],
      tagOptions: [ ],
      seriesOptions: [ ],
      summaryExpand: true,
      // paste modal
      modalVisible: false,
      pasteContent: '',
    };
  },
  watch: {
    createSignal(val, oldValue) {
      this.initCreate();
    },
    id(val, newVal) {
      this.editing = false;
      this.editingSummary = false;
      this.editingDetail = false;
      this.loadJournal(val);
    },
  },
  methods: {
    expandSummary() {
      this.summaryExpand = !this.summaryExpand;
    },
    initCreate() {
      this.journal = {
        type: window.journalTypes[0].value,
        occur: moment(),
        series: window.journalSeries[0].value,
        tags: [ ],
        images: [ ],
        summary: '<p></p>',
        detail: '<p></p>',
      };
      this.editingSummary = false;
      this.editingDetail = false;
    },
    loadJournal(id) {
      doGetN('/api/morpho/journal/get', { id, }).then((rsp) => {
        this.journal = { ...rsp.result };
        this.journal.occur = moment(this.journal.occur);
      });
    },
    editSummary() {
      this.editingDetail = false;
      this.editingSummary = true;
      this.summaryExpand = true;
      if (this.mode === 'view') {
        this.editing = true;
      }
    },
    editDetail() {
      this.editingSummary = false;
      this.editingDetail = true;
      if (this.mode === 'view') {
        this.editing = true;
      }
    },
    onSelectChange() {
      if (this.mode === 'view') {
        this.editing = true;
      }
    },
    saveSummary() {
      this.editingSummary = false;
    },
    saveDetail() {
      this.editingDetail = false;
    },
    onPastImage(event) {
      if (event.clipboardData.files.length === 0) {
        return;
      }
      this.$message({ message: 'Uploading...', type: 'info' });
      uploadImage(event.clipboardData.files[0]).then((url) => {
        this.journal.images.push(url);
        this.$message({ message: 'Image uploaded.', type: 'success' });
        this.editing = true;
      });
    },
    onRemoveImage(url) {
      let index = 0;
      this.journal.images.forEach((it, i) => {
        if (url === it) {
          index = i;
        }
      });
      this.journal.images.splice(index, 1);
      this.editing = true;
    },
    onCreate() {
      if (this.journal.summary === '' || this.journal.summary === '<p></p>' || this.journal.summary === '<p><br></p>') {
        Message({ message: 'Summary cannot be empty!', type: 'fail' });
        return;
      }
      const payload = { ...this.journal };
      payload.occur = timestampToFormatDateTime(payload.occur.unix());
      if (this.mode === 'creating') {
        doPostN('/api/morpho/journal/create', payload).then((rsp) => {
          Message({ message: rsp.message, type: 'success' });
          this.$emit('close', payload.occur);
        });
      }
    },
    onTryCancel() {
      this.$emit('cancel');
    },
    onUpdate() {
      if (this.journal.summary === '' || this.journal.summary === '<p></p>' || this.journal.summary === '<p><br></p>') {
        Message({ message: 'Summary cannot be empty!', type: 'fail' });
        return;
      }
      const payload = { ...this.journal };
      payload.occur = timestampToFormatDateTime(payload.occur.unix());
      doPostN('/api/morpho/journal/update', payload).then((rsp) => {
        Message({ message: rsp.message, type: 'success' });
        this.$emit('close', payload.occur);
      });
    },
    onDelete() {
      doPostN('/api/morpho/journal/delete', { id: this.id, }).then((rsp) => {
        Message({ message: rsp.message, type: 'success' });
        this.$emit('close', timestampToFormatDateTime(this.journal.occur.unix()));
      });
    },
    loadConfigs() {
      if (window.journalTypes === undefined || window.journalTypes === null) {
        doGetN('/api/morpho/config/query', { code: 'JOURNAL_TYPES' }).then((rsp) => {
          window.journalTypes = rsp.result;
          this.typeOptions = rsp.result;
        });
      } else {
        this.typeOptions = window.journalTypes;
      }
      if (window.journalTags === undefined || window.journalTags === null) {
        doGetN('/api/morpho/config/query', { code: 'JOURNAL_TAGS' }).then((rsp) => {
          window.journalTags = rsp.result;
          this.tagOptions = rsp.result;
        });
      } else {
        this.tagOptions = window.journalTags;
      }
      if (window.journalSeries === undefined || window.journalSeries === null) {
        doGetN('/api/morpho/config/query', { code: 'JOURNAL_SERIES' }).then((rsp) => {
          window.journalSeries = rsp.result;
          this.seriesOptions = rsp.result;
        });
      } else {
        this.seriesOptions = window.journalSeries;
      }
    },
    // modal
    showPasteModal() {
      this.pasteContent = '';
      this.modalVisible = true;
    },
    onPasteHtml() {
      doPost('/api/journal/prosess_paste', { content: this.pasteContent, }).then((rsp) => {
        this.journal.detail = rsp.data;
        this.modalVisible = false;
      });
    },
    onCancel() {
      this.modalVisible = false;
    },
  },
  mounted() {
    this.loadConfigs();
    if (this.mode === 'creating') {
      this.initCreate();
    } else if (this.mode === 'view') {
      this.loadJournal(this.id);
    }
  },
};
</script>

<style scoped>
.jour-img {
  width: 100%;
  max-height: 200px;
  display: block;
  border-radius: 5px;
  margin-bottom: 8px;
}
.pad {
  padding: 4px 12px;
}
.doc {
  padding: 4px 12px!important;
}
.label {
  font-size: 14px;
  font-weight: 550;
  color: #212529;
}
.button {
  border-radius: 50%;
  padding: 6px;
  color: #FFF;
  background-color: #409EFF;
  border-color: #409EFF;
}
.artical-container {
  height: 100%;
  overflow-y: scroll;
  flex: 1;
}
</style>