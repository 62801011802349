<template>
  <draggable :list="value" class="container-layout" :group="{ name: 'g1' }">
    <b-tabs fill style="width: 100%;">

    <div v-for="chartConfig in value" :key="chartConfig.id"
         :style="{ width: `${chartConfig.width}${chartConfig.widthUnit}` }">

      <b-tab :title="chartConfig.title">

      <!-- 容器 递归 -->
      <div v-if="chartConfig.chartType === 'Container'"
           :class="{ 'compadding3': isEditting }">
        <div :class="{ 'editting-container': isEditting }">
          <!-- header -->
          <div v-if="isEditting" style="display: flex; padding: 12px 12px 0px 12px;">
            <!-- 编辑态下加上编辑按钮header -->
            <span style="fontSize: 16px;"><strong>{{ chartConfig.title }}</strong></span>
            <div style="margin-left: auto; display: flex;">
              <mr-icon-button name="Add Chart" icon="plus-square" size="11px"
                              @click="on_addChildChart(chartConfig.id)">
              </mr-icon-button>
              <mr-icon-button name="Settings" icon="tools" size="11px"
                  @click="on_editChartConfig(chartConfig.id)">
              </mr-icon-button>
              <mr-icon-button name="Delete" icon="trash-fill" size="11px"> </mr-icon-button>
            </div>
          </div>

          <nested-drag-chart
                v-if="chartConfig.children"
                :value="chartConfig.children"
                :isEditting="isEditting"
                :level="level+1"
                :pageEvents="pageEvents"
                :pageVariables="pageVariables"
                @addChildChart="on_addChildChart"
                @addPageEvent="on_addPageEvent"
                @addPageVariable="on_addPageVariable"
                @editChartConfig="on_editChartConfig"
                @deleteChart="on_deleteChart"
                >
          </nested-drag-chart>
        </div>
      </div>

      <!-- 普通chart -->
      <div v-else>
        <markdown-chart
            v-if="chartConfig.chartType === 'MarkdownText'"
            :style="computeHeightStyle(chartConfig.height)"
            :config='chartConfig'
            :isEditting="isEditting"
            :hideTitle='true'
            @editChartConfig="on_editChartConfig"
            @deleteChart="on_deleteChart"
            >
        </markdown-chart>

        <node-chart
            v-if="chartConfig.chartType === 'NodeChart'"
            :style="computeHeightStyle(chartConfig.height)"
            :config='chartConfig'
            :isEditting="isEditting"
            :hideTitle='true'
            :pageEvents="pageEvents"
            :pageVariables="pageVariables"
            @addPageEvent="on_addPageEvent"
            @addPageVariable="on_addPageVariable"
            @editChartConfig="on_editChartConfig"
            @deleteChart="on_deleteChart"
            >
        </node-chart>
      </div>

      </b-tab>
    </div>

    </b-tabs>
  </draggable>
</template>

<script>
import draggable from 'vuedraggable';
import MrIconButton from './MrIconButton.vue';
import 'markdown-it-vue/dist/markdown-it-vue.css';
import MarkdownChart from './chart/MarkdownChart.vue';
import NodeChart from './chart/NodeChart.vue';

export default {
  name: 'NestedDragTab',
  components: { draggable, MrIconButton, MarkdownChart, NodeChart,
  },
  props: [ 'value', 'isEditting', 'level', 'pageEvents', 'pageVariables' ],
  data() {
    return {
    };
  },
  methods: {
    on_addChildChart(containerId) {
      this.$emit('addChildChart', containerId);
    },
    on_addPageEvent(eName) {
      this.$emit('addPageEvent', eName);
    },
    on_addPageVariable(vName) {
      this.$emit('addPageVariable', vName);
    },
    computeHeightStyle(height) {
      return height ? { height: `${height}px`} : { };
    },
    on_editChartConfig(chartId) {
      console.log(`on_editChartConfig ${chartId}`);
      this.$emit('editChartConfig', chartId);
    },
    on_deleteChart(chartId) {
      console.log(`on_editChartConfig ${chartId}`);
      this.$emit('deleteChart', chartId);
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
};
</script>

<style scoped>
.container-layout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.editting-container {
  background-color: #fbfbfb;
}
.table-td {
  padding: 4px 8px 4px 8px;
}
.table-td-border {
  border-top: 1px solid #dee2e6;
  padding: 4px 8px 4px 8px;
}
</style>
