<template>
  <div id="body">
    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p class="page-title">
            Indicator Management
          </p>
          <!-- head buttons -->
          <div class="flxrow" style="margin-left: auto;">
            <a-input-search v-model="searchStr"
              placeholder="input search text"
              enter-button
              @search="onSearch"
              style="margin-right: 12px;"
            />
            <button class="btn btn-p1 bg-gradient-dark margin-r"
              style="min-width: 60px;"
              @click="onCreate" >新增</button>
            <button class="btn btn-p1 bg-gradient-dark margin-r"
                style="min-width: 70px;"
                @click="onUploadDef" > 批量新增 </button>
            <button class="btn btn-p1 bg-gradient-dark margin-r"
                style="min-width: 70px;"
                @click="onUpload" > 上传数据 </button>
            <button class="btn btn-p1 bg-gradient-dark margin-r"
                style="min-width: 70px;"
                @click="onOtherUpload" > 其他上传 </button>
          </div>
        </div>

        <!-- container layout start -->
        <div class="container-layout">
            <div class="chart commargin width100" style="margin: 4px;" >
              <el-table :data="rows" stripe class="width100">
                <el-table-column
                    v-for="{ key, title} in columns"
                    :prop="key"
                    :label="title"
                    :key="key"/>

                <el-table-column label="操作">
                  <template slot-scope="scope">
                    <button class="btn btn-p1 bg-gradient-dark margin-r"
                      @click="onEdit(scope.row)" > 编辑 </button>
                    <button class="btn btn-p1 bg-gradient-dark margin-r"
                      @click="showValueDrawer(scope.row)" > 指标值管理 </button>
                    <a-popconfirm
                      title="Are you sure delete this task?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="() => {}"
                      @cancel="() => {}"
                    >
                      <button class="btn btn-p1 bg-gradient-danger" > 删除 </button>
                    </a-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
              <div class="width100 flxrow">
              <el-pagination background layout="prev, pager, next"
                style="margin-top: 8px; margin-left: auto"
                :page-size="pageSize"
                :current-page="pageNo"
                :total="total"
                @current-change="onChangePage"
                >
              </el-pagination>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!-- drawer start -->
    <a-drawer
        width="900" placement="right" :closable="false"
        :visible="drawerVisible" @close="onCloseDrawer"
        :bodyStyle="{
          // background: 'rgb(245, 245, 245)',
          background: 'white',
          padding: '0px',
          height: '100%',
        }"
      >
      <IndicatorValueMng
        :name="drawerName"
        :code="drawerCode"
        />
    </a-drawer>
    <!-- drawer end -->

    <a-modal
        :visible="formVisible" @ok="onFormOk"
        :closable="false"
        :maskClosable="false"
        :footer="null"
        >
      <div class="margintb"> 名称 <a-input v-model="formData.name" style="width: 300px; margin-left: 41px" /> </div>
      <div class="margintb"> Code <a-input v-model="formData.code" style="width: 300px; margin-left: 35px"
          :disabled="!formOnCreating"
        /> </div>
      <div class="margintb"> 小数点位数 <a-input-number v-model="formData.decimalPlaces" :min="0" :max="4"  /> </div>
      <div class="margintb"> 单位
      <a-select v-model="formData.unit" style="width: 100px; margin-left: 42px" allowClear="true">
        <a-select-option value="%">%</a-select-option>
        <a-select-option value="bp">bp</a-select-option>
        <a-select-option value="$">$</a-select-option>
        <a-select-option value="MM $">MM $</a-select-option>
        <a-select-option value="K">K</a-select-option>
        <a-select-option value="元">元</a-select-option>
        <a-select-option value="亿元">亿元</a-select-option>
        <a-select-option value="万亿元">万亿元</a-select-option>
      </a-select>
      </div>
      <div class="margintb"> Source <a-input v-model="formData.source" style="width: 300px; margin-left: 25px" /> </div>
      <div class="margintb">
      时区
      <a-select v-model="formData.timezone" style="width: 100px; margin-left: 42px"/>
        <a-select-option value="+8">+8</a-select-option>
        <a-select-option value="-5">-5</a-select-option>
      </div>
    <div>
      <div class="flxrow pad">
        <div class="flxalignright">
          <button class="btn btn-p1 bg-gradient-secondary" @click="onFormCancel" > Cancel </button>
          <button class="btn btn-p1 bg-gradient-dark"
            style="margin-left: 12px;"
            @click="onFormOk" > Ok </button>
        </div>
      </div>
    </div>
    </a-modal>

    <!-- upload modal -->
    <a-modal
        :visible="uploadFormVisible" @ok="onUploadFormOk"
        :closable="false"
        :maskClosable="false"
        :footer="null"
        >
      <div v-if="!uploading">
        <a-upload class="margintb" :file-list="uploadFileList" :before-upload="beforeUpload" :multiple="false" >
          <a-button> <upload-outlined></upload-outlined> 选择文件 </a-button>
        </a-upload>
        <div class="margintb2">日期按行排列: <a-switch v-model="dateByRow" /> </div>
        <div class="margintb2">调整时间为月末: <a-switch v-model="uploadAdjustLastOfMonth" /> </div>
        <div class="margintb2">时区Offset(秒，反向): <a-input-number v-model="uploadTimezoneOffset" size="small" /> </div>
        <div class="flxrow pad">
          <div class="flxalignright">
            <button class="btn btn-p1 bg-gradient-secondary" @click="onUploadFormCancel" > Cancel </button>
            <button class="btn btn-p1 bg-gradient-dark"
              style="margin-left: 12px;"
              @click="onUploadFormOk" > Ok </button>
          </div>
        </div>
      </div>
      <div v-else class="flxrow">
        <a-spin class="margin-lr-auto" />
      </div>
    </a-modal>

    <!-- upload def modal -->
    <a-modal
        :visible="uploadDefFormVisible" @ok="onUploadDefFormOk"
        :closable="false"
        :maskClosable="false"
        :footer="null"
        >
      <a-upload class="margintb" :file-list="uploadFileList" :before-upload="beforeUpload" :multiple="false" >
        <a-button> <upload-outlined></upload-outlined> 选择文件 </a-button>
      </a-upload>
      <div class="flxrow pad">
        <div class="flxalignright">
          <button class="btn btn-p1 bg-gradient-secondary" @click="onUploadDefFormCancel" > Cancel </button>
          <button class="btn btn-p1 bg-gradient-dark"
            style="margin-left: 12px;"
            @click="onUploadDefFormOk" > Ok </button>
        </div>
      </div>
    </a-modal>

    <!-- other upload modal -->
    <a-modal
        :visible="otherUploadModalVisible" @ok="onOtherUploadFormOk"
        :closable="false"
        :maskClosable="false"
        :footer="null"
        >
      <a-radio-group v-model="otherUploadPath">
        <a-radio-button value="us_gas_price">US Gas Price</a-radio-button>
      </a-radio-group>
      <div class="margintb2">
        <a-upload v-if="!uploading2" :file-list="uploadFileList" :before-upload="beforeUpload" :multiple="false" >
          <a-button> <upload-outlined></upload-outlined> 选择文件 </a-button>
        </a-upload>
        <div v-else class="flxrow">
          <a-spin class="margin-lr-auto" />
        </div>
      </div>
      <div class="flxrow pad">
        <div class="flxalignright">
          <button class="btn btn-p1 bg-gradient-secondary" @click="onOtherUploadFormCancel" > Cancel </button>
          <button class="btn btn-p1 bg-gradient-dark"
            style="margin-left: 12px;"
            @click="onOtherUploadFormOk" > Upload </button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { doPostN, doGetN, doPostForm, } from '@/script//ServerTools';
import IndicatorValueMng from './IndicatorValueMng.vue';
import { getDefs, createDef, updateDef, uploadValues, } from '@/script/IndicatorAPIs';
import { UploadOutlined } from '@ant-design/icons-vue';

export default {
  name: 'IndicatorManagement',
  components: { IndicatorValueMng, UploadOutlined, },
  data() {
    return {
      searchStr: '',
      pageNo: 1,
      pageSize: 10,
      total: 0,
      columns: [ {
        title: '名称',
        key: 'name',
        dataIndex: 'name',
      }, {
        title: 'Code',
        key: 'code',
        dataIndex: 'code',
      }, {
        title: '最新值',
        key: 'value',
        dataIndex: 'value',
      } ],
      rows: [ ],

      drawerName: '',
      drawerCode: '',
      drawerVisible: false,

      formVisible: false,
      formOnCreating: false,
      formData: {},

      uploadIndicatorCode: '',
      uploadFormVisible: false,
      uploadFileList: [],
      dateByRow: true,
      uploadAdjustLastOfMonth: false,
      uploadTimezoneOffset: 0,
      uploading: false,

      uploadDefFormVisible: false,

      // other upload
      otherUploadModalVisible: false,
      otherUploadPath: 'us_gas_price',
      uploading2: false,
    };
  },
  methods: {
    queryDefs() {
      const rows = [ ];
      const params = {
        withValue: true,
        search: this.searchStr,
      };
      getDefs({
        pageNo: this.pageNo, pageSize: this.pageSize, params,
      }).then((rsp) => {
        this.pageNo = rsp.result.pageNo;
        this.pageSize = rsp.result.pageSize;
        this.total = rsp.result.total;
        rsp.result.list.forEach((it) => {
          it.key = it.id;
          rows.push(it);
        });
      });
      this.rows = rows;
    },
    onSearch() {
      this.pageNo = 1;
      this.queryDefs();
    },
    onChangePage(pageNo) {
      this.pageNo = pageNo;
      this.queryDefs();
    },
    showValueDrawer(def) {
      this.drawerName = def.name;
      this.drawerCode = def.code;
      this.drawerVisible = true;
    },
    onCloseDrawer() {
      this.drawerVisible = false;
    },
    // ===============================
    onCreate() {
      this.formVisible = true;
      this.formOnCreating = true;
    },
    onEdit(data) {
      this.formData = { ...data, };
      this.formOnCreating = false;
      this.formVisible = true;
    },
    onFormOk() {
      if (this.formOnCreating) {
        createDef(this.formData).then((rsp) => {
          this.$message({ message: rsp.message, type: 'success' });
          this.queryDefs();
        });
      } else {
        updateDef(this.formData).then((rsp) => {
          this.$message({ message: rsp.message, type: 'success' });
          this.queryDefs();
        });
      }
      this.formVisible = false;
    },
    onFormCancel() {
      this.formVisible = false;
    },
    // =========== upload indicator values =============
    onUpload() {
      this.dataByRow = true;
      this.uploadFormVisible = true;
    },
    beforeUpload(file) {
      this.uploadFileList = [ file ];
      return false;
    },
    onUploadFormOk() {
      const formData = new FormData();
      formData.append('file', this.uploadFileList[0]);
      formData.append('dateByRow', this.dateByRow);
      formData.append('adjustEndOfMonth', this.uploadAdjustLastOfMonth);
      formData.append('timezoneOffset', this.uploadTimezoneOffset);
      this.uploading = true;
      uploadValues(formData).then((rsp) => {
        if (rsp.errorCode === 'SUCCESS') {
          this.$message({ message: rsp.message, type: 'success' });
        } else {
          this.$message({ message: rsp.message, type: 'error' });
        }
        this.uploadFormVisible = false;
        this.uploadFileList = [ ];
        this.uploading = false;
      });
    },
    onUploadFormCancel() {
      this.uploadFormVisible = false;
      this.uploadFileList = [ ];
    },
    // =========== upload indicator def =============
    onUploadDef() {
      this.uploadDefFormVisible = true;
    },
    onUploadDefFormOk() {
      const formData = new FormData();
      formData.append('file', this.uploadFileList[0]);
      doPostForm('/api/morpho/indicator/upload_defs', formData).then((rsp) => {
        if (rsp.errorCode === 'SUCCESS') {
          this.$message({ message: rsp.message, type: 'success' });
          this.uploadDefFormVisible = false;
          this.uploadFileList = [ ];
        } else {
          this.$message({ message: rsp.message, type: 'error' });
        }
      });
    },
    onUploadDefFormCancel() {
      this.uploadDefFormVisible = false;
      this.uploadFileList = [ ];
    },
    // ========== other upload =============
    onOtherUpload() {
      this.otherUploadModalVisible = true;
      this.uploadFileList = [ ];
      this.uploading2 = false;
    },
    onOtherUploadFormOk() {
      const formData = new FormData();
      formData.append('file', this.uploadFileList[0]);
      this.uploading2 = true;
      doPostForm(`/api/morpho/indicator/${this.otherUploadPath}`, formData).then((rsp) => {
        if (rsp.errorCode === 'SUCCESS') {
          this.$message({ message: rsp.message, type: 'success' });
          this.otherUploadModalVisible = false;
          this.uploadFileList = [ ];
          this.uploading2 = false;
        } else {
          this.$message({ message: rsp.message, type: 'error' });
          this.uploading2 = false;
        }
      });
    },
    onOtherUploadFormCancel() {
      this.otherUploadModalVisible = false;
      this.uploadFileList = [ ];
    },
  },
  mounted() {
    this.queryDefs();
  },
};
</script>