<template>
  <div>
    <!-- chart title start -->
    <div style="display: flex; align-items: center;">
      <h6> Category </h6>
      <div style="margin-left: auto;">
        <mr-icon-button name="" icon="plus-square" size="12px"
          @click="onCreateUnderRoot"
        ></mr-icon-button>
      </div>
    </div>
    <!-- chart title end  -->

    <div style="height: 456px; overflow-y: scroll;">
      <category :data="treeData"
          @addNode="onCreateUnderNode"
          @sortItems="onSortItems"
          @select="onSelect"
          @deleteNode="onDeleteNode"
      />
    </div>

    <!-- modal start -->
    <a-modal
        :title="modalTitle"
        :visible="modalVisible"
        :footer="null"
        class="local-modal"
        @cancel="onCloseModal"
    >
      <a-form-model :model="formData"
          :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="Name" required style="marginBottom: 8">
          <a-input v-model="formData.name"/>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 5 }">
          <a-button @click="onCloseModal"> Cancel </a-button>
          <a-button style="margin-left: 12px;" type="primary" @click="onSubmitForm">
            Submit
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- modal end -->
  </div>
</template>

<script>
import { doPost, doGet } from '@/script/ServerTools';
import MrIconButton from '../../components/MrIconButton.vue';
import Category from '../../components/Category.vue';

export default {
  name: 'TradeCategory',
  props: [],
  components: { MrIconButton, Category, },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },

      treeData: [],
      modalTitle: 'New Category Node',
      modalVisible: false,
      formData: {
        _id: '',
        name: '',
        parent_node_id: '',
      },
    };
  },
  methods: {
    onCreateUnderRoot() {
      this.formData._id = '';
      this.formData.name = '';
      this.formData.parent_node_id = '';
      this.modalVisible = true;
    },
    onCreateUnderNode(nodeKey, seq) {
      this.formData._id = '';
      this.formData.name = '';
      this.formData.parent_node_id = nodeKey;
      this.formData.seq = seq;
      this.modalVisible = true;
    },
    onCloseModal() {
      this.modalVisible = false;
    },
    onSubmitForm() {
      if (this.formData._id) {
      } else {
        doPost('/api/category/createTradeCategoryNode', this.formData).then((rsp) => {
          this.$message({ message: rsp.message, type: 'success' });
          this.modalVisible = false;
          this.loadTradeCategory();
        });
      }
    },
    onSortItems(sortData) {
      const data = [];
      sortData.forEach((it) => {
        data.push({ id: it.key, seq: it.seq });
      });
      doPost('/api/category/sortNodes', { data }).then((rsp) => { });
    },
    onSelect(key) {
      this.$emit('select', key);
    },
    onDeleteNode(path, key) {
      doPost('/api/category/deleteNode', { nodeId: key }).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
        this.removeNode(this.treeData, path, key);
      });
    },
    // =============================================================
    //
    removeNode(data, path, key) {
      if (path.length > 0) {
        const parent = path.shift();
        data.forEach((it) => {
          if (it.key === parent) {
            console.log('--', it.title);
            this.removeNode(it.children, path, key);
          }
        });
      } else {
        const i = data.findIndex((it) => it.key === key);
        console.log(i);
        console.log('----', data[i].title);
        data.splice(i, 1);
        console.log(data);
      }
    },
    loadTradeCategory() {
      doGet('/api/category/queryTradeCategory', {}).then((rsp) => {
        this.treeData = this.buildTreeData(rsp.data);
      });
    },
    buildTreeData(list) {
      const l = [];
      list.forEach((it) => {
        const item = { key: it._id, title: it.name, seq: it.seq, };
        if (it.children) {
          item.children = this.buildTreeData(it.children);
        }
        l.push(item);
      });
      return l;
    },
  },
  mounted() {
    this.loadTradeCategory();
  },
};
</script>
