<template>
  <div>
    <li>
      <div style="display: flex;">
        <div>
          <b-icon v-if="expanding" icon="plus" @click="collapse" />
          <b-icon v-else icon="dash" @click="expand" />
        </div>
        <div class="cate-item" :class="{ 'cate-item-color': !selected, 'cate-item-color-selected': selected}"
            @click="onClick">
          <span style="font-size: 14px;">{{ item.title }}</span>
          <div style="display: flex;">
            <mr-icon-button name="" icon="plus-square" style="margin-right: 0px"  size="12px"
              @click="onAddChild"></mr-icon-button>
            <mr-icon-button name="" icon="arrow-up-circle" style="margin-right: 0px" size="12px"
              @click="onUp"></mr-icon-button>
            <mr-icon-button name="" icon="arrow-down-square" style="margin-right: 0px" size="12px"
              @click="onDown"></mr-icon-button>
            <mr-icon-button name="" icon="trash" size="12px"
              @click="onDelete"></mr-icon-button>
            <!--
            -->
          </div>
        </div>
      </div>
      <ul v-show="expanding && item.children"  class="no-bullets" style="padding-left: 18px;">
        <category-item v-for="it in item.children" :key="it.key" :item="it"
            :selectedKey="selectedKey"
            @select="onSelectChild"
            @addNode="onAddNode"
            @itemUp="onChildUp"
            @itemDown="onChildDown"
            @itemDelete="onChildDelete"
        />
      </ul>
    </li>

  </div>
</template>

<script>
import MrIconButton from '../components/MrIconButton.vue';

export default {
  components: { MrIconButton, },
  name: 'CategoryItem',
  props: [ 'item', 'selectedKey', ],
  data() {
    return {
      expanding: true,
      sel: false,
    };
  },
  methods: {
    onClick() {
      const s = this.selected;
      this.$emit('select', s ? undefined : this.item.key);
    },
    onSelectChild(key) {
      this.$emit('select', key);
    },
    collapse() {
      this.expanding = false;
    },
    expand() {
      this.expanding = true;
    },
    onUp() {
      this.$emit('itemUp', [ ], this.item.key);
    },
    onDown() {
      this.$emit('itemDown', [ ], this.item.key);
    },
    onDelete() {
      this.$emit('itemDelete', [ ], this.item.key);
    },
    onAddChild() {
      let seq = 0;
      if (this.item.children && this.item.children.length > 0) {
        seq = this.item.children[this.item.children.length - 1].seq + 1;
      }
      this.$emit('addNode', this.item.key, seq);
    },

    // ================================
    onAddNode(parentKey, seq) {
      this.$emit('addNode', parentKey, seq);
    },
    onChildUp(path, key) {
      this.$emit('itemUp', [this.item.key, ...path], key);
    },
    onChildDown(path, key) {
      this.$emit('itemDown', [this.item.key, ...path], key);
    },
    onChildDelete(path, key) {
      this.$emit('itemDelete', [this.item.key, ...path], key);
    },
  },
  computed: {
    selected() {
      return this.selectedKey === this.item.key;
    },
  },
};
</script>

<style>
ul.no-bullets {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cate-item {
  display: flex;
  flex: 1;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: space-between;
  -moz-user-select:none; /*火狐*/
  -webkit-user-select:none; /*webkit浏览器*/
  -ms-user-select:none; /*IE10*/
  -khtml-user-select:none; /*早期浏览器*/
  user-select:none;
}
.cate-item-color {
  background: #ffffff;
}
.cate-item-color:hover {
  background: #f0f0f0;
}
.cate-item-color-selected {
  background: #d9d9d9;
}
.cate-item-color-selected:hover {
  background: #d9d9d9;
}
</style>
