export function objectNotNull(obj) {
  return typeof obj !== 'undefined' && obj !== null && obj !== '';
}

export function uuid4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    /* eslint no-bitwise: ["error", { "allow": ["|", "&"] }] */
    const r = Math.random() * 16 | 0;
    /* eslint no-mixed-operators: ["error", {"allowSamePrecedence": true}] */
    const v = c === 'x' ? r : ((r & 0x3) | 0x8);
    return v.toString(16);
  });
}
