<template>
  <div class="home">
    <CandleStick style="height:800px"
        :stockConfig="{ stockCode: stockCode }"
        :options='options' :extremes='extremes'
        :quoteData='quoteData'
    />
  </div>
</template>

<script>
// @ is an alias to /src
import CandleStick from '@/components/CandleStick.vue';
import { queryIndexQuotes } from '@/script/QuotesQuery';

export default {
  name: 'Stock',
  components: {
    CandleStick,
  },
  data() {
    return {
      stockCode: `${this.$route.query.code}`,
      options: {
        showRecentLowHigh: false,
        showNavigator: true,
      },
      extremes: [ null, null, '' ],
      quoteData: {},
    };
  },
  methods: {
  },
  mounted() {
    queryIndexQuotes(this.stockCode).then((result) => {
      if (result !== null) {
        this.quoteData = result;
      }
    });
  },
};
</script>

<style>
</style>
