<template>
  <div id="body">

    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p class="page-title" style="margin: 8px 0px;">
            Trading
          </p>
          <!-- head buttons -->
          <div style="margin-left: auto;">
            <button class="btn btn-p1 bg-gradient-info" @click="onShowCreateTransModal" > + </button>
          </div>
        </div>

        <div class="container-layout">
          <!-- chart start -->
          <div style="width: 20%;">
              <div class="chart" >
                <trade-category @select="onSelectCategory" />
              </div>
          </div>
          <!-- chart end -->

          <!-- row 1 -->
          <div style="width: 80%;">
            <!-- chart start -->
            <div style="padding: 4px; background-color: transparent; border-radius: .5rem;">
              <div style="height: 513px; overflow-y: scroll;">
                  <trade-trans :category="selectedCategory" :createTransSignal="createTransSignal"/>
              </div>
            </div>
            <!-- chart end -->
          </div>

          <!-- chart start -->
          <div style="width: 70%;">
              <div class="chart" >
                <trand-logics :category="selectedCategory" />
              </div>
          </div>
          <!-- chart end -->

          <div style="width: 30%;">
            <!-- chart start -->
              <div class="chart">
                <stories />
              </div>
            <!-- chart end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TradeTrans from './TradeTrans.vue';
import TrandLogics from './TrandLogics.vue';
import TradeCategory from './TradeCategory.vue';
import Stories from './Stories.vue';

export default {
  name: 'Trade',
  components: { TradeTrans, TrandLogics, TradeCategory, Stories, },
  data() {
    return {
      selectedCategory: '',
      createTransSignal: 0,
    };
  },
  methods: {
    // ------------------------------------------------
    // interact methods

    // ------------------------------------------------
    // save methods
    onSelectCategory(categoryId) {
      this.selectedCategory = categoryId;
    },
    onShowCreateTransModal() {
      this.createTransSignal = this.createTransSignal + 1;
    },
  },
  mounted() {
  },
};
</script>
