<template>
  <div id="body">
    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            Macro
          </p>
        </div>

        <!-- container layout start -->
        <div class="container-layout">

          <!-- chart start -->
          <div :style="{'width': '100%'}">
              <div class="chart commargin" >
                <Comprehensive />
              </div>
          </div>
          <!-- chart end -->

          <!-- chart start -->
          <div :style="{'width': '100%'}">
              <div class="chart commargin" >
                <SomaSummary />
              </div>
          </div>
          <!-- chart end -->

        </div>
        <!-- container layout end -->

      </div>
    </div>
  </div>
</template>

<script>
import Comprehensive from './Comprehensive.vue';
import SomaSummary from '@/views/data/SomaSummary.vue';
export default {
  name: 'Macro',
  components: { Comprehensive, SomaSummary },
  data() {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
};
</script>
