<template>
  <div v-if="sources" class="source flxrow">
    <div v-for="it, index  in sources" :key="index" class="link-text marginlr" @click="jumpSource(it)">
      Source
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataSource',
  props: [ 'sources', ],
  data() {
    return {
    };
  },
  methods: {
    jumpSource(src) {
      window.open(src);
    },
  },
};
</script>

<style scoped>
.source {
  position: absolute;
  right: 16px;
  top: 3px;
  font-size: 13px;
  z-index: 1000;
}
</style>