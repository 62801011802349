<template>
  <div id="body">
    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p class="page-title">
            Spider Tasks
          </p>
          <!-- head buttons -->
          <div class="flxrow" style="margin-left: auto;">
            <a-input-search v-model="searchStr"
              placeholder="input search text"
              enter-button
              @search="onSearch"
              style="margin-right: 12px;"
            />
            <button class="btn btn-p1 bg-gradient-dark margin-r"
              style="min-width: 60px;"
              @click="onCreate" >新增</button>
          </div>
        </div>

        <!-- container layout start -->
        <div class="container-layout">
            <div class="chart commargin width100" style="margin: 4px;" >
              <a-table :data-source="spiders" :columns="columns" rowKey="id"
                  :pagination="pagination" @change="handleTableChange"  >
                <span slot="status" slot-scope="text, record">
                  <a-switch :checked="text===0" @change="startOrStop(record)"/>
                </span>
                <span slot="lastExeStatus" slot-scope="text">
                  <a-tag v-if="text===0" color="green"> success </a-tag>
                  <a-tag v-if="text===1" color="blue"> running </a-tag>
                  <a-tag v-if="text===2" color="pink"> error </a-tag>
                </span>
                <span slot="lastExeTime" slot-scope="text">
                  {{ text=== null ? '-' : text.replace('T', ' ') }}
                </span>
                <span slot="nextExeTime" slot-scope="text">
                  {{ text=== null ? '-' : text.replace('T', ' ') }}
                </span>
                <span slot="action" slot-scope="record">
                    <button class="btn btn-p1 bg-gradient-dark margin-r"
                      @click="onEdit(record)" > 编辑 </button>
                    <a-popconfirm
                      title="Are you sure delete this task?"
                      ok-text="Yes"
                      cancel-text="No"
                      @confirm="() => {}"
                      @cancel="() => {}"
                    >
                      <button class="btn btn-p1 bg-gradient-danger" > 删除 </button>
                    </a-popconfirm>
                </span>
              </a-table>
          </div>
        </div>
      </div>
    </div>

    <a-modal
        :visible="formVisible" @ok="onFormOk"
        :closable="false"
        :maskClosable="false"
        :footer="null"
        :width="840"
        >
      <a-space class="margintb">
        <label>名称</label>
        <a-input v-model="formData.name" style="width: 260px;" />
        <label>Cron</label>
        <a-input v-model="formData.cron" style="width: 100px;" />
        <span>{{ formData.nextExeTime }}</span>
      </a-space>
      <a-space class="margintb">
        <label>URL</label>
        <a-input v-model="formData.request.url" style="width: 430px;" />
      </a-space>
      <div v-for="(header, index) in formData.request.headers" :key="header.k" >
        <a-space class="margintb">
          <label >Key</label>
          <a-input v-model="header.k"  class="mr-input" style="width: 160px; " />
          <label >Value</label>
          <a-input v-model="header.v" style="width: 360px; "/>
          <MinusCircleOutlined class="marginl" @click="removeHeader(index)" />
        </a-space>
      </div>
      <a-form-item style="width: 670px; ">
        <a-button type="dashed" block @click="addHeader">
        <PlusOutlined /> Add Header</a-button>
      </a-form-item>
      <div v-for="ve in formData.valueExtractors" :key="ve.valueXpath">
        <a-space class="margintb">
          <label>指标</label>
          <a-input v-model="ve.indicatorCode" style="width: 150px; "/>
          <a-switch checked-children="html" un-checked-children="json" v-model="ve.isHtml" />
        </a-space>

        <a-space v-if="ve.isHtml" class="margintb">
          <label class="marginl3">日期XPath</label>
          <a-input v-model="ve.dateXPath" style="width: 180px; "/>
          <label>值XPath</label>
          <a-input v-model="ve.valueXPath" style="width: 300px; "/>
        </a-space>
        <a-space v-else class="margintb">
          <label class="marginl3">日期JsonPath</label>
          <a-input v-model="ve.dateJsonPath" style="width: 180px; "/>
          <label>值JsonPath</label>
          <a-input v-model="ve.jsonPath" style="width: 300px; "/>
        </a-space>

        <a-space class="margintb">
          <label>日期Format</label>
          <a-select v-model="ve.dateFormat" style="width: 140px; ">
            <a-select-option value="MMM dd, yyyy">MMM dd, yyyy</a-select-option>
            <a-select-option value="yyyy-MM-dd">yyyy-MM-dd</a-select-option>
            <a-select-option value="yyyy/MM/dd">yyyy/MM/dd</a-select-option>
            <a-select-option value="MM.dd">MM.dd</a-select-option>
            <a-select-option value="yyyy年MM月">yyyy年MM月</a-select-option>
            <a-select-option value="yyyy年MM月dd日">yyyy年MM月dd日</a-select-option>
            <a-select-option value="yyyy年M月d日">yyyy年M月d日</a-select-option>
          </a-select>
          <label>调整</label>
          <a-select v-model="ve.dateAdjustment" style="width: 140px; ">
            <a-select-option value="None">None</a-select-option>
            <a-select-option value="Now">Now time</a-select-option>
            <a-select-option value="Today">Today start</a-select-option>
            <a-select-option value="Yesterday">Yesterday</a-select-option>
            <a-select-option value="Weekend">Weekend</a-select-option>
            <a-select-option value="LastWeekend">Last Weekend</a-select-option>
            <a-select-option value="MonthEnd">Current Month End</a-select-option>
            <a-select-option value="LastMonthEnd">Last Month End</a-select-option>
          </a-select>
          <label>TZOffset</label>
          <a-input-number v-model="ve.zoneOffsetSeconds" :defaultValue="0" />
        </a-space>
      </div>
      <a-button type="dashed" block @click="addVE">
        <PlusOutlined /> Add VE</a-button>
      <div class="flxrow pad margint2">
        <div class="flxalignright">
          <button class="btn btn-p1 bg-gradient-secondary" @click="onFormCancel" > Cancel </button>
          <button class="btn btn-p1 bg-gradient-info marginl2" @click="onTestRun" > Test Run </button>
          <button class="btn btn-p1 bg-gradient-dark marginl2" @click="onFormOk" > Save </button>
        </div>
      </div>
    </a-modal>

    <a-modal
        :visible="testResultVisible"
        :closable="true"
        :maskClosable="true"
        :footer="null"
        :width="640"
        @cancel="closeTestResult"
        >
      <div v-for="result in testResults" :key="result.indicatorCode" class="flxcolumn">
        <a-space class="margintb">
          <label>指标</label>
          <span>{{ result.indicatorCode }}</span>
        </a-space>
        <a-space class="margintb">
          <label>值</label>
          <span>{{ result.value }}</span>
        </a-space>
        <a-space class="margintb">
          <label>时间</label>
          <span>{{ result.dataTime }}</span>
        </a-space>
      </div>
    </a-modal>


  </div>
</template>

<script>
import { doPostN, doGetN, doPostForm, } from '@/script//ServerTools';
import { MinusCircleOutlined, PlusOutlined, } from '@ant-design/icons-vue';

export default {
  name: 'Spiders',
  components: { MinusCircleOutlined, PlusOutlined, },
  data() {
    return {
      searchStr: '',
      pageNo: 1,
      pageSize: 10,
      total: 0,
      spiders: [],
      columns: [ {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
      }, {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
      }, {
        title: 'Next Run Time',
        key: 'nextExeTime',
        dataIndex: 'nextExeTime',
        scopedSlots: { customRender: 'nextExeTime' },
      }, {
        title: 'Last Run Status',
        key: 'lastExeStatus',
        dataIndex: 'lastExeStatus',
        scopedSlots: { customRender: 'lastExeStatus' },
      }, {
        title: 'Last Run Time',
        key: 'lastExeTime',
        dataIndex: 'lastExeTime',
        scopedSlots: { customRender: 'lastExeTime' },
      }, {
        title: 'Run Log',
        key: 'exceptionMsg',
        dataIndex: 'exceptionMsg',
      }, {
        title: 'Action',
        key: 'action',
        scopedSlots: { customRender: 'action' },
      } ],
      formVisible: false,
      drawerVisible: false,
      drawerName: '',
      drawerCode: '',
      formData: {
        request: {
        }
      },
      testResultVisible: false,
      testResults: [],
    };
  },
  computed: {
    pagination() {
      return {
        current: this.pageNo,
        pageSize: this.pageSize,
        total: this.total,
      };
    },
  },
  methods: {
    query(pageNo) {
      doPostN('api/morpho/spider/query', {
        pageNo, pageSize: this.pageSize, params: {
          search: this.searchStr,
        }
      }).then((rsp) => {
        if (rsp.errorCode === 'SUCCESS' && rsp.result.list.length > 0) {
          this.pageNo = pageNo;
          this.total = rsp.result.total;
          this.spiders = rsp.result.list;
        }
      });
    },
    handleTableChange(pagination, filters, sorter) {
      this.query(pagination.current);
    },
    onSearch(searchStr) {
      this.searchStr = searchStr;
      this.query(this.pageNo);
    },
    onCreate() {
      this.formData = {
        name: '',
        cron: '0 15 20 * * ?',
        request: {
          url: '',
          headers: [
            {k: 'accept', v: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7'},
            {k: 'accept-encoding', v: 'gzip, deflate, br'},
            {k: 'accept-language', v: 'zh-CN,zh;q=0.9,en;q=0.8'},
            {k: 'cache-control', v: 'no-cache'},
            {k: 'pragma', v: 'no-cache'},
            {k: 'sec-ch-ua', v: '\"Not_A Brand\";v=\"8\", \"Chromium\";v=\"120\", \"Google Chrome\";v=\"120\"'},
            {k: 'sec-ch-ua-mobile', v: '?0'},
            {k: 'sec-ch-ua-platform', v: '\"macOS\"'},
            {k: 'sec-fetch-dest', v: 'document'},
            {k: 'sec-fetch-mode', v: 'navigate'},
            {k: 'sec-fetch-site', v: 'same-origin'},
            {k: 'user-agent', v: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/105.0.0.0 Safari/537.36'},
            {k: 'origin', v: ''},
            {k: 'referer', v: ''},
          ],
        },
        valueExtractors: [],
      };
      this.formVisible = true;
    },
    onEdit(task) {
      this.formData = task;
      this.formVisible = true;
    },
    removeHeader(index) {
      this.formData.request.headers.splice(index, 1);
    },
    addHeader() {
      this.formData.request.headers.push({k: '', v: ''});
    },
    addVE() {
      this.formData.valueExtractors.push({ isHtml: true });
    },
    onChangePage() {},
    onFormOk() {
      if (this.formData.id !== null && this.formData.id !== undefined) {
        doPostN('api/morpho/spider/update', this.formData).then((rsp) => {
          if (rsp.errorCode === 'SUCCESS') {
            this.$message({ message: rsp.message, type: 'success' });
            this.formVisible = false;
            this.query(this.pageNo);
          } else {
            this.$message({ message: rsp.message, type: 'error' });
          }
        });
      } else {
        doPostN('api/morpho/spider/create', this.formData).then((rsp) => {
          if (rsp.errorCode === 'SUCCESS') {
            this.$message({ message: rsp.message, type: 'success' });
            this.formVisible = false;
            this.query(this.pageNo);
          } else {
            this.$message({ message: rsp.message, type: 'error' });
          }
        });
      }
    },
    onFormCancel() {
      this.formVisible = false;
    },
    onTestRun() {
      doGetN('api/morpho/spider/testRun', { id: this.formData.id }).then((rsp) => {
        if (rsp.errorCode === 'SUCCESS') {
          this.$message({ message: rsp.message, type: 'success' });
          this.testResults = rsp.result;
          this.testResultVisible = true;
        } else {
          this.$message({ message: rsp.message, type: 'error' });
        }
      });
    },
    closeTestResult() {
      this.testResultVisible = false;
    },
    startOrStop(record) {
      doGetN('api/morpho/spider/startOrStop', { id: record.id }).then((rsp) => {
        if (rsp.errorCode === 'SUCCESS') {
          this.$message({ message: rsp.message, type: 'success' });
          this.query(this.pageNo);
        } else {
          this.$message({ message: rsp.message, type: 'error' });
        }
      });
    },
  },
  mounted() {
    this.query(1);
  },
};
</script>
