import vm from 'vm';
import DataModelAPIs from './DataModelAPIs.js';
import MA5ShortStrategyAPIs from './MA5ShortStrategyAPIs.js';
import DynamicFormAPIs from './DynamicFormAPIs.js';
import PageEventBus from './PageEventBus.js';
import { Message } from 'element-ui';

/**
 * run js script
 * @param {string} text js script text
 * @param {Object} context run context
 * @returns {Object} result
 */
export function exeScriptString(text, context = undefined) {
  let sandbox = {
    DataModelAPIs, console, Event, MA5ShortStrategyAPIs, DynamicFormAPIs, Message,
    PageEventBus,
  };
  if (context !== undefined) {
    sandbox = Object.assign(sandbox, context);
  }
  const contextifiedSandbox = vm.createContext(sandbox);
  const script = new vm.Script(text);
  return script.runInContext(contextifiedSandbox);
}
