import Vue from 'vue';
import DynamicFormModal from '../components/DynamicFormModal.vue';
import FormAPIs from './FormAPIs.js';

export function openForm(title, formId, renderParams, cancelCallback, submitSuccessCallback) {
  FormAPIs.getById(formId).then((rspData) => {
    const schema = rspData.data.schema;
    const api = rspData.data.api;

    const VueComponent = Vue.extend(DynamicFormModal);
    const div = document.createElement('div');
    document.body.appendChild(div);
    const ele = new VueComponent({
      propsData: {
        title,
        schema,
        api,
        renderParams,
        onCancel() {
          ele.$destroy();
          if (cancelCallback) {
            cancelCallback();
          }
        },
        onSubmitSuccess() {
          ele.$destroy();
          if (submitSuccessCallback) {
            submitSuccessCallback();
          }
        }
      }
    }).$mount(div);
  });
}

const DynamicFormAPIs = { openForm, };
export default DynamicFormAPIs;
