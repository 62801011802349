<template>
  <div id="body">
    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            {{ repoName }}
          </p>
        </div>

        <div class="container-layout">
          <div style="width: 100%;">
            <!-- chart start -->
              <div class="chart commargin" >
                <div style="position: relative; padding: 0px" >
                <canvas id="canvas" width="1" height="1"
                  :style="{width: canvasWidth + 'px', height: canvasHeight + 'px'}"
                > </canvas>
                <!--
                style="width: 753px; height: 4550px;"
                <canvas id="canvas" width="753" height="4550"
                -->
                <div id="repoTreeContainer" class="tree-container" @click="onClickTree" >
                  <doc-node v-for="node in docNodes" :node="node" :key="node._id"
                    :root="true"
                    :clickEvent="clickEvent"
                    :refreshEvent="refreshEvent"
                    @refreshTree="onRefreshTree"
                    @rerenderTree="onRefreshTree"
                  />
                </div>
                </div>
              </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Update doc form modal -->
    <el-dialog title="Update Doc" :visible.sync="updateDocFormVisible">
      <el-form :model="updateDocFormData" ref="updateDocForm"
          :rules="updateDocFormRules"
          label-position="left" label-width="120px" size="mini">

        <el-form-item label="Chapter" prop="chapter_id">
          <el-cascader v-model="updateDocFormData.chapter_id"
              :options="parentChapterOptions" :props="{ checkStrictly: true }"
              :show-all-levels="false"
              separator=" > " clearable>
          </el-cascader>
        </el-form-item>

        <el-form-item label="Name" prop="name">
          <el-input v-model="updateDocFormData.name" autocomplete="off"
              placeholder="enter name">
          </el-input>
        </el-form-item>

        <el-form-item label="Sequence" prop="sequence">
          <el-input v-model.number="updateDocFormData.sequence" autocomplete="off"
              placeholder="enter sequence">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeUpdateDocForm">取 消</el-button>
        <el-button type="primary" size="small" @click="submitUpdateDoc">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryRepoById, queryDocsByRepo,
  createChapter, updateChapter, deleteChapter,
  createDocument, updateDocument, deleteDocument,
} from '@/script/DocOperations';
import DocNode from '../../components/doc/DocNode.vue';

export default {
  name: 'RepoDetail',
  components: { DocNode, },
  data() {
    return {
      repoId: '',
      repo: null,
      repoName: '',
      docTreeData: [],
      // update chapter form
      parentChapterOptions: [],
      updateChapterFormVisible: false,
      // update doc form
      updateDocFormVisible: false,
      updateDocFormData: {
        _id: '',
        name: '',
        chapter_id: '',
        sequence: 0,
      },
      updateDocFormRules: {
        name: [{ required: true, message: 'Name cannot be empty', trigger: 'change' }],
        chapter_id: [{ required: true, message: 'Name cannot be empty', trigger: 'change' }],
        sequence: [{ type: 'number', message: 'Sequence must be number', trigger: 'change' }],
      },
      currentDocId: '',

      docNodes: [ ],
      clickEvent: 0,
      refreshEvent: 0,
      canvasWidth: 1,
      canvasHeight: 1,
    };
  },
  methods: {
    // ============== event =============
    onClickTree() {
      this.clickEvent += 1;
    },
    onRefreshTree() {
      this.queryAllDocs().then(() => {
        this.refreshEvent += 1;
        setTimeout(() => {
        }, 10);
      });
    },
    queryAllDocs() {
      return new Promise((resolve, reject) => {
        queryDocsByRepo(this.repoId).then((data) => {
          this.docNodes = [{
            _id: '/',
            name: this.repoName,
            children: data.data,
            repo_id: this.repoId,
          }];
          resolve();
        });
      });
    },
    // ======== update chapter form ========
    showUpdateForm(data) {
      this.updateDocFormVisible = true;
      this.updateDocFormData = data;
      this.parentChapterOptions = this.buildParentChapterOptions(this.docTreeData, 'NOT_EXISTS');
    },
    buildParentChapterOptions(data, avoidId) {
      const result = [];
      data.forEach((it) => {
        if (it.isChapter && it._id !== avoidId) {
          const temp = { label: it.name, value: it._id };
          if (it.children) {
            const children = this.buildParentChapterOptions(it.children, avoidId);
            if (children && children.length > 0) {
              temp.children = children;
            }
          }
          result.push(temp);
        }
      });
      return result;
    },
    closeUpdateChapterForm() {
      this.updateChapterFormVisible = false;
      this.$refs.updateChapterForm.resetFields();
    },
    // ======== update doc form ========
    closeUpdateDocForm() {
      this.updateDocFormVisible = false;
      this.$refs.updateDocForm.resetFields();
    },
    submitUpdateDoc() {
      const formData = { ...this.updateDocFormData };
      if (typeof this.updateDocFormData.chapter_id !== 'string') {
        const len = this.updateDocFormData.chapter_id.length;
        formData.chapter_id = this.updateDocFormData.chapter_id[len - 1];
      }
      console.log(formData);
      updateDocument(formData).then((responseData) => {
        this.closeUpdateDocForm();
        this.$message({ message: responseData.message, type: 'success' });
        this.queryAllDocs();
      });
    },
    // ======== delete ========
    deleteChapterOrDoc(data) {
      if (window.confirm('Are you sure to delete?')) {
        if (data.isChapter) {
          deleteChapter({ _id: data._id }).then((responseData) => {
            this.$message({ message: responseData.message, type: 'success' });
            this.queryAllDocs();
          });
        } else {
          deleteDocument({ _id: data._id }).then((responseData) => {
            this.$message({ message: responseData.message, type: 'success' });
            this.queryAllDocs();
          });
        }
      }
    },
  },
  mounted() {
    this.repoId = this.$route.params.repoid;
    queryRepoById(this.repoId).then((data) => {
      this.repo = data.data;
      this.repoName = this.repo.name;
    });
    this.queryAllDocs();

    setTimeout(() => {
      const canvas = document.getElementById('canvas');
      const treeContainer = document.getElementById('repoTreeContainer');
      const devicePixelRatio = window.devicePixelRatio;
      canvas.width = Math.floor(treeContainer.clientWidth * devicePixelRatio);
      canvas.height = Math.floor(treeContainer.clientHeight * devicePixelRatio);
      this.canvasWidth = Math.floor(treeContainer.clientWidth);
      this.canvasHeight = Math.floor(treeContainer.clientHeight);

      const ctx = canvas.getContext("2d");
      ctx.scale(devicePixelRatio, devicePixelRatio);
      ctx.lineWidth = 1;
      console.log(treeContainer.getBoundingClientRect());
      console.log(window.devicePixelRatio);
      this.refreshEvent += 1;
    }, 1000);
  },
};
</script>

<style scoped>
.doc-item {
  border: 1px solid #6996f7;
  border-radius: 5px;
  background-color: white;
  margin: 16px;
}
.tree-container {
  background-color: rgb(255, 255, 255, 0) !important;
  position: absolute;
  top: 0px;
  left: 0px;
}
</style>
