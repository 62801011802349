<template>
  <div>
    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">

      <div v-for='item in renderData' :key='item.paramKey'>
        <a-form-item v-if='(editMode || !item.hidden) && (item.type==="text" || item.isReplace)'
            :label='item.label' style="margin-bottom: 8px;">
          <a-input v-model="item.value" />
        </a-form-item>

        <a-form-item v-if='(editMode || !item.hidden) && item.type==="number" && !item.isReplace'
            :label='item.label' style="margin-bottom: 8px;">
          <a-input-number v-model="item.value" :step="item.step" :min="item.min" :max="item.max" />
        </a-form-item>

        <a-form-item v-if='(editMode || !item.hidden) && item.type==="datePicker" && !item.isReplace'
            :label='item.label' style="margin-bottom: 8px;">
          <a-date-picker v-model="item.value" :format="item.format" :valueFormat="item.format" />
        </a-form-item>

      </div>

      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button type="primary" html-type="submit" @click="handleSubmit">
          Submit
        </a-button>
        <a-button @click="handleCancel" style="margin-left: 16px;" >
          Cancel
        </a-button>
      </a-form-item>

    </a-form>
  </div>
</template>

<script>
import { doPost } from '@/script//ServerTools';

export default {
  name: 'DynamicForm',
  props: [ 'schema', 'api', 'editMode', 'renderParams' ],
  data() {
    return {
      key: 0,
      renderData: [],
    };
  },
  /* {
    {
        type: 'text',
        lable: '',
        paramKey: '',
        defaultValue: '',  // 默认值，支持占位符
        allowEmpty: false, // 允许为空 默认false
        disable: false,    // 不允许修改 默认false
        hidden: false,     // 隐藏 默认false
    },
    { type: 'container', paramKey: '', children: [
      { type: 'text', lable: '', paramKey: '', allowEmpty: false, },
    ]},
   } */
  methods: {
    buildDefaultValue(isReplace, defValue) {
      if (isReplace) {
        if (this.renderParams) {
          return this.renderParams[defValue.substring(2, defValue.length - 1)];
        }
        return defValue;
      }
      return defValue;
    },
    getKey() {
      this.key += 1;
      return this.key;
    },
    buildRenderData(item) {
      if (item.type === 'container') {
        const data = {
          key: this.getKey(),
          type: item.type,
          paramKey: item.paramKey,
          children: [],
        };
        if (item.children) {
          item.children.forEach((it) => {
            data.children.push(this.buildRenderData(it));
          });
        }
        return data;
      } else {
        const data = { key: this.getKey(), ...item, };
        data.isReplace = false;
        if ('defaultValue' in item && item.defaultValue) {
          data.isReplace = typeof item.defaultValue === 'string' && item.defaultValue.startsWith('${') && item.defaultValue.endsWith('}');
          data.value = this.buildDefaultValue(data.isReplace, item.defaultValue);
        }
        return data;
      }
    },
    buildPostData(items) {
      const data = {};
      items.forEach((it) => {
        if (it.type === 'container') {
          data[it.paramKey] = this.buildPostData(it.children);
        } else {
          data[it.paramKey] = it.value;
        }
      });
      return data;
    },
    handleCancel() {
      this.$emit('cancel');
    },
    handleSubmit() {
      if (!this.editMode && this.api) {
        doPost(this.api, this.buildPostData(this.renderData)).then((respData) => {
          this.$message({ message: respData.message, type: 'success' });
          this.$emit('sucess');
        });
      }
    },
  },
  watch: {
    schema: function(value) {
      const data = [];
      value.forEach((item) => {
        data.push(this.buildRenderData(item));
      });
      this.renderData = data;
    },
  },
  mounted() {
    this.schema.forEach((item) => {
      this.renderData.push(this.buildRenderData(item));
    });
  },
};
</script>
