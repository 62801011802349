<template>
  <div class="container">
    <button type="button" class="btn btn-primary">{{ msg }}</button>
  </div>
</template>

<script>
import axios from 'axios';
import { backEndHost } from '@/script//ServerTools';

export default {
  name: 'Ping',
  data() {
    return {
      msg: '',
    };
  },
  methods: {
    getMessage() {
      const path = `${backEndHost()}/ping`;
      axios.get(path).then((res) => {
        this.msg = res.data;
      }).catch((error) => {
        // eslint-disable-next-line
        console.error(error);
      });
    },
  },
  created() {
    this.getMessage();
  },
};
</script>
