<template>
  <!--
  <div :id="chartId" class="HighStock"></div>
  -->
  <highcharts v-if="chartOptions"
      style="height: 100%;"
      :constructor-type="'stockChart'"
      :options="chartOptions "/>
</template>

<script>
import Highcharts from 'highcharts/highstock';
import { uuid4, } from '@/script/CommonTools';

export default {
  name: 'CommonCandleChart',
  props: [
    'title', 'inhertParentHeight', 'data', 'yTitle', 'legend',
    'xFormat', 'showValue', 'rangeSelector', 'rangeSelectorSelected',
    'navigator', 'reload',
  ],
  data() {
    return {
      chartId: null,
      chartOptions: null,
    };
  },
  computed: {
  },
  watch: {
    reload(val, oldVal) {
      if (val !== oldVal) {
        console.log(111111);
        this.initOptions();
        // Highcharts.stockChart(this.chartId, this.chartOptions);
      }
    },
  },
  methods: {
    initOptions() {
      this.chartId = uuid4();
      const newData = [ ...this.data ];
      let yAxisLeft = false;
      let yAxisRight = false;
      const yAxis = [ ];
      let stacking = false;
      newData.forEach((it) => {
        if (it.yAxisSide === 'left') {
          yAxisLeft = true;
          it.yAxis = 0;
        }
        if (it.yAxisSide === 'right') {
          yAxisRight = true;
          it.yAxis = 1;
        }
        if (it.stack !== null && it.stack !== undefined && it.stack !== '') {
          stacking = true;
        }
      });
      if (yAxisLeft || (!yAxisLeft && !yAxisRight)) {
        yAxis.push({ title: null, opposite: false, });
      }
      if (yAxisRight) {
        yAxis.push({ title: null, opposite: true, });
      }

      const rangeSelectorButtons = [ ];
      this.rangeSelector.forEach((it) => {
        if (it.endsWith('m')) {
          rangeSelectorButtons.push({
            type: 'month',
            text: it,
            count: parseInt(it.substring(0, it.length-1)),
          });
        } else if (it.endsWith('y')) {
          rangeSelectorButtons.push({
            type: 'year',
            text: it,
            count: parseInt(it.substring(0, it.length-1)),
          });
        } else if (it === 'ytd') {
          rangeSelectorButtons.push({
            type: 'ytd',
            text: 'YTD'
          });
        } else if (it === 'All') {
          rangeSelectorButtons.push({
            type: 'all',
            text: 'All'
          });
        }
      });

      const opts = {
        title: { text: this.title, },
        time: {
          useUTC: false,
        },
        chart: { type: 'candlestick', },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            millisecond: this.xFormat === 'day' ? '%Y/%m/%d' : '%H:%M:%S.%L',
            second: this.xFormat === 'day' ? '%Y/%m/%d' : '%Y/%m/%d<br/>%H:%M:%S',
            minute: this.xFormat === 'day' ? '%Y/%m/%d' : '%Y/%m/%d<br/>%H:%M',
            hour: this.xFormat === 'day' ? '%Y/%m/%d' : '%Y/%m/%d<br/>%H:%M',
            day: '%Y/%m/%d',
            week: '%Y/%m/%d',
            month: '%Y-%m',
            year: '%Y',
          },
        },
        yAxis: yAxis,
        legend: {
          enabled: this.legend !== null,
          layout: this.legend === 'center' ? 'horizontal' : 'vertical',
          align: this.legend,
          verticalAlign: this.legend === 'center' ? 'top' : 'middle'
        },
        rangeSelector: {
          enabled: this.rangeSelector !== null,
          /*
          buttons: [{
              type: 'month',
              count: 1,
              text: '1m',
          }, {
              type: 'month',
              count: 3,
              text: '3m',
          }, {
              type: 'month',
              count: 6,
              text: '6m',
          }, {
              type: 'ytd',
              text: 'YTD',
          }, {
              type: 'year',
              count: 3,
              text: '3y',
          }, {
              type: 'year',
              count: 5,
              text: '5y',
          }, {
              type: 'all',
              text: 'All',
          }],
          */
          buttons: rangeSelectorButtons,
          selected: this.rangeSelectorSelected,
          // selected: -1,
        },
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              }
            }
          },
          column: stacking ? { stacking: 'normal' } : { dataGrouping: { enabled: false, }, },
          line: { dataGrouping: { enabled: false, }, },
          dataGrouping: { enabled: false, },
        },
        tooltip: {
          xDateFormat: '%Y-%m-%d %H:%M',
          split: false,
          shared: true,
        },
        series: newData,
        credits: { enabled: false, },
      };
      if (this.navigator == null || this.navigator) {
        opts['navigator'] = {
          enabled: true,
          height: 20,
        };
      } else {
        opts['navigator'] = {
          enabled: false,
          height: 20,
        };
      }
      this.chartOptions = opts;
    },
  },
  mounted() {
    // setTimeout(() => {
    //   Highcharts.stockChart(this.chartId, this.chartOptions);
    // }, 1000);
  },
  created() {
    this.initOptions();
    // Highcharts.setOptions(this.chartOptions);
  },
};
</script>

<style scoped>
.height100p {
  height: 100%;
}
</style>
