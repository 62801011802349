import axios from 'axios';
import { Message } from 'element-ui';

export function backEndHost() {
  // return 'http://localhost:5000';
  return '';
}

export function doPost(path, data) {
  const fullPath = `${backEndHost()}${path}`;
  return new Promise((resolve, reject) => {
    axios.post(fullPath, data).then((result) => {
      if (result.data.errcode === 0) {
        resolve(result.data);
      } else {
        Message({ message: result.data.message, type: 'error' });
        reject(result.data);
      }
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function doGet(path, params={}) {
  const fullPath = `${backEndHost()}${path}`;
  return new Promise((resolve, reject) => {
    axios.get(fullPath, {params}).then((result) => {
      if (result.data.errcode === 0) {
        resolve(result.data);
      } else {
        Message({ message: result.data.message, type: 'error' });
        reject(result.data);
      }
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function doPostN(path, data) {
  const fullPath = `${backEndHost()}${path}`;
  return new Promise((resolve, reject) => {
    axios.post(fullPath, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function doPostForm(path, bodyFormData) {
  const fullPath = `${backEndHost()}${path}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: fullPath,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    }).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function doGetN(path, params={}) {
  const fullPath = `${backEndHost()}${path}`;
  return new Promise((resolve, reject) => {
    axios.get(fullPath, {params}).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}
