import { doPostN, doGetN, doPostForm, } from './ServerTools';

export function createDef(data) {
    return doPostN('/api/morpho/indicator/create_def', data);
}

export function updateDef(data) {
    return doPostN('/api/morpho/indicator/update_def', data);
}

export function getDef(code) {
    return doGetN('/api/morpho/indicator/get_def', { code });
}

export function getDefs(req) {
    return doPostN('/api/morpho/indicator/query_defs', req);
}

export function getValue(code) {
    return doGetN('/api/morpho/indicator/get_value', { code });
}

export function getValues(code, start, end) {
    const payload = { code, };
    if (start !== null && start !== undefined) {
        payload.start = start;
    }
    if (end !== null && end !== undefined) {
        payload.end = end;
    }
    return doGetN('/api/morpho/indicator/get_values_by_time', payload);
}

export function getValuesByPage(code, pageSize, pageNo) {
    return doPostN('/api/morpho/indicator/get_values', { pageSize, pageNo, params: { code, }});
}

export function saveValue(obj) {
    return doPostN('/api/morpho/indicator/save_value', obj);
}

export function uploadValues(formData) {
    return doPostForm('/api/morpho/indicator/upload_values2', formData);
}

export function deleteValue(obj) {
    return doPostN('/api/morpho/indicator/delete_value', obj);
}

export function deleteAllValues(formData) {
    return doPostForm('/api/morpho/indicator/remove_all', formData);
}
