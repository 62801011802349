<template>
  <div>
    <ul class="no-bullets">
      <category-item v-for="it in data" :key="it.key" :item="it"
          :selectedKey="selectedKey"
          @select="onSelectItem"
          @addNode="onAddNode"
          @itemUp="onChildUp"
          @itemDown="onChildDown"
          @itemDelete="onChildDelete"
      />
    </ul>
  </div>
</template>

<script>
import CategoryItem from './CategoryItem.vue';

export default {
  name: 'Category',
  props: [ 'data', ],
  components: { CategoryItem },
  data() {
    return {
      selectedKey: '',
      items: [],
    };
  },
  methods: {
    onSelectItem(key) {
      this.selectedKey = key;
      this.$emit("select", key);
    },
    onAddNode(parentKey, seq) {
      this.$emit('addNode', parentKey, seq);
    },
    onChildUp(path, key) {
      this.processItemUp(this.items, path, key, -3);
    },
    onChildDown(path, key) {
      this.processItemUp(this.items, path, key, 1);
    },
    onChildDelete(path, key) {
      this.$emit('deleteNode', path, key);
    },

    processItemUp(l, path, key, delta) {
      if (path.length === 0) {
        let seq = 2;
        l.forEach((it) => {
          if (it.key === key) {
            it.seq = seq + delta;
          } else {
            it.seq = seq;
            seq += 2;
          }
        });
        l.sort((a, b) => a.seq - b.seq);
        const sortData = [];
        l.forEach((it) => sortData.push({key: it.key, seq: it.seq}));
        this.$emit('sortItems', sortData);
      }
      else {
        for (let i=0; i<l.length; ++i) {
          if (l[i].key === path[0]) {
            this.processItemUp(l[i].children, path.slice(1), key, delta);
            break;
          }
        }
      }
    }
  },
  watch: {
    data(val) {
      this.items = [ ];
      val.forEach((it) => {
        this.items.push(it);
      });
    },
  },
};
</script>

<style>
ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
</style>
