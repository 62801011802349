<template>
  <div id="login-body">
    <div id="login-form" style="width: 300px;">
      <el-form ref="loginForm" :model="loginFormData" :rules="loginFormValidRules"
          label-position="left" label-width="80px" size="mini">
        <el-form-item label="Account" prop="account">
          <el-input v-model="loginFormData.account" autocomplete="off"
              placeholder="enter account">
          </el-input>
        </el-form-item>
        <el-form-item label="Password" prop="password">
          <el-input type="password" v-model="loginFormData.password" autocomplete="off"
              placeholder="enter password">
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="doLogin">Login</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';
import { login } from '@/script/AccountOperations';

export default {
  name: 'Login',
  data() {
    return {
      labelPosition: 'left',
      loginFormData: {
        account: '',
        password: '',
      },
      loginFormValidRules: {
        account: [
          { required: true, message: 'Account cannot be empty', trigger: 'change' },
        ],
        password: [
          { required: true, message: 'Password cannot be empty', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    doLogin() {
      login({
        account: this.loginFormData.account,
        password: sha256(this.loginFormData.password).toString(),
      }).then((data) => {
        console.log(data);
        this.$message({ message: data.message, type: 'success' });
      });
    },
  },
};
</script>

<style>
#login-body {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
  width: 100%;
  height: 100%;
  background-color: #F5F5F5;
}
#login-form {
  display: block;
  background-color: white;
  padding: 24px 24px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
}
</style>
