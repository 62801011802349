<template>
  <div class="chart">
    <div class="jour-category">
      <div v-if="type === 'Narrative'"> {{ date.substring(0, 10) }} </div>
      <div v-else>{{ series }} </div>
    </div>
    <div class="jour-summary" v-html="summary" @click="viewJournal"> </div>
    <!-- 图片 -->
    <div v-if="images !== undefined && images.length > 0"
        v-viewer>
      <img class="jour-img" :src="images[0]"/>
    </div>
    <!-- tags -->
    <div class="flxrow" style="margin-bottom: 8px;">
      <div v-for="tag in tags" :key="tag" class="jour-tag">{{tag}}</div>
    </div>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css';
import { directive as viewer } from "v-viewer";

export default {
  name: 'JournalSummary',
  props: [ 'id', 'summary', 'images', 'series', 'tags', 'date', 'type', ],
  directives: {
    viewer: viewer({
      debug: false,
    }),
  },
  data() {
    return {
    };
  },
  methods: {
    viewJournal() {
      this.$emit('view', this.id);
    },
  },
};
</script>

<style scoped>
.jour-img {
  width: 100%;
  max-height: 200px;
  display: block;
  border-radius: 5px;
  margin-bottom: 8px;
}
.jour-summary {
  margin-bottom: 6px;
  cursor: pointer;
}
</style>