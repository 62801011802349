<template>
  <div id="body">
    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; margin: 8px 0px; color: #515A6E">
            US Macro Data
          </p>
        </div>

        <!-- container layout start -->
        <div class="container-layout">

          <div style="width: 100%; height: 350px; margin: 0px 4px 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 50%;" class="marginlr height100p "
                :title="'利差'"
                :indicators="[
                  {code: 'US10Y_3M', type: 'line', },
                  {code: 'US10Y_2Y', type: 'line', },
                  {code: 'US2Y_3M', type: 'column', },
                ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="3"
            />
            <YieldCurve style="width: 50%; height: 100%" class="marginlr"/>
          </div>

          <!-- row start -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <FedRateExp style="width: 40%; height: 608px" class="marginlr" />
            <IndicatorChart
                style="width: 60%; height: 608px" class="marginlr "
                :title="'Fed Balance Sheet - Assets'"
                :indicators="[
                  {code: 'FED_BS_ASSETS_Bills', type: 'area', stack: 'stack' },
                  {code: 'FED_BS_ASSETS_Notes_and_bonds_nominal', type: 'area', stack: 'stack', title: 'Notes and Bonds', },
                  {code: 'FED_BS_ASSETS_Notes_and_bonds_inflation_indexed', type: 'area', stack: 'stack', title: 'TIPS', },
                  {code: 'FED_BS_ASSETS_Inflation_compensation', type: 'area', stack: 'stack' },
                  {code: 'FED_BS_ASSETS_Federal_agency_debt_securities', type: 'area', stack: 'stack', title: '联邦机构债务证券' },
                  {code: 'FED_BS_ASSETS_Mortgage-backed_securities', type: 'area', stack: 'stack', title: 'MBS', },
                  {code: 'FED_BS_ASSETS_Unamortized_premiums_on_securities_held_outright', type: 'area', stack: 'stack', title: 'Securities premiums' },
                  {code: 'FED_BS_ASSETS_Unamortized_discounts_on_securities_held_outright', type: 'area', stack: 'stack', title: 'Securities discounts' },
                  {code: 'FED_BS_ASSETS_Repurchase_agreements', type: 'area', stack: 'stack', title: 'Repurchase' },
                  {code: 'FED_BS_ASSETS_Loans', type: 'area', stack: 'stack', title: 'Loans(Discount Window)' },
                  {code: 'FED_BS_ASSETS_Net_portfolio_holdings_of_MS_Facilities_LLC', type: 'area', stack: 'stack', title: 'MS Facilities LLC', },
                  {code: 'FED_BS_ASSETS_Other_Federal_Reserve_assets', type: 'area', stack: 'stack', title: '美联储其他资产' },
                  {code: 'FED_BS_ASSETS_Foreign_currency_denominated_assets', type: 'area', stack: 'stack', title: '外币计价资产' },
                  {code: 'FED_BS_ASSETS_Gold_stock', type: 'area', stack: 'stack' },
                  // {code: 'FED_BS_ASSETS_Special_drawing_rights_certificate_account', type: 'area', stack: 'stack' },
                  {code: 'FED_BS_ASSETS_Treasury_currency_outstanding', type: 'area', stack: 'stack', title: '未偿国库券' },
                  {code: 'FED_BS_LIABILITIES_Currency_in_circulation', type: 'area', stack: 'stack', yAxis: 'reverse' },
                  {code: 'FED_BS_LIABILITIES_Reverse_repurchase_agreements', type: 'area', stack: 'stack', title: 'Reverse repurchase', yAxis: 'reverse'},
                  // {code: 'FED_BS_LIABILITIES_Treasury_cash_holdings', type: 'area', stack: 'stack' },
                  // {code: 'FED_BS_LIABILITIES_Term_deposits_held_by_depository_institutions', type: 'area', stack: 'stack' },
                  {code: 'FED_BS_LIABILITIES_US_Treasury_General_Account', type: 'area', stack: 'stack', title: 'TGA',yAxis: 'reverse' },
                  {code: 'FED_BS_LIABILITIES_Foreign_official', type: 'area', stack: 'stack', yAxis: 'reverse' },
                  {code: 'FED_BS_LIABILITIES_Other_Deposits_with_FR_Banks', type: 'area', stack: 'stack', title: 'Other Deposits',yAxis: 'reverse' },
                  {code: 'FED_BS_LIABILITIES_Treasury_contributions_to_credit_facilities', type: 'area', stack: 'stack', title: 'Treasury contributions to CF',yAxis: 'reverse' },
                  {code: 'FED_BS_LIABILITIES_Other_liabilities_and_capital', type: 'area', stack: 'stack',yAxis: 'reverse' },
                  {code: 'FED_BS_LIABILITIES_Reserve_balances_with_Federal_Reserve_Banks', type: 'area', stack: 'stack', title: 'Reserves',yAxis: 'reverse' },
                ]"
                :type="'stackingArea'"
                :show-value="true"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
          </div>
          <!-- row end -->

          <!-- row start -->
          <div style="width: 100%; height: 350px; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 34%;" class="marginlr height100p "
                :title="'S&P500 PE'"
                :indicators="[
                  {code: 'SP500_PE_WSJ', type: 'line', },
                  {code: 'SP500_12M_FORWARD_PE_WSJ', type: 'line', },
                  {code: 'Shiller_PE', type: 'line', yAxis: 'right', }
                ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', '10y', 'All']"
                :rangeSelectorSelected="3"
            />
            <IndicatorChart
                style="width: 34%;" class="marginlr height100p "
                :title="'Quarter EPS Forecast'"
                :show-value="false"
                :indicators="epsForecastIndicators"
                :legend="'center'"
            />
            <IndicatorChart
                style="width: 34%;" class="marginlr height100p "
                :title="'Last Quarter EPS Full Fill'"
                :show-value="false"
                :indicators="epsFullFillIndicators"
                :legend="'center'"
            />
          </div>
          <!-- row end -->

          <!-- row 4 start -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'CPI & Core CPI MoM'"
                :indicators="[
                  {code: 'US_CPI_MoM_All_items', },
                  {code: 'US_CPI_MoM_L1_All_items_less_food_and_energy', },
                ]"
                :type="'column'"
                :show-value="true"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
                :recordApiPath="'/cpi'"
            />
            <IndicatorChart
                style="width: 67.6%; height: 400px" class="marginlr "
                :title="'CPI Components'"
                :indicators="[
                  {code: 'US_CPI_MoM_L1_Food', type: 'column', },
                  {code: 'US_CPI_MoM_L2_Energy_commodities', type: 'column', },
                  {code: 'US_CPI_MoM_L2_Energy_services', type: 'column', },
                  {code: 'US_CPI_MoM_L2_Commodities_less_food_and_energy_commodities', type: 'column', title: '核心商品' },
                  {code: 'US_CPI_MoM_L3_Shelter', type: 'column',},
                  {code: 'US_CPI_MoM_L3_Medical_care_services', type: 'column',},
                  {code: 'US_CPI_MoM_L3_Transportation_services', type: 'column',},
                ]"
                :type="'column'"
                :show-value="true"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
          </div>

          <!-- row start -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'PCE & Core PCE MoM'"
                :indicators="[{code: 'US_PCE_MOM', type: 'column', }, {code: 'US_CORE_PCE_MOM', type: 'column', }]"
                :type="'column'"
                :show-value="true"
                :legend="null"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
            <IndicatorChart
                style="width: 67.6%; height: 400px" class="marginlr "
                :title="'CPI Components Contribution'"
                :indicators="[
                  {code: 'US_CPI_MoM_All_items', type: 'line', },
                  {code: 'US_CPI_MONTH_EFFECT_FOOD_AT_HOME', type: 'column', stack: 'stack' },
                  {code: 'US_CPI_MONTH_EFFECT_FOOD_AWAY_FROM_HOME', type: 'column', stack: 'stack' },
                  {code: 'US_CPI_MONTH_EFFECT_ENERGY_COMMODITIES', type: 'column', stack: 'stack' },
                  {code: 'US_CPI_MONTH_EFFECT_ENERGY_SERVICES', type: 'column', stack: 'stack' },
                  {code: 'US_CPI_MONTH_EFFECT_COMMODITIES_LESS_FOOD_AND_ENERGY_COMMODITIES', type: 'column', stack: 'stack', title: '核心商品'},
                  {code: 'US_CPI_MONTH_EFFECT_RENT_OF_SHELTER', type: 'column', stack: 'stack' },
                  {code: 'US_CPI_MONTH_EFFECT_MEDICAL_CARE_SERVICES', type: 'column', stack: 'stack' },
                  {code: 'US_CPI_MONTH_EFFECT_TRANSPORTATION_SERVICES', type: 'column', stack: 'stack' },
                  {code: 'US_CPI_MONTH_EFFECT_RECREATION_SERVICES', type: 'column', stack: 'stack' },
                  {code: 'US_CPI_MONTH_EFFECT_EDUCATION_AND_COMMUNICATION_SERVICES', type: 'column', stack: 'stack', title: '教育和通信服务' },
                  {code: 'US_CPI_MONTH_EFFECT_OTHER_PERSONAL_SERVICES', type: 'column', stack: 'stack' },
                ]"
                :type="'column'"
                :show-value="true"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
          </div>
          <!-- row end -->

          <!-- row 4 end -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'US 二手车价值指数'"
                :indicators="[
                  { code: 'MANHEIM_USED_VEHICLE_VALUE_INDEX', yAxis: 'left', },
                  { code: 'MANHEIM_USED_VEHICLE_VALUE_INDEX_MOM', yAxis: 'right', },
                  { code: 'US_CPI_MoM_L3_Used_cars_and_trucks', yAxis: 'right', }
                ]"
                :show-value="false"
                :legend="null"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'Pending Home Sales'"
                :indicators="[
                  { code: 'US_PENDING_HOME_SALES_INDEX', yAxis: 'left', type: 'column', },
                  { code: 'US_PENDING_HOME_SALES_INDEX_MOM', yAxis: 'right', },
                  { code: 'US_PENDING_HOME_SALES_INDEX_YOY', yAxis: 'right', },
                ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="2"
            />
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'Gas Price'"
                :indicators="[
                  { code: 'EMM_EPMRU_PTE_NUS_DPG', },
                  { code: 'EMM_EPMRU_PTE_R10_DPG' },
                  { code: 'EMM_EPMRU_PTE_R20_DPG' },
                  { code: 'EMM_EPMRU_PTE_R30_DPG' },
                  { code: 'EMM_EPMRU_PTE_R40_DPG' },
                  { code: 'EMM_EPMRU_PTE_R50_DPG' },
                ]"
                :type="'column'"
                :show-value="true"
                :legend="'center'"
                :rangeSelector="['1y', '3y', '5y', 'All']"
                :rangeSelectorSelected="2"
            />
          </div>

          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'PMI'"
                :indicators="[{code: 'US_ISM_MANUFACTURING_PMI', }, { code: 'US_ISM_SERVICES_PMI' },
                  { code: 'US_ISM_MANUFACTURING_NEW_ORDERS_INDEX', yAxis: 'right', type: 'line', color: 'orange', },
                ]"
                :type="'column'"
                :show-value="true"
                :legend="'center'"
                :rangeSelector="['1y', '3y', '5y', 'All']"
                :rangeSelectorSelected="2"
            />
            <IndicatorChart
                style="width: 66.7%; height: 400px" class="marginlr "
                :title="' Global PMI'"
                :indicators="[
                  { code: 'US_ISM_MANUFACTURING_PMI', },
                  { code: 'US_ISM_SERVICES_PMI' },
                  { code: 'CN_CAIXIN_MANUFACTURING_PMI' },
                  { code: 'CN_CAIXIN_SERVICE_PMI' },
                  { code: 'EU_MANUFACTURING_PMI' },
                  { code: 'EU_SERVICE_PMI' },
                  { code: 'GE_MANUFACTURING_PMI' },
                  { code: 'GE_SERVICE_PMI' },
                  { code: 'JP_MANUFACTURING_PMI' },
                  { code: 'JP_SERVICE_PMI' },
                ]"
                :type="'line'"
                :show-value="true"
                :legend="'center'"
                :rangeSelector="['1y', '3y', '5y', 'All']"
                :rangeSelectorSelected="2"
            />
          </div>

          <!-- row 5 start -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'就业'"
                :indicators="[ {
                  code: 'US_UNEMPLOYMENT_RATE',
                }, {
                  code: 'US_NONFARM_PAYROLL_TOTAL', yAxis: 'right', type: 'line',
                } ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="3"
            />
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'就业'"
                :indicators="[ {
                  code: 'US_NONFARM_PAYROLL_TOTAL', yAxis: 'left', type: 'line',
                }, {
                  code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE', yAxis: 'right', type: 'line',
                } ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="3"
            />
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'就业'"
                :indicators="[ {
                  code: 'US_UNEMPLOYMENT_RATE',
                }, {
                  code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE', yAxis: 'right', type: 'line',
                } ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="3"
            />
          </div>
          <!-- row 5 end -->

          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 66.6%; height: 400px" class="marginlr "
                :title="'Nonfarm Payroll Components'"
                :indicators="[
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_TOTAL', type: 'line', },
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_MINING_AND_LOGGING', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_CONSTRUCTION', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_MANUFACTURING', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_M_CHANGE_TRADE_TRANSPORTATION_AND_UTILITIES', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_INFORMATION', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_FINANCIAL_ACTIVITIES', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_M_CHANGE_PROFESSIONAL_AND_BUSINESS_SERVICES', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_EDUCATION_AND_HEALTH_SERVICES', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_LEISURE_AND_HOSPITALITY', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_OTHER_SERVICES', type: 'column', stack: 'stack' },
                  {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_GOVERNMENT', type: 'column', stack: 'stack' },
                ]"
                :show-value="true"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
            <IndicatorPieChart style="width: 33.3%; height: 400px" class="marginlr " />
          </div>

          <!-- row 5 start -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'参与率'"
                :indicators="[ {
                  code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_WOMEN',
                }, {
                  code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_MEN',
                }, {
                  code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_16_19', yAxis: 'right'
                }, {
                  code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_20_24',
                }, {
                  code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_25_54',
                }, {
                  code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_55_OVER', yAxis: 'right'
                } ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="3"
            />
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'失业率-人群'"
                :indicators="[ {
                  code: 'US_UNEMPLOYMENT_RATE_OVER20_WOMEN',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_OVER20_WOMEN_WHITE',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_OVER20_WOMEN_BLACK_OR_AFRICAN',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_OVER20_WOMEN_HISPANIC_OR_LATINO',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_OVER20_MEN',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_OVER20_MEN_WHITE',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_OVER20_MEN_BLACK_OR_AFRICAN',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_OVER20_MEN_HISPANIC_OR_LATINO',
                } ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="3"
            />
            <IndicatorChart
                style="width: 33.3%; height: 400px" class="marginlr "
                :title="'失业率-教育'"
                :indicators="[ {
                  code: 'US_UNEMPLOYMENT_RATE_LESS_THAN_A_HIGH_SCHOOL_DIPLOMA_OVER25',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_HIGH_SCHOOL_GRADUATES_NO_COLLEGE_OVER25',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_SOME_COLLEGE_OR_ASSOCIATE_DEGREE_OVER25',
                }, {
                  code: 'US_UNEMPLOYMENT_RATE_BACHELOR_DEGREE_AND_HIGHER_OVER25',
                } ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="3"
            />
          </div>
          <!-- row 5 end -->

          <!-- row 6 start -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 33%; height: 350px" class="marginlr "
                :title="'收入 & 消费'"
                :indicators="[
                  { code: 'US_PERSONAL_SPENDING_MOM', type: 'column', yAxis: 'left', },
                  { code: 'US_PERSONAL_INCOME_MOM', type: 'column',  yAxis: 'left', },
                ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="0"
            />
            <IndicatorChart
                style="width: 33%; height: 350px" class="marginlr "
                :title="'储蓄率'"
                :indicators="[
                  { code: 'US_PERSONAL_SAVINGS_RATE', type: 'column', }
                ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="2"
            />
          </div>
          <!-- row 6 end -->

          <!-- row 7 start -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 33%; height: 350px" class="marginlr "
                :title="'实际利率(TIPS)'"
                :indicators="[ {
                  code: 'US5YTIP',
                }, {
                  code: 'US10YTIP',
                } ]"
                :show-value="false"
                :legend="null"
                :rangeSelector="['3m', '1y', '3y', '5y', 'All']"
                :rangeSelectorSelected="0"
            />
            <IndicatorChart
                style="width: 33%; height: 350px" class="marginlr "
                :title="'实际利率(Cleveland Fed)'"
                :indicators="[ {
                  code: 'CLEVELANDFED_REAL_RATE_1M',
                }, {
                  code: 'CLEVELANDFED_REAL_RATE_1Y',
                }, {
                  code: 'CLEVELANDFED_REAL_RATE_10Y',
                } ]"
                :show-value="false"
                :legend="null"
                :rangeSelector="['1y', '2y', '3y', '5y', '10y', 'All']"
                :rangeSelectorSelected="3"
            />
            <IndicatorChart
                style="width: 33%; height: 350px" class="marginlr "
                :title="'通胀预期(by TIPS)'"
                :indicators="[ {
                  code: 'US10YTIP_INFLATION_EXP', yAxis: 'left',
                }, {
                  code: 'US5YTIP_INFLATION_EXP', yAxis: 'left',
                }, {
                  code: 'CLEVELANDFED_1Y_INFLATION_EXP', yAxis: 'left',
                } ]"
                :show-value="false"
                :legend="null"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="0"
            />
          </div>
          <!-- row 7 end -->

          <!-- row start SOMA -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 40%; height: 400px" class="marginlr "
                :title="'Fed Liquidity and Bitcoin'"
                :indicators="[
                  {code: 'FED_BALANCE_SHEET', yAxis: 'left', },
                  {code: 'FED_LIQUIDITY', yAxis: 'left', },
                  {code: 'BITCOIN_PRICE', yAxis: 'right',}]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
            <div style="width: 60%;" class="chart marginlr roundcorner">
              <SomaSummary />
            </div>
          </div>
          <!-- row 2 end -->

          <!-- row 2 start -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 40%; height: 400px" class="marginlr "
                :title="'新房成交'"
                :show-value="false"
                :indicators="[
                  {code: 'BEIJING_NEW_HOUSE_SALES', type: 'line', },
                  {code: 'SHANGHAI_NEW_HOUSE_SALES', type: 'line', },
                  {code: 'GUANGZHOU_NEW_HOUSE_SALES', type: 'line', },
                  {code: 'SHENZHEN_NEW_HOUSE_SALES', type: 'line', },
                  {code: 'HANGZHOU_NEW_HOUSE_SALES', type: 'line', },
                  {code: 'NANJING_NEW_HOUSE_SALES', type: 'line', },
                  {code: 'SUZHOU_NEW_HOUSE_SALES', type: 'line', },
                  {code: 'CHENGDU_NEW_HOUSE_SALES', type: 'line', }
                ]"
                :legend="'center'"
                :rangeSelector="['1y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
            <IndicatorChart
                style="width: 30%; height: 400px" class="marginlr "
                :title="null"
                :show-value="false"
                :indicators="[ {
                  code: 'CN_ABLE_TO_BREED_SOWS',
                  yAxis: 'left',
                  type: 'line',
                }, {
                  code: 'CN_ZHUWANG_PORK_CORN_PRICE_RATE',
                  yAxis: 'right',
                  type: 'line',
                }, {
                  code: 'CN_ABLE_TO_BREED_SOWS_REDUCTION',
                  yAxis: 'left',
                  type: 'line',
                  sideChart: true,
                } ]"
                :legend="'center'"
                :rangeSelector="['1y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
            <IndicatorChart
                style="width: 30%; height: 400px" class="marginlr "
                :title="null"
                :show-value="false"
                :indicators="[ {
                  code: 'CN_PORK_WHOLESALE_PRICE',
                  yAxis: 'left',
                  type: 'line',
                }, {
                  code: 'CN_ZHUWANG_PORK_PRICE',
                  yAxis: 'left',
                  type: 'line',
                }, {
                  code: 'CN_ABLE_TO_BREED_SOWS_RECIPROCAL',
                  yAxis: 'right',
                  type: 'line',
                } ]"
                :legend="'center'"
                :rangeSelector="['1y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
          </div>
          <!-- row end -->

          <!-- row 2 start -->
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <IndicatorChart
                style="width: 33%; height: 400px" class="marginlr "
                :title="'货币增速'"
                :indicators="[{code: 'CN_M0_YOY'}, {code: 'CN_M1_YOY'}, {code: 'CN_M2_YOY'}, {code: 'CN_M1_M2_YOY_DIFF', type: 'column'}]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '3y', '5y', 'All']"
                :rangeSelectorSelected="2"
            />
            <IndicatorChart
                style="width: 33%; height: 400px" class="marginlr "
                :title="'社融'"
                :indicators="[{code: 'CN_AFRE_FLOW', type: 'column', yAxis: 'left'}, {code: 'CN_AFRE_STOCK_YOY', yAxis: 'right'}]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '3y', '5y', 'All']"
                :rangeSelectorSelected="2"
            />
            <IndicatorChart
                style="width: 33%; height: 400px" class="marginlr "
                :title="'贷款 & 存款'"
                :indicators="[
                  {code: 'CN_HOUSEHOLD_FIX_DEPOSIT_ADD', type: 'column', },
                  {code: 'CN_HOUSEHOLD_LONG_DEBT_ADD', type: 'column', },
                  {code: 'CN_ENTERPRISE_LONG_DEBT_ADD', type: 'column', }
                ]"
                :show-value="false"
                :legend="'center'"
                :rangeSelector="['1y', '2y', '3y', '5y', 'All']"
                :rangeSelectorSelected="1"
            />
          </div>
          <!-- row end -->


          <!-- row 2 start -->
          <!--
          <div style="width: 100%; margin: 4px 4px;" class="flxrow">
            <StockSector class="marginlr roundcorner"/>
          </div>
          -->
          <!-- row end -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { now, formatDateShort, addMonths, } from '@/script/DateTools';
import IndicatorChart from '@/components/charts/IndicatorChart.vue';
import SomaSummary from '@/views/data/SomaSummary.vue';
import FedRateExp from './FedRateExp.vue';
import YieldCurve from './YieldCurve.vue';
import IndicatorPieChart from '../../components/charts/IndicatorPieChart.vue';

export default {
  name: 'UsMacro',
  components: { IndicatorChart, SomaSummary, FedRateExp, YieldCurve, IndicatorPieChart },
  data() {
    return {
      today: null,
      currentTimestamp: null,
      preQStartDate: null,
      preQStartTimestamp: null,
      currentQStart: '',
      preQStart: '',
      nextQStart: '',

      epsForecastIndicators: null,
      epsFullFillIndicators: null,
    };
  },
  methods: {
    load() {
      this.today = now();
      this.currentTimestamp = this.today.getTime() / 1000 | 0;
      let tmp = now();
      tmp.setDate(1);
      tmp.setHours(0);
      tmp.setMonth(Math.floor(tmp.getMonth() / 3) * 3);
      this.currentQStart = formatDateShort(tmp);
      addMonths(tmp, -3);
      this.preQStartDate = new Date(tmp.getTime());
      this.preQStartTimestamp = tmp.getTime() / 1000 | 0;

      this.preQStart = formatDateShort(tmp);
      addMonths(tmp, 6);
      this.nextQStart = formatDateShort(tmp);

      this.epsForecastIndicators = [ {
        code: 'SP500_EPS_FORECAST_' + this.currentQStart,
        start: this.preQStartTimestamp,
        end: this.currentTimestamp,
      }, {
        code: 'SP500_EPS_FORECAST_' + this.nextQStart,
        start: this.preQStartTimestamp,
        end: this.currentTimestamp,
      } ];

      this.epsFullFillIndicators = [ {
        code: 'SP500_QUARTER_EPS_FORECAST_BY_PUBLISH_' + this.preQStart,
        start: this.preQStartTimestamp,
        end: this.currentTimestamp,
        yAxis: 'left',
      }, {
        code: 'SP500_QUARTER_EPS_BY_PUBLISH_' + this.preQStart,
        start: this.preQStartTimestamp,
        end: this.currentTimestamp,
        yAxis: 'left',
      }, {
        code: 'SP500_QUARTER_EPS_FULL_FILL_RATE_' + this.preQStart,
        start: this.preQStartTimestamp,
        end: this.currentTimestamp,
        yAxis: 'right',
      } ];
    },
  },
  mounted() {
    this.load();
  },
};
</script>

