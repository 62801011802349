<template>
  <div>
    <a-tabs type="card">
      <!-- Bind Tab -->
      <a-tab-pane key="bind" tab="Bind">
        <table style="width:100%">
          <tr>
            <td class="table-td"><strong>Category</strong></td>
            <td class="table-td"><strong>Event</strong></td>
            <td class="table-td"><strong>Bind to Page Event</strong></td>
            <td class="table-td"><strong>Bind to Page Variable</strong></td>
          </tr>
          <tr v-for="e in interactEvents" :key="e.event">
            <td class="table-td-border"> {{ e.category }} </td>
            <td class="table-td-border"> {{ e.event }} </td>
            <!-- bind event -->
            <td class="table-td-border">
              <a-select style="width: 120px" size="small"
                  v-model="e.bind.pageEvent"
                  :allowClear="true"
                  >
                <div slot="dropdownRender" slot-scope="menu">
                  <v-nodes :vnodes="menu" />
                  <a-divider style="margin: 4px 0;" />
                  <div style="padding: 4px 8px;"
                      @mousedown="e => e.preventDefault()"
                      @click="e => e.stopPropagation()" >
                    <mr-icon-button name="New Event" size="11px" @click="on_showAddPageEventModal"/>
                  </div>
                </div>
                <a-select-option v-for="eName in Object.keys(pageEvents)" :key="eName" :value="eName">
                  {{ eName }}
                </a-select-option>
              </a-select>
            </td>
            <!-- bind variable -->
            <td class="table-td-border" v-if="e.event==='LOADED'">
            </td>
            <td class="table-td-border" v-if="e.event==='ROW_SELECT'">
              <div style="display: flex; flex-direction: row;">
                <a-select style="width: 120px" size="small"
                    v-model="e.bind.field"
                    :allowClear="true"
                    >
                  <a-select-option value="$">$SelectedRow</a-select-option>
                  <a-select-option v-for="f in e.options.fields" :key="f.key" :value="f.key">
                    {{ f.label }}
                  </a-select-option>
                </a-select>
                <div style="margin-left: 4px"> -> </div>
                <a-select style="width: 120px; margin-left: 4px" size="small"
                    v-model="e.bind.pageVariable"
                    :allowClear="true"
                    >
                  <div slot="dropdownRender" slot-scope="menu">
                    <v-nodes :vnodes="menu" />
                    <a-divider style="margin: 4px 0;" />
                    <div style="padding: 4px 8px;"
                        @mousedown="e => e.preventDefault()"
                        @click="e => e.stopPropagation()" >
                      <mr-icon-button name="New Variable" size="11px" @click="on_showAddPageVariableModal"/>
                    </div>
                  </div>
                  <a-select-option v-for="vName in Object.keys(pageVariables)" :key="vName" :value="vName">
                    {{ vName }}
                  </a-select-option>
                </a-select>
              </div>
            </td>
          </tr>
        </table>
      </a-tab-pane>
      <!-- Listen Tab -->
      <a-tab-pane key="listen" tab="Listen">
        <table style="width:100%">
          <tr>
            <td class="table-td"><strong>Category</strong></td>
            <td class="table-td"><strong>Action</strong></td>
            <td class="table-td"><strong>Listen to Page Event</strong></td>
            <td class="table-td"><strong>Params</strong></td>
          </tr>
          <tr v-for="a in interactActions" :key="a.action">
            <td class="table-td-border"> {{ a.category }} </td>
            <td class="table-td-border"> {{ a.action }} </td>
            <!-- page event -->
            <td class="table-td-border">
              <a-select style="width: 120px" size="small"
                  v-model="a.bind.pageEvent"
                  :allowClear="true"
                  >
                <a-select-option v-for="eName in Object.keys(pageEvents)" :key="eName" :value="eName">
                  {{ eName }}
                </a-select-option>
              </a-select>
            </td>
            <!-- params -->
            <!-- load -->
            <td class="table-td-border" v-if="a.action==='LOAD'">
              <div style="display: flex; flex-direction: row;">
                with params
                <div style="margin-left: 4px;">
                  <div v-for="param in a.bind.loadParams" :key="param.id"
                       style="display: flex; flex-direction: row;">
                    <a-select style="width: 120px; margin-left: 4px" size="small"
                        v-model="param.pageVariableName" :allowClear="true" >
                      <a-select-option v-for="vName in Object.keys(pageVariables)" :key="vName" :value="vName">
                        {{ vName }}
                      </a-select-option>
                    </a-select>
                    <div style="margin-left: 4px"> as </div>
                    <a-input v-model="param.paramName" style="width: 120px; margin-left: 4px" size="small" />
                    <mr-icon-button icon="trash" style="margin-left: 4px;" @click="on_addRemoveActionParam(param.id)" />
                  </div>
                  <a-button type="primary" style="width: 263px; margin: 4px;" size="small"
                      @click="on_addLoadActionParam">add</a-button>
                </div>
              </div>
            </td>
            <!-- row filter -->
            <td class="table-td-border" v-if="a.action==='ROW_FILTER'">
              <div style="display: flex; flex-direction: row;">
                <div> filter </div>
                <a-select style="width: 120px; margin-left: 4px" size="small"
                    v-model="a.bind.filterField" :allowClear="true" >
                  <a-select-option v-for="f in a.options.fields" :key="f.key" :value="f.key">
                    {{ f.label }}
                  </a-select-option>
                </a-select>
                <div style="margin-left: 4px"> by </div>
                <a-select style="width: 120px; margin-left: 4px" size="small"
                    v-model="a.bind.pageVariable" :allowClear="true" >
                  <a-select-option v-for="vName in Object.keys(pageVariables)" :key="vName" :value="vName">
                    {{ vName }}
                  </a-select-option>
                </a-select>
              </div>
            </td>
          </tr>
        </table>
      </a-tab-pane>
    </a-tabs>

    <!-- 新增Event Model -->
    <a-modal :visible="addPageEventModalVisible"
        :closable="false" :width="300"
        @cancel="on_closeAddPageEventModal"
        @ok="on_addPageEventModalOk"
        >
      <div>
        <div style="margin-bottom: 4px;">Event Name: </div><a-input v-model="newPageEventName"/>
        <div v-if="newPageEventNameWarn" color="red" style="color: red; font-size: 11px;">
          {{ newPageEventNameWarn }}
        </div>
      </div>
    </a-modal>

    <!-- 新增Variable Model -->
    <a-modal :visible="addPageVariableModalVisible"
        :closable="false" :width="300"
        @cancel="on_closeAddPageVariableModal"
        @ok="on_addPageVariableModalOk"
        >
      <div>
        <div style="margin-bottom: 4px;">Variable Name: </div><a-input v-model="newPageEventName"/>
        <div v-if="newPageEventNameWarn" color="red" style="color: red; font-size: 11px;">
          {{ newPageEventNameWarn }}
        </div>
      </div>
    </a-modal>

  </div>
</template>

<script>
import MrIconButton from './MrIconButton.vue';

export default {
  name: 'InteractConfig',
  props: [ 'interactEvents', 'interactActions', 'pageEvents', 'pageVariables', ],
  components: { MrIconButton,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  data() {
    return {
      addPageEventModalVisible: false,
      addPageVariableModalVisible: false,
      newPageEventName: '',
      newPageEventNameWarn: '',
    };
  },
  methods: {
    on_showAddPageEventModal() {
      this.addPageEventModalVisible = true;
    },
    on_closeAddPageEventModal() {
      this.addPageEventModalVisible = false;
    },
    on_showAddPageVariableModal() {
      this.addPageVariableModalVisible = true;
    },
    on_closeAddPageVariableModal() {
      this.addPageVariableModalVisible = false;
    },
    on_addLoadActionParam() {
      this.interactActions.forEach((a) => {
        if (a.category === 'COMMON' && a.action === 'LOAD') {
          let maxId = 0;
          a.bind.loadParams.forEach((p) => { maxId = p.id >= maxId ? p.id + 1 : maxId; });
          a.bind.loadParams.push({ id: maxId, pageVariableName: '', paramName: '' });
          return;
        }
      });
    },
    on_addRemoveActionParam(id) {
      this.interactActions.forEach((a) => {
        if (a.category === 'COMMON' && a.action === 'LOAD') {
          const newArray = [ ];
          a.bind.loadParams.forEach((p) => {
            if (p.id !== id) {
              newArray.push(p);
            }
          });
          a.bind.loadParams = newArray;
        }
      });
    },
    on_addPageEventModalOk() {
      const eName = this.newPageEventName;
      if (eName in this.pageEvents) {
        this.newPageEventNameWarn = 'event name existed';
        return;
      }
      this.$emit('addPageEvent', eName);
      this.addPageEventModalVisible = false;
      this.newPageEventName = '';
      this.newPageEventNameWarn = '';
    },
    on_addPageVariableModalOk() {
      const vName = this.newPageEventName;
      if (vName in this.pageVariables) {
        this.newPageEventNameWarn = 'variable name existed';
        return;
      }
      this.$emit('addPageVariable', vName);
      this.addPageVariableModalVisible = false;
      this.newPageEventName = '';
      this.newPageEventNameWarn = '';
    },
  },
};
</script>

<style scoped>

</style>
