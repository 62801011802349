
<template>
  <div id="body">
    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; margin: 8px 0px; color: #515A6E">
            CN <span v-if="loadRest > 0"> (loading... rest {{ loadRest }} )</span>
          </p>
        </div>

        <!-- container layout start -->
        <div class="container-layout">
          <div style="width: 100%;">
          </div>
          <!-- liked start -->
            <div v-for="it, index in likeSectorData" :key="'like' + it.code" style="width: 33.3%;" >
              <div class="thin-border" >
                <div class="title">
                  <div style="width: 100%; text-align: center;"> {{ it.name }} </div>
                  <div class="title-action">
                    <star-outlined :style="{ 'display': 'table-cell', 'vertical-align': 'middle', color: it.like ? 'red' : '#212529' }"
                      @click="likeOrUnlike(it, index)"
                    />
                  </div>
                </div>
                <CommonCandleChart
                  :reload="it.code"
                  :data="it.chartData"
                  :rangeSelector="['3m', '4m', '6m', '1y', '3y']"
                  :rangeSelectorSelected="1"
                  :legend="null"
                  :navigator="false"
                  style="height: 350px"
                />
              </div>
            </div>
          <!-- liked end -->
          <!-- not liked start -->
            <div v-for="it, index in sectorData" :key="'unlike' + it.code" style="width: 33.3%;" >
              <div class="thin-border" >
                <div class="title">
                  <div style="width: 100%; text-align: center;"> {{ it.name }} </div>
                  <div class="title-action">
                    <star-outlined :style="{ 'display': 'table-cell', 'vertical-align': 'middle', color: it.like ? 'red' : '#212529' }"
                      @click="likeOrUnlike(it, index)"
                    />
                  </div>
                </div>
                <CommonCandleChart
                  :reload="it.code"
                  :data="it.chartData"
                  :rangeSelector="['3m', '4m', '6m', '1y', '3y']"
                  :rangeSelectorSelected="1"
                  :legend="null"
                  :navigator="false"
                  style="height: 350px"
                />
              </div>
            </div>
          <!-- not liked end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doPostN, doGetN, } from '@/script/ServerTools';
import CommonCandleChart from '@/components/charts/CommonCandleChart.vue';
import { StarOutlined, } from '@ant-design/icons-vue';
import { doPost } from '../../script/ServerTools';


export default {
  name: 'CnStock',
  components: { CommonCandleChart, StarOutlined, },
  data() {
    return {
      timer: null,
      loadRest: 0,
      sectors: {
        "881101": "种植业与林业 二级行业",
        "881102": "养殖业 二级行业",
        "881103": "农产品加工 二级行业",
        "881104": "农业服务 二级行业",
        "881105": "煤炭开采加工 二级行业",
        "881107": "油气开采及服务 二级行业",
        "881108": "化学原料 二级行业",
        "881109": "化学制品 二级行业",
        "881110": "化工合成材料 二级行业",
        "881112": "钢铁 二级行业",
        "881114": "金属新材料 二级行业",
        "881115": "建筑材料 二级行业",
        "881116": "建筑装饰 二级行业",
        "881117": "通用设备 二级行业",
        "881118": "专用设备 二级行业",
        "881119": "仪器仪表 二级行业",
        "881120": "电力设备 二级行业",
        "881121": "半导体及元件 二级行业",
        "881122": "光学光电子 二级行业",
        "881123": "其他电子 二级行业",
        "881124": "消费电子 二级行业",
        "881125": "汽车整车 二级行业",
        "881126": "汽车零部件 二级行业",
        "881127": "非汽车交运 二级行业",
        "881128": "汽车服务 二级行业",
        "881129": "通信设备 二级行业",
        "881130": "计算机设备 二级行业",
        "881131": "白色家电 二级行业",
        "881132": "黑色家电 二级行业",
        "881133": "饮料制造 二级行业",
        "881134": "食品加工制造 二级行业",
        "881135": "纺织制造 二级行业",
        "881136": "服装家纺 二级行业",
        "881137": "造纸 二级行业",
        "881138": "包装印刷 二级行业",
        "881139": "家用轻工 二级行业",
        "881140": "化学制药 二级行业",
        "881141": "中药 二级行业",
        "881142": "生物制品 二级行业",
        "881143": "医药商业 二级行业",
        "881144": "医疗器械 二级行业",
        "881145": "电力 二级行业",
        "881146": "燃气 二级行业",
        "881148": "港口航运 二级行业",
        "881149": "公路铁路运输 二级行业",
        "881151": "机场航运 二级行业",
        "881152": "物流 二级行业",
        "881153": "房地产开发 二级行业",
        "881155": "银行 二级行业",
        "881156": "保险及其他 二级行业",
        "881157": "证券 二级行业",
        "881158": "零售 二级行业",
        "881159": "贸易 二级行业",
        "881160": "景点及旅游 二级行业",
        "881161": "酒店及餐饮 二级行业",
        "881162": "通信服务 二级行业",
        "881163": "计算机应用 二级行业",
        "881164": "传媒 二级行业",
        "881165": "综合 二级行业",
        "881166": "国防军工 二级行业",
        "881167": "非金属材料 二级行业",
        "881168": "工业金属 二级行业",
        "881169": "贵金属 二级行业",
        "881170": "小金属 二级行业",
        "881171": "自动化设备 二级行业",
        "881172": "电子化学品 二级行业",
        "881173": "小家电 二级行业",
        "881174": "厨卫电器 二级行业",
        "881175": "医疗服务 二级行业",
        "881176": "房地产服务 二级行业",
        "881177": "互联网电商 二级行业",
        "881178": "教育 二级行业",
        "881179": "其他社会服务 二级行业",
        "881180": "石油加工贸易 二级行业",
        "881181": "环保 二级行业",
        "881182": "美容护理 二级行业",
        "884001": "种子生产 三级行业",
        "884002": "粮食种植 三级行业",
        "884003": "其他种植业 三级行业",
        "884004": "林业 三级行业",
        "884005": "海洋捕捞 三级行业",
        "884006": "水产养殖 三级行业",
        "884007": "畜禽养殖 三级行业",
        "884008": "饲料 三级行业",
        "884009": "果蔬加工 三级行业",
        "884010": "粮油加工 三级行业",
        "884011": "其他农产品加工 三级行业",
        "884012": "农业综合 三级行业",
        "884013": "动物保健 三级行业",
        "884014": "煤炭开采 三级行业",
        "884015": "焦炭加工 三级行业",
        "884016": "油气开采 三级行业",
        "884018": "油服工程 三级行业",
        "884020": "石油加工 三级行业",
        "884021": "油品石化贸易 三级行业",
        "884022": "纯碱 三级行业",
        "884023": "氯碱 三级行业",
        "884024": "无机盐 三级行业",
        "884025": "其他化学原料 三级行业",
        "884026": "氮肥 三级行业",
        "884027": "磷肥及磷化工 三级行业",
        "884028": "农药 三级行业",
        "884030": "涂料油墨 三级行业",
        "884031": "钾肥 三级行业",
        "884032": "民爆用品 三级行业",
        "884033": "纺织化学用品 三级行业",
        "884034": "其他化学制品 三级行业",
        "884035": "复合肥 三级行业",
        "884036": "氟化工 三级行业",
        "884039": "聚氨酯 三级行业",
        "884041": "涤纶 三级行业",
        "884043": "粘胶 三级行业",
        "884044": "其他纤维 三级行业",
        "884045": "氨纶 三级行业",
        "884046": "其他塑料制品 三级行业",
        "884048": "改性塑料 三级行业",
        "884050": "其他橡胶制品 三级行业",
        "884051": "炭黑 三级行业",
        "884052": "普钢 三级行业",
        "884053": "铝 三级行业",
        "884054": "铜 三级行业",
        "884055": "铅锌 三级行业",
        "884056": "其他金属新材料 三级行业",
        "884057": "磁性材料 三级行业",
        "884059": "玻璃玻纤 三级行业",
        "884060": "水泥 三级行业",
        "884062": "其他建材 三级行业",
        "884063": "耐火材料 三级行业",
        "884064": "管材 三级行业",
        "884065": "装饰园林 三级行业",
        "884066": "房屋建设 三级行业",
        "884067": "基础建设 三级行业",
        "884068": "专业工程 三级行业",
        "884069": "机床工具 三级行业",
        "884071": "磨具磨料 三级行业",
        "884073": "制冷空调设备 三级行业",
        "884074": "其他通用设备 三级行业",
        "884075": "金属制品 三级行业",
        "884076": "纺织服装设备 三级行业",
        "884077": "工程机械 三级行业",
        "884078": "农用机械 三级行业",
        "884080": "能源及重型设备 三级行业",
        "884081": "印刷包装机械 三级行业",
        "884082": "其他专用设备 三级行业",
        "884083": "楼宇设备 三级行业",
        "884084": "环保设备 三级行业",
        "884085": "电机 三级行业",
        "884086": "电气自控设备 三级行业",
        "884088": "输变电设备 三级行业",
        "884089": "线缆部件及其他 三级行业",
        "884090": "分立器件 三级行业",
        "884091": "半导体材料 三级行业",
        "884092": "印制电路板 三级行业",
        "884093": "被动元件 三级行业",
        "884094": "面板 三级行业",
        "884095": "LED 三级行业",
        "884096": "光学元件 三级行业",
        "884098": "消费电子零部件及组装 三级行业",
        "884099": "乘用车 三级行业",
        "884100": "商用载货车 三级行业",
        "884101": "商用载客车 三级行业",
        "884105": "轨交设备 三级行业",
        "884106": "其他交运设备 三级行业",
        "884112": "冰洗 三级行业",
        "884113": "空调 三级行业",
        "884116": "其他白色家电 三级行业",
        "884117": "彩电 三级行业",
        "884118": "其他黑色家电 三级行业",
        "884119": "其他酒类 三级行业",
        "884120": "软饮料 三级行业",
        "884123": "肉制品 三级行业",
        "884124": "调味发酵品 三级行业",
        "884125": "乳品 三级行业",
        "884126": "其他食品 三级行业",
        "884128": "棉纺 三级行业",
        "884130": "印染 三级行业",
        "884131": "辅料 三级行业",
        "884132": "其他纺织 三级行业",
        "884136": "鞋帽及其他 三级行业",
        "884137": "家纺 三级行业",
        "884139": "家具 三级行业",
        "884140": "其他家用轻工 三级行业",
        "884141": "饰品 三级行业",
        "884142": "文娱用品 三级行业",
        "884143": "原料药 三级行业",
        "884144": "化学制剂 三级行业",
        "884145": "医疗设备 三级行业",
        "884146": "火电 三级行业",
        "884147": "水电 三级行业",
        "884149": "热力 三级行业",
        "884150": "新能源发电 三级行业",
        "884153": "港口 三级行业",
        "884154": "高速公路 三级行业",
        "884155": "铁路运输 三级行业",
        "884156": "机场 三级行业",
        "884157": "航空运输 三级行业",
        "884158": "多元金融 三级行业",
        "884159": "保险 三级行业",
        "884160": "百货零售 三级行业",
        "884161": "专业连锁 三级行业",
        "884162": "商业物业经营 三级行业",
        "884163": "人工景点 三级行业",
        "884164": "自然景点 三级行业",
        "884165": "旅游综合 三级行业",
        "884167": "酒店 三级行业",
        "884168": "餐饮 三级行业",
        "884172": "有线电视网络 三级行业",
        "884174": "软件开发 三级行业",
        "884176": "出版 三级行业",
        "884177": "影视院线 三级行业",
        "884178": "广告营销 三级行业",
        "884180": "航天装备 三级行业",
        "884181": "航空装备 三级行业",
        "884182": "地面兵装 三级行业",
        "884183": "航海装备 三级行业",
        "884184": "特钢 三级行业",
        "884186": "其他小金属 三级行业",
        "884188": "白酒 三级行业",
        "884189": "啤酒 三级行业",
        "884191": "航运 三级行业",
        "884200": "公交 三级行业",
        "884202": "住宅开发 三级行业",
        "884203": "产业地产 三级行业",
        "884209": "钛白粉 三级行业",
        "884210": "食品及饲料添加剂 三级行业",
        "884211": "有机硅 三级行业",
        "884212": "合成树脂 三级行业",
        "884213": "膜材料 三级行业",
        "884214": "冶钢原料 三级行业",
        "884215": "稀土 三级行业",
        "884216": "能源金属 三级行业",
        "884217": "工程咨询服务 三级行业",
        "884218": "机器人 三级行业",
        "884219": "工控设备 三级行业",
        "884220": "激光设备 三级行业",
        "884221": "其他自动化设备 三级行业",
        "884222": "光伏设备 三级行业",
        "884223": "风电设备 三级行业",
        "884224": "电池 三级行业",
        "884225": "其他电源设备 三级行业",
        "884226": "集成电路设计 三级行业",
        "884227": "集成电路制造 三级行业",
        "884228": "集成电路封测 三级行业",
        "884229": "半导体设备 三级行业",
        "884230": "品牌消费电子 三级行业",
        "884233": "休闲食品 三级行业",
        "884234": "服装 三级行业",
        "884235": "印刷 三级行业",
        "884236": "包装 三级行业",
        "884237": "瓷砖地板 三级行业",
        "884238": "血液制品 三级行业",
        "884239": "疫苗 三级行业",
        "884240": "其他生物制品 三级行业",
        "884242": "医疗耗材 三级行业",
        "884243": "体外诊断 三级行业",
        "884244": "医疗研发外包 三级行业",
        "884245": "其他医疗服务 三级行业",
        "884246": "电能综合服务 三级行业",
        "884247": "商业地产 三级行业",
        "884249": "国有大型银行 三级行业",
        "884250": "股份制银行 三级行业",
        "884251": "城商行 三级行业",
        "884252": "农商行 三级行业",
        "884254": "旅游零售 三级行业",
        "884257": "专业服务 三级行业",
        "884258": "体育 三级行业",
        "884260": "游戏 三级行业",
        "884261": "数字媒体 三级行业",
        "884262": "通信网络设备及器件 三级行业",
        "884263": "通信线缆及配套 三级行业",
        "884264": "通信终端及配件 三级行业",
        "884265": "其他通信设备 三级行业",
        "884266": "军工电子 三级行业",
        "884267": "大气治理 三级行业",
        "884268": "水务及水治理 三级行业",
        "884269": "固废治理 三级行业",
        "884270": "综合环境治理 三级行业",
        "884271": "个护用品 三级行业",
        "884272": "化妆品 三级行业",
        "884273": "医疗美容 三级行业",
        "884274": "IT服务 三级行业",
      },
      likeMap: { },
      likeSectorData: [ ],
      sectorData: [ ],
    };
  },
  methods: {
    likeOrUnlike(row, index) {
      const code = row.code;
      const like = row.like;
      console.log(row.like, index);
      if (row.like) {
        row.like = !row.like;
        this.likeSectorData.splice(index, 1);
        const i = this.sectorData.findIndex((el) => el.code > row.code);
        console.log(i);
        if (i === -1) {
          this.sectorData.push(row);
        } else {
          this.sectorData.splice(i, 0, row);
        }
      } else {
        row.like = !row.like;
        this.sectorData.splice(index, 1);
        const i = this.likeSectorData.findIndex((el) => el.code > row.code);
        console.log(i);
        if (i === -1) {
          this.likeSectorData.push(row);
        } else {
          this.likeSectorData.splice(i, 0, row);
        }
      }
      doPostN('/api/morpho/sector/update_favourite', {
        market: 'ChinaA', source: 'THS', code, favourite: like ? 'N' : 'Y',
      }).then((rsp) => { });
    },
    sortFunc(a, b) {
      if (a.like && !b.like) {
        return -1;
      }
      if (!a.like && b.like) {
        return 1;
      }
      if (a.code < b.code) {
        return -1;
      }
      return 1;
    },
    load(codes) {
      if (codes.length > 0) {
        doGetN('/api/morpho/quote/sector/last1800', { code: codes[0], } ).then((rsp) => {
          const series = [ ];
          rsp.result.forEach((it) => {
            series.push([it.timestamp * 1000, it.open, it.high, it.low, it.close]);
          });

          /*
          if (this.sectorData.length === 0 || this.sectorData[this.sectorData.length-1].length === 3) {
            this.sectorData.push([ ]);
          }
          */

          const code = codes[0];
          const d = {
            name: this.sectors[code],
            code: code,
            like: this.likeMap[code],
            chartData: [{
              name: this.sectors[code],
              data: series,
              type: 'candlestick',
              code: code,
            }],
          };
          if (this.likeMap[code]) {
            this.likeSectorData.push(d);
          } else {
            this.sectorData.push(d);
          }

          this.loadRest = codes.length - 1;
          this.timer = setTimeout(() => this.load(codes.slice(1)), 100);
        });
      }
    },
  },
  mounted() {
    doGetN('/api/morpho/sector/query_def_list', { market: 'ChinaA', source: 'THS', }).then((rsp) => {
      rsp.result.forEach((it) => {
        this.likeMap[it.code] = it.favourite === 'Y';
      });

      this.timer = this.load(Object.keys(this.sectors));
    });
  },
  destroyed() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  position: relative;
  min-height: 20px;
}
.title-action {
  height: 100%;
  display: table;
  position: absolute;
  right: 16px;
  top: 0px;
  z-index: 1000;
}
</style>
