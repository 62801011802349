<template>
  <div>
    <highcharts
        style="height: 1100px"
        :constructor-type="'stockChart'"
        :options="chartOptions"/>
  </div>
</template>

<script>
import { doPost, doGet } from '@/script/ServerTools';
export default {
  name: 'Comprehensive',
  data() {
    return {
      gdpDeclineBands: [],
      stockDeclineBands: [
        {
          // 1953
          from: -536096730000,
          to: -514323930000,
          color: '#DCDCDC',
        }, {
          // 1956
          from: -423258330000,
          to: -384810330000,
          color: '#DCDCDC',
        }, {
          // 1968
          from: -34128000000,
          to: -115200000,
          color: '#DCDCDC',
        }, {
          from: -115200000,
          to: 7574400000,
          color: '#ede1b8',
        }, {
          from: 7574400000,
          to: 12441600000,
          color: '#DCDCDC',
        }, {
          from: 95299200000,
          to: 149731200000,
          color: '#DCDCDC',
        }, {
          // 1980
          from: 344232870000,
          to: 378576000000,
          color: '#DCDCDC',
        }, {
          from: 378576000000,
          to: 386265600000,
          color: '#ede1b8',
        }, {
          from: 386265600000,
          to: 397973670000,
          color: '#DCDCDC',
        }, {
          // 2000
          from: 967766400000,
          to: 1034121600000,
          color: '#DCDCDC',
        }, {
          from: 1191888000000,
          to: 1222704000000,
          color: '#DCDCDC',
        }, {
          from: 1222704000000,
          to: 1236556800000,
          color: '#ede1b8',
        }, {
          from: 1437350400000,
          to: 1455148800000,
          color: '#DCDCDC',
        }, {
          from: 1538524800000,
          to: 1545609600000,
          color: '#DCDCDC',
        }, {
          from: 1582070400000,
          to: 1584921600000,
          color: '#DCDCDC',
        }, {
          from: 1641168000000,
          to: 1665792000000,
          color: '#DCDCDC',
        }
      ],
      chartOptions: {
        title: { text: null },
        time: {
          useUTC: false,
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            second: '%Y-%m-%d<br/>%H:%M:%S',
            minute: '%Y-%m-%d<br/>%H:%M',
            hour: '%Y-%m-%d<br/>%H:%M',
            day: '%Y/%m/%d',
            week: '%Y/%m/%d',
            month: '%Y-%m',
            year: '%Y',
          },
          plotBands: [ ],
        },
        yAxis: [
          {
            title: {
              text: 'GDP'
            },
            lineWidth: 2,
            height: '30%',
          }, {
            title: {
              text: 'CPI'
            },
            lineWidth: 2,
            offset: 0,
            height: '20%',
            top: '30%',
          }, {
            labels: {
              align: 'left',
              x: -3
            },
            title: {
              text: 'Fed Fund Rate'
            },
            lineWidth: 2,
            offset: 0,
            height: '50%',
            top: '50%',
            opposite: false,
          }, {
            title: {
              text: 'S&P500'
            },
            lineWidth: 2,
            offset: 0,
            height: '50%',
            top: '50%',
          }
        ],
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              }
            }
          }
        },
        rangeSelector: {
          buttons: [{
            type: 'month',
            count: 1,
            text: '1m',
          }, {
            type: 'month',
            count: 3,
            text: '3m',
          }, {
            type: 'month',
            count: 6,
            text: '6m',
          }, {
            type: 'year',
            count: 1,
            text: '1y',
          }, {
            type: 'year',
            count: 5,
            text: '5y',
          }, {
            type: 'year',
            count: 10,
            text: '10y',
          }, {
            type: 'year',
            count: 20,
            text: '20y',
          }],
          selected: 6,
        },
        tooltip: {
          xDateFormat: '%Y-%m-%d %H:%M',
          split: false,
          shared: true,
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            // 0.
            name: 'GDP YoY',
            type: '',
            data: [ ],
          }, {
            // 1.
            name: 'GDP SAAR',
            type: '',
            data: [ ],
          }, {
            // 2. GDP zero
            name: null,
            type: '',
            dashStyle: 'Dash',
            color: '#CC00FF',
            data: [ ],
          }, {
            // 3.
            name: 'CPI YoY',
            type: '',
            color: 'orange',
            yAxis: 1,
            data: [ ],
          }, {
            // 4. CPI 2%
            name: null,
            type: '',
            dashStyle: 'Dash',
            color: '#CC00FF',
            yAxis: 1,
            data: [ ],
          }, {
            // 5. fed fund rate
            name: 'Fed Fund Rate',
            type: '',
            color: '#FF5050',
            yAxis: 2,
            data: [ ],
          }, {
            // 6. S&P500
            name: 'S&P 500',
            type: '',
            color: 'blue',
            yAxis: 3,
            data: [ ],
          }
        ],
        credits: { enabled: false, },
      },
    };
  },
  methods: {
    buildBands() {
      this.chartOptions.xAxis.plotBands = [ ...this.gdpDeclineBands, ...this.stockDeclineBands, ];
    },
    loadGDP() {
      doGet('/api/macroIndicators/query', { name: 'USGDP' }).then((rsp) => {
        const arr = [];
        const arr2 = [];
        const bands = [];
        let start1 = null;
        let start2 = null;
        let end = null;
        rsp.data.data.forEach((it) => {
          arr.push([ it.date, it.RealYoY ]);
          arr2.push([ it.date, it.SAAR ]);

          if (start1 !== null && start2 !== null) {
            if (it.SAAR < 0) {
              end = it.date;
            } else {
              bands.push({ from: start1, to: end, color: '#FBE59A' });
              start1 = null;
              start2 = null;
              end = null;
            }
          } else {
            if (start1 !== null) {
              if (it.SAAR < 0) {
                start2 = it.date;
                end = it.date;
              } else {
                start1 = null;
                start2 = null;
              }
            } else {
              if (it.SAAR < 0) {
                start1 = it.date;
              }
            }
          }
        });
        this.chartOptions.series[0].data = arr;
        this.chartOptions.series[1].data = arr2;
        console.log(arr[0]);
        console.log(arr[arr.length-1]);
        this.chartOptions.series[2].data = [
          [ arr[0][0], 0.0 ],
          [ arr[arr.length-1][0], 0.0 ]
        ];
        this.gdpDeclineBands = bands;
        this.buildBands();
      });
    },
    loadCPI() {
      doGet('/api/macroIndicators/query', { name: 'USCPI' }).then((rsp) => {
        const arr = [];
        rsp.data.data.forEach((it) => {
            arr.push([ it.date, it.CPIYoY ]);
        });
        this.chartOptions.series[3].data = arr;
        this.chartOptions.series[4].data = [
          [ arr[0][0], 2.0 ],
          [ arr[arr.length-1][0], 2.0 ]
        ];
      });
    },
    loadFedFundRate() {
      doGet('/api/macroIndicators/query', { name: 'FedFundRate' }).then((rsp) => {
        const arr = [];
        rsp.data.data.forEach((it) => {
          arr.push([ it.date, it.FedFundRate ]);
        });
        this.chartOptions.series[5].data = arr;
      });
    },
    loadSPX() {
      doGet('/api/quote/usStock', {
        // symbol: 'ES=F',
        symbol: '^GSPC',
        start: '1948-01-01',
      }).then((rsp) => {
        const arr = [];
        rsp.data.data.forEach((it) => {
          arr.push([ it.date, Math.log(it.close) ]);
        });
        this.chartOptions.series[6].data = arr;
      });
    },
  },
  mounted() {
    this.loadGDP();
    this.loadCPI();
    this.loadFedFundRate();
    this.loadSPX();
  },
};
</script>
