import axios from 'axios';
import { backEndHost } from './ServerTools';

export function convertQuoteData(data) {
  const quoteSeries = [];
  const volSeries = [];
  const maSeries = [];
  const maDeltaSeries = [];
  const profitSeries = [];
  const trendSeries = [];

  data.quotes.forEach((quote) => {
    quoteSeries.push([quote.date, quote.open, quote.high, quote.low, quote.close]);
    volSeries.push([quote.date, quote.vol]);
    maSeries.push([quote.date, quote.ma_60]);
    maDeltaSeries.push([quote.date, quote.ma_60_delta]);
  });
  data.trends.forEach((trend) => {
    const segments = [];
    trend.fit_line.forEach((point) => {
      segments.push([point.date, point.fit_value]);
    });
    trendSeries.push(segments);
  });
  return {
    quoteSeries, volSeries, maSeries, maDeltaSeries, profitSeries, trendSeries,
  };
}

export function queryIndexQuotes(
    code, period = 'day', start = '20140101', end = 'null',
    ma = '30,33,36,39,42,45,48,51,54,57,60,63,66,69,72,75,78,91,94') {
  return new Promise((resolve, reject) => {
    if (code) {
      let path = `${backEndHost()}/api/quotes/index_daily`;
      path = `${path}?code=${code}&period=${period}&start=${start}&end=${end}&ma=${ma}`;
      console.log('==== qureyQuotes ====');
      axios.get(path).then((result) => {
        resolve(convertQuoteData(result.data.data));
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
    } else {
      resolve(null);
    }
  });
}
