<template>
  <div id="body">
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            {{ currentWord }}
          </p>
          <!-- head buttons -->
          <div style="margin-left: auto;">
            <button style="width: 120px; margin-right: 12px;" class="btn btn-p1 bg-gradient-danger"
                @click="onReview" > 没记住 </button>
            <button style="width: 120px;" class="btn btn-p1 bg-gradient-info" @click="moveNextStage" > 记住了 </button>
          </div>
        </div>

        <div class="container-layout">
          <div style="width: 100%;">
            <!-- chart start -->
              <div class="chart commargin" >
                <WordParaphrase :word="currentWord" />
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doPost, doGet } from '@/script/ServerTools';
import WordParaphrase from '../../components/dictionary/WordParaphrase.vue';

export default {
  name: 'ReviewWords',
  components: { WordParaphrase },
  data() {
    return {
      currentWord: '',
      currentWordId: '',
    };
  },
  methods: {
    getWord() {
      doGet('/api/dictionary/topToRead', { }).then((rsp) => {
        this.currentWord = rsp.data[0].word;
        this.currentWordId = rsp.data[0]._id;
      });
    },
    onReview() {
      doGet('/api/dictionary/onreview', { wordId: this.currentWordId }).then((rsp) => {
        this.getWord();
      });
    },
    moveNextStage() {
      doGet('/api/dictionary/movestage', { wordId: this.currentWordId }).then((rsp) => {
        this.getWord();
      });
    },
  },
  mounted() {
    this.getWord();
  },
};
</script>
