<template>
  <div id='CandleStick'>
    <highcharts id='candleStick' style="height:100%" ref='chart' class="stock"
    :constructor-type="'stockChart'"
    :options="stockOptions"/>
  </div>
</template>

<script>
export default {
  name: 'CandleStick',
  props: ['stockConfig', 'options', 'extremes', 'quoteData'],
  data() {
    return {};
  },
  watch: {
    extremes(newValue) {
      if (newValue[2] !== this.stockConfig.stockName) {
        this.$refs.chart.chart.xAxis[0].setExtremes(newValue[0], newValue[1]);
      }
    },
  },
  computed: {
    stockOptions() {
      const temp = [
        {
          type: 'candlestick',
          data: this.quoteData.quoteSeries,
          color: '#33AA11',
          lineColor: '#33AA11',
          upColor: '#DD2200',
          upLineColor: '#DD2200',
        },
        {
          type: 'line',
          data: this.quoteData.maSeries,
          lineWidth: 1,
          color: '#DD2200',
        },
        {
          id: 'trend',
          type: 'line',
          data: [],
          lineWidth: 1,
          color: '#49a9ee',
        },
        {
          type: 'column',
          data: this.quoteData.volSeries,
          yAxis: 1,
        },
        {
          type: 'line',
          data: this.quoteData.maDeltaSeries,
          yAxis: 2,
          lineWidth: 1,
          threshold: 0,
          color: 'red',
          negativeColor: 'green',
        },
        /*
        ...this.quoteData.trendSeries,
        {
          type: 'column',
          data: this.quoteData.profitSeries,
          yAxis: 3,
          lineWidth: 1,
          color: '#DD2200',
        },
        */
      ];
      if (this.quoteData.trendSeries) {
        this.quoteData.trendSeries.forEach((segment) => {
          temp.push({
            type: 'line',
            data: segment,
            lineWidth: 2,
            color: '#49a9ee',
            linkedTo: 'trend',
          });
        });
      }
      return {
        title: { },
        series: temp,
        chart: { backgroundColor: '#FFFFFF' },
        colors: ['#616DA0'],
        xAxis: {
          type: 'datetime',
          labels: { format: '{value: %y-%m-%d}' },
          events: {
            afterSetExtremes: (e) => {
              console.log('==== afterSetExtremes ====');
              if (e.trigger) {
                setTimeout(() => {
                  console.log('==== emit ====');
                  this.$emit('extremeChangeEvent', [e.min, e.max, this.stockConfig.stockName]);
                }, 300);
              }
            },
          },
        },
        yAxis: [{
          labels: { align: 'right', x: -3 },
          title: { text: this.stockConfig.stockName },
          height: '60%',
          resize: { enabled: true },
          lineWidth: 2,
        }, {
          labels: { align: 'right', x: -3 },
          title: { text: '成交量' },
          top: '60%',
          height: '20%',
          offset: 0,
          lineWidth: 2,
        }, {
          labels: { align: 'right', x: -3 },
          title: { text: 'MA Delta' },
          top: '80%',
          height: '20%',
          offset: 0,
          lineWidth: 2,
          plotLines: [{
            value: 0,
            width: 0.5,
            color: '#000000',
            zIndex: 1,
          }],
        },
        /*
        {
          labels: { align: 'right', x: -3 },
          title: { text: 'Profit Rate' },
          top: '80%',
          height: '20%',
          mix: -50,
          max: 50,
          offset: 0,
          lineWidth: 2,
        } */
        ],
        plotOptions: {
          series: {
            dataGrouping: {
              dateTimeLabelFormats: {
                millisecond: ['%m/%d/%Y', '%m/%d/%Y %H:%M', '-%H:%M'],
                second: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                minute: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                hour: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                day: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                week: ['%m/%d/%Y', '%m/%d/%Y %H:%M', '-%H:%M'],
                month: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                year: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
              },
            },
          },
        },
        rangeSelector: {
          buttons: [
            { type: 'year', count: 1, text: '1Y' },
            { type: 'year', count: 2, text: '2Y' },
            { type: 'year', count: 3, text: '3Y' },
          ],
          selected: 3,
          buttonTheme: {},
          inputEnabled: false, // 不显示日期输入框
        },
        navigator: {
          enabled: this.options.showNavigator,
          adaptToUpdatedData: true,
        },
        credits: { enabled: false },
        tooltip: {
          split: false,
          shared: true,
          xDateFormat: '%m/%d/%Y %H:%M',
        },
      };
    },
  },
  methods: {
  },
  mounted() {
  },
};
</script>

<style>
</style>
