<template>
  <div id="body">
    <div id='content'>
      <div class="mr-main">
      <!-- PAGE HEAD -->
      <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
        <!-- table title -->
        <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;"> Home </p>
      </div>

      <div class="mr-row">
        <div class="mr-chart-common home-note">
          <p>宏观 <a href="https://docs.google.com/document/d/1icZvV_oaHYofTugNHlS0NqdgsLdYtFr2XCpgfaSe0bE/edit" target='_blank'>贝乐斯的宏观分析</a></p>
          <p>信用/M2，是资产价格上涨的非常重要原因，放水推动资产价格上涨；股票、黄金、比特币、房地产</p>
          <p>首次流向（新增信用冲击最先流向的资产价格)</p>
          <ul>
            <li>美国国债、资产抵押债券 -> 美股（美联储“证券购买”）</li>
            <li>中国房地产</li>
          </ul>
          <p>黄金 <a href="https://docs.google.com/document/d/1xNw4ddrEyriFGWMIGWnWRNlbKKSutrs8eXRb42VIuPk/edit" target='_blank'>黄金的逻辑</a></p>

          <p>房地产</p>
          <ul>
            <li><a class="note-highlight">指标：新增房贷</a>（新增房贷支撑房价）</li>
          </ul>
          <p>M2，M1，国债收益率</p>
          <p>社融、各项贷款增长、个人房贷增长</p>
          <p>道琼斯、上证指数、沪深300</p>
          <p>CPI、PPI</p>
          <p><a href="http://www.pbc.gov.cn/goutongjiaoliu/113456/index.html" target='_blank'>金融机构贷款投向统计报告 & 月度金融统计数据报告</a></p>
          <p><a href="http://www.pbc.gov.cn/diaochatongjisi/116219/116319/index.html" target='_blank'>社融</a></p>
        </div>
        <div class="mr-chart-common home-note">
          <p>当前判断:</p>
          <ul>
            <li><a class="note-highlight">不要负债</a></li>
            <li><a class="note-highlight">不要持有债权</a></li>
            <li><a class="note-highlight">现金、黄金</a></li>
          </ul>
          <p>长期看好黄金</p>
          <p>近期内不会再降息，放水之后会有通胀</p>
        </div>
        <div class="mr-chart-common home-note">
          <p>股票：</p>
          <ul>
            <li>基本面、技术分析两手都要硬</li>
            <li><a href="https://docs.google.com/document/d/11jtlp7dV0LcX_aIsGQBEJzdaOslp04yQdelHcXvg-g4/edit" target='_blank'>嘎子学艺</a></li>
            <li>微观层面，股市风格是投资者对于现金流增长路径的押注：线性or非线性，短期主要受长债利率驱动。</li>
          </ul>
          <p>大作手：</p>
          <ul>
            <li>趋势，往阻力最小的方向发展</li>
            <li>金字塔加仓，按最后买入价格设置-1%止损点，回落至止损点立即平仓;</li>
            <li>摆脱希望和控制的控制，毫无感情的判断当前状况</li>
            <ul>
              <li>仍然处于上升趋势，继续加仓</li>
              <li>出现回落，上升趋势疲软，立即平仓</li>
            </ul>
          </ul>
          <p>技术分析：</p>
          <ul>
            <li>Range expansion + 成交量放大</li>
            <li>一元线性拟合趋势分析</li>
          </ul>
          <p>利息上行，利好银行股 —— 2020.11.29</p>
        </div>
      </div>
      <div class="mr-row">
        <highcharts class="mr-chart-common key-index"
            :constructor-type="'stockChart'"
            :options="USTreasuryChartOptions">
        </highcharts>
        <highcharts class="mr-chart-common key-index"
            :constructor-type="'stockChart'"
            :options="CNTreasuryChartOptions">
        </highcharts>
      </div>
      <div class="mr-row">
        <highcharts class="mr-chart-common key-index"
            :constructor-type="'stockChart'"
            :options="chinaFinancingOptions">
        </highcharts>
      </div>
      <div class="mr-row">
        <highcharts class="mr-chart-common key-index"
            :constructor-type="'stockChart'"
            :options="CFREOptions">
        </highcharts>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {
  queryUSTreasuryYield,
  queryCNTreasuryYield,
  queryChinaAFRE,
  queryChinaFinancing,
} from '@/script/EconomyQuery';

export default {
  name: 'Home',
  components: { },
  data() {
    return {
      USTreasuryYieldSeries1Year: [],
      USTreasuryYieldSeries3Year: [],
      USTreasuryYieldSeries5Year: [],
      USTreasuryYieldSeries10Year: [],
      USSelectRange: -1,
      CNTreasuryYieldSeries1Year: [],
      CNTreasuryYieldSeries3Year: [],
      CNTreasuryYieldSeries5Year: [],
      CNTreasuryYieldSeries10Year: [],
      CNSelectRange: -1,
      CFREStock: [],
      CFREFlow: [],
      CFRERmbLoans: [],
      CFREGrowthRate: [],
      CFRESelectRange: -1,
      m0: [],
      m1: [],
      m2: [],
      m0GrowthRate: [],
      m1GrowthRate: [],
      m2GrowthRate: [],
      finacingRangeSelect: -1,
    };
  },
  computed: {
    // ==== 国债利率图表通用配置 ====
    commonOptions() {
      return {
        xAxis: {
          type: 'datetime',
        },
        legend: {
          enabled: true,
          floating: true,
          align: 'left',
          verticalAlign: 'top',
          y: 0,
        },
        rangeSelector: {
          allButtonsEnabled: true,
          buttons: [
            { type: 'month', count: 3, text: '3m' },
            { type: 'month', count: 6, text: '6m' },
            { type: 'year', count: 1, text: '1y' },
            { type: 'year', count: 5, text: '5y' },
            { type: 'year', count: 10, text: '10y' },
          ],
        },
        plotOptions: {
          series: {
            dataGrouping: {
              dateTimeLabelFormats: {
                millisecond: ['%m/%d/%Y', '%m/%d/%Y %H:%M', '-%H:%M'],
                second: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                minute: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                hour: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                day: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                week: ['%m/%d/%Y', '%m/%d/%Y %H:%M', '-%H:%M'],
                month: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
                year: ['%m/%d/%Y %H:%M', '%m/%d/%Y %H:%M', '-%H:%M'],
              },
            },
          },
        },
        navigator: {
          height: 20,
        },
        credits: { enabled: false },
        tooltip: {
          shared: true,
          split: false,
          xDateFormat: '%m/%d/%Y %H:%M',
        },
      };
    },
    USTreasuryChartOptions() {
      const options = { ...this.commonOptions };
      options.rangeSelector.selected = this.USSelectRange;
      options.title = 'US Treasury Yield';
      options.series = [{
        type: 'line',
        name: '1Year',
        data: this.USTreasuryYieldSeries1Year,
        showInLegend: true,
        color: '#f7629e',
        lineWidth: 1,
      }, {
        type: 'line',
        name: '3Year',
        data: this.USTreasuryYieldSeries3Year,
        showInLegend: true,
        color: '#f78e3d',
        lineWidth: 1,
      }, {
        type: 'line',
        name: '5Year',
        data: this.USTreasuryYieldSeries5Year,
        showInLegend: true,
        color: '#76d0a3',
        lineWidth: 1,
      }, {
        type: 'line',
        name: '10Year',
        data: this.USTreasuryYieldSeries10Year,
        showInLegend: true,
        color: '#49a9ee',
        lineWidth: 1,
      }];
      return options;
    },
    CNTreasuryChartOptions() {
      const options = { ...this.commonOptions };
      options.rangeSelector.selected = this.CNSelectRange;
      options.title = 'CN Treasury Yield';
      options.series = [{
        type: 'line',
        name: '1Year',
        data: this.CNTreasuryYieldSeries1Year,
        showInLegend: true,
        color: '#f7629e',
        lineWidth: 1,
      }, {
        type: 'line',
        name: '3Year',
        data: this.CNTreasuryYieldSeries3Year,
        showInLegend: true,
        color: '#f78e3d',
        lineWidth: 1,
      }, {
        type: 'line',
        name: '5Year',
        data: this.CNTreasuryYieldSeries5Year,
        showInLegend: true,
        color: '#76d0a3',
        lineWidth: 1,
      }, {
        type: 'line',
        name: '10Year',
        data: this.CNTreasuryYieldSeries10Year,
        showInLegend: true,
        color: '#49a9ee',
        lineWidth: 1,
      }];
      return options;
    },
    chinaFinancingOptions() {
      const options = { ...this.commonOptions };
      options.rangeSelector.selected = this.CFRESelectRange;
      options.title = '货币';
      options.series = [{
        type: 'column',
        name: 'M0',
        data: this.m0,
        showInLegend: true,
        color: '#3dbd7d',
        yAxis: 0,
      }, {
        type: 'column',
        name: 'M1',
        data: this.m1,
        showInLegend: true,
        color: '#49a9ee',
        lineWidth: 1,
        yAxis: 0,
      }, {
        type: 'column',
        name: 'M2',
        data: this.m2,
        showInLegend: true,
        color: '#f78e3d',
        lineWidth: 1,
        yAxis: 0,
      }, {
        type: 'line',
        name: 'M0 Rate',
        data: this.m0GrowthRate,
        showInLegend: true,
        color: '#3dbd7d',
        lineWidth: 1,
        yAxis: 1,
      }, {
        type: 'line',
        name: 'M1 Rate',
        data: this.m1GrowthRate,
        showInLegend: true,
        color: '#49a9ee',
        lineWidth: 1,
        yAxis: 1,
      }, {
        type: 'line',
        name: 'M2 Rate',
        data: this.m2GrowthRate,
        showInLegend: true,
        color: '#f78e3d',
        lineWidth: 1,
        yAxis: 1,
      }];
      options.yAxis = [
        { },
        { opposite: true },
      ];
      return options;
    },
    CFREOptions() {
      const options = { ...this.commonOptions };
      options.rangeSelector.selected = this.CFRESelectRange;
      options.title = '社融';
      options.series = [{
        type: 'column',
        name: '社融增量',
        data: this.CFREFlow,
        showInLegend: true,
        color: '#49a9ee',
        yAxis: 0,
      }, {
        type: 'column',
        name: '人民币贷款',
        data: this.CFRERmbLoans,
        showInLegend: true,
        color: '#f78e3d',
        lineWidth: 1,
        yAxis: 0,
      }];
      options.yAxis = [{}, { opposite: false }];
      return options;
    },
  },
  methods: {
  },
  mounted() {
    queryUSTreasuryYield().then((result) => {
      [
        this.USTreasuryYieldSeries1Year,
        this.USTreasuryYieldSeries3Year,
        this.USTreasuryYieldSeries5Year,
        this.USTreasuryYieldSeries10Year] = result;
      setTimeout(() => {
        this.USSelectRange = 4;
      }, 300);
    });
    queryCNTreasuryYield().then((result) => {
      [
        this.CNTreasuryYieldSeries1Year,
        this.CNTreasuryYieldSeries3Year,
        this.CNTreasuryYieldSeries5Year,
        this.CNTreasuryYieldSeries10Year] = result;
      setTimeout(() => {
        this.CNSelectRange = 4;
      }, 300);
    });
    queryChinaAFRE().then((result) => {
      [this.CFREStock, this.CFREFlow, this.CFREGrowthRate, this.CFRERmbLoans] = result;
      setTimeout(() => {
        this.CFRESelectRange = 3;
      }, 300);
    });
    queryChinaFinancing().then((result) => {
      [this.m0, this.m1, this.m2, this.m0GrowthRate, this.m1GrowthRate, this.m2GrowthRate] = result;
      setTimeout(() => {
        this.finacingRangeSelect = 3;
      }, 300);
    });
  },
};
</script>

<style>
.key-index {
  width: 50%;
  height: 300px;
}
.home-note li {
  margin: 0px 0px;
  font-size: 14px;
}
.home-note ul {
  margin: 4px 0px 4px 0px;
}
.home-note a {
  color: #0e77ca;
}
.note-highlight {
  background-color: orange;
}
</style>
