import { doPost, } from './ServerTools';

export function insertOne(modelName, data) {
  return doPost('/api/datamodel/insert_one', {
    model_name: modelName,
    data: data,
  });
}

export function queryData(modelName, condition) {
  return doPost('/api/datamodel/query_data', {
    model_name: modelName,
    condition: condition,
  });
}

export function updateData(modelName, condition, data) {
  return doPost('/api/datamodel/update_data', {
    model_name: modelName,
    condition: condition,
    data: data,
  });
}

const DataModelAPIs = { insertOne, queryData, updateData, };
export default DataModelAPIs;
