import { doPost, doGet } from './ServerTools';

export function remote_createPage(title) {
  return doPost('/api/page/create_page', { title });
}

export function remote_pageQueryPages() {
  return doGet('/api/page/page_query_pages');
}

export function remote_queryPageById(pageId) {
  return doGet(`/api/page/query_page_by_id?page_id=${pageId}`);
}

export function remote_updatePage(data) {
  return doPost('/api/page/update_page', data);
}

export function remote_updatePageLayout(pageId, list) {
  return doPost('/api/page/update_page_layout', {
    page_id: pageId, layout: JSON.stringify(list),
  });
}

export function remote_savePage(pageId, { title, layout, pageEvents, pageVariables}) {
  const data = { _id: pageId };
  if (title) {
    data.title = title;
  }
  if (layout !== undefined) {
    data.layout = JSON.stringify(layout);
  }
  if (pageEvents !== undefined) {
    data.page_events = pageEvents;
  }
  if (pageVariables !== undefined) {
    data.page_variables = pageVariables;
  }
  return doPost('/api/page/save_page', data);
}

export function remote_deletePage(page_id) {
  return doPost('/api/page/delete_page', { page_id });
}

function convertChartToRaw(chart) {
  return {
    _id: chart.id,
    page_id: chart.page_id,
    title: chart.title,
    chart_type: chart.chartType,
    width: parseInt(chart.width),
    width_unit: chart.widthUnit,
    height: parseInt(chart.height),
    hide_title: chart.hideTitle,
    stream_id: chart.streamId,
    node_id: chart.nodeId,
    interact_events: JSON.stringify(chart.interactEvents),
    interact_actions: JSON.stringify(chart.interactActions),
    call_mode: chart.callMode,
  };
}

export function remote_createChart(pageId, chart) {
  const raw = convertChartToRaw(chart);
  raw.page_id = pageId;
  return doPost('/api/page/create_chart', raw);
}

export function remote_queryChartById(chartId) {
  return doGet(`/api/page/query_chart_by_id?chart_id=${chartId}`);
}

export function remote_queryPageCharts(pageId) {
  return doGet(`/api/page/query_page_charts?page_id=${pageId}`);
}

function convertRawToChart(raw) {
  return {
    id: raw._id,
    title: raw.title,
    chartType: raw.chart_type,
    width: raw.width,
    widthUnit: raw.width_unit,
    height: raw.height,
    hideTitle: raw.hide_title,
    text: raw.text,
    streamId: raw.stream_id,
    nodeId: raw.node_id,
    callMode: raw.call_mode,
    interactEvents: raw.interact_events ? JSON.parse(raw.interact_events) : [ ],
    interactActions: raw.interact_actions ? JSON.parse(raw.interact_actions) : [ ],
  };
}

function buildChartsRecurse(list, rawMap) {
  const result = [ ];
  list.forEach((it) => {
    const raw = rawMap[it.id];
    const chart = convertRawToChart(raw);
    if (it.children) {
      chart.children = buildChartsRecurse(it.children, rawMap);
    }
    result.push(chart);
  });
  return result;
}

export function queryChartsRecurse(pageId, layout) {
  return new Promise((resolve, reject) => {
    remote_queryPageCharts(pageId).then((result) => {
      const rawMap = { };
      result.data.forEach((it) => {
        rawMap[it._id] = it;
      });
      const chartsData = buildChartsRecurse(layout, rawMap);
      resolve(chartsData);
    });
  });
}

export function remote_updateMarkdownText(chartId, text) {
  return doPost('/api/page/update_markdown_text', {
    chart_id: chartId, text
  });
}

export function remote_saveChart(chartConfig) {
  return doPost('/api/page/save_chart', convertChartToRaw(chartConfig));
}

export function remote_deleteChart(chart_id) {
  return doPost('/api/page/delete_chart', { chart_id });
}
