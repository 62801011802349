import { doPost, doGet, } from './ServerTools';

export function create(name) {
  return doPost('/api/form/create', { name });
}

export function getById(formId) {
  return doGet(`/api/form/querybyid?id=${formId}`);
}

export function queryByPage() {
  return doGet('/api/form/pagequery');
}

export function updateName(formId, name) {
  return doPost('/api/form/update', { id: formId, name });
}

export function updateSchema(formId, api, schema) {
  return doPost('/api/form/update', { id: formId, api, schema });
}

export function deleteForm(formId) {
  return doPost('/api/form/delete', { id: formId });
}

const FormAIPS = { create, getById, queryByPage, updateName, updateSchema, deleteForm };
export default FormAIPS;
