<template>
  <div>
    <aside id="aside-menu">
      <section>
        <ul class="sidebar-menu">
          <li v-for="item in menu" :key="item.name">
            <a v-bind:href="item.path">
              <span v-if="mobile">{{ item.name[0] }}</span>
              <span v-else>{{ item.name }}</span>
            </a>
          </li>
        </ul>
      </section>
    </aside>
  </div>
</template>

<script>
import { check_login } from '@/script/AccountOperations';

export default {
  name: 'MrMenu',
  props: [ 'mobile' ],
  data() {
    return {
      menu: [
        { name: 'Data', path: '/data/usmacro' },
        { name: 'Indicators', path: '/indicatormng' },
        { name: 'Spiders', path: '/spiders' },
        { name: 'Research', path: '/research' },
        { name: 'CN', path: '/data/cnstock' },
        { name: 'Option', path: '/trade/oo' },
        { name: 'Docs', path: '/docrepos' },
        { name: 'Macro', path: '/macro' },
        // { name: 'USEquity', path: '/usequity' },
        { name: 'Trading', path: '/trade' },
        // { name: 'Home', path: '/home' },
        { name: 'Pages', path: '/pages' },
        { name: 'Streams', path: '/streams' },
        // { name: 'ShortTrend', path: '/shortrend' },
        // { name: 'Forms', path: '/forms' },
        // { name: 'Scanners', path: '/scanners' },
        // { name: 'Industries', path: '/industry' },
        // { name: 'BoxBreak', path: '/boxbreak' },
        { name: 'Dictionary', path: '/searchword' },
        { name: 'WordReview', path: '/revieword' },
      ],
    };
  },
  mounted() {
    check_login().catch((result) => {
      console.log(result);
      if (result.errcode === 5002) {
        this.$router.push('/login');
      }
    });
  },
};
</script>

<style>
#aside-menu {
  flex-shrink: 0;
  height: calc(100vh - 40px);
  background: rgb(255, 255, 255);
  /*
  background: rgb(34,45,50);
  */
}
.sidebar-menu {
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  /*
  background-color: gray;
  */
  list-style: none;
}
.sidebar-menu > li {
  display: list-item;
  text-align: -webkit-match-parent;
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.sidebar-menu > li > a {
  display: block;
  padding: 12px 5px 12px 15px;
  border-left: 3px solid transparent;
  color: #515A6E;
  /*
  color: #b8c7ce;
  color: rgb(51, 51, 51);
  */
  text-decoration: none;
}
.sidebar-menu > li.active > a,
.sidebar-menu > li > a:hover {
  color: #ffffff;
  background: #bfbfbf;
  /*
  background: #1e282c;
  */
  border-left-color: #3c8dbc;
}
</style>
