<template>
  <div id="body">
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <!--
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            Search Word
          </p>
          -->
            <a-input-search
              v-model="inputWord"
              placeholder="input search text"
              style="width: 300px"
              @search="onSearch"
            />
          <!-- head buttons -->
          <div style="margin-left: auto;">
          </div>
        </div>

        <div class="container-layout">
          <div style="width: 100%;">
            <!-- chart start -->
              <div class="chart commargin" >
                <WordParaphrase :word="searchingWord" />
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doPost, doGet } from '@/script/ServerTools';
import WordParaphrase from '../../components/dictionary/WordParaphrase.vue';

export default {
  name: 'SearchWord',
  components: { WordParaphrase },
  data() {
    return {
      inputWord: '',
      searchingWord: '',
    };
  },
  methods: {
    onSearch(word) {
      this.searchingWord = this.inputWord;
    },
  },
};
</script>
