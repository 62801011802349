<template>
  <div v-show="visible" class="context-menu"
        :style="{left: options.left+'px', top: options.top+'px'}">
    <div v-for="it in options.items" :key=it.title @click="it.callback">
      <i :class="it.icon"></i> <span>{{it.title}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContextMenu',
  props: ['options'],
  data() {
    return { };
  },
  computed: {
    visible() {
      if (this.options === undefined || this.options.visible === undefined) {
        return false;
      }
      return this.options.visible;
    },
    left() {
      if (this.options === undefined || this.options.left === undefined) {
        return 0;
      }
      return this.options.left;
    },
    top() {
      if (this.options === undefined || this.options.top === undefined) {
        return 0;
      }
      return this.options.top;
    },
    items() {
      if (this.options === undefined || this.options.items === undefined) {
        return [];
      }
      return this.options.items;
    },
  },
};
</script>

<style>
.context-menu {
  position: absolute;
  width: 120px;
  border: 3px solid #ebe5e6;
  border-radius: 3px;
  background-color: #ebe5e6;
  box-shadow: 1px 1px 3px #888888;
  z-index: 9999;

  font-size: 11px;

  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
