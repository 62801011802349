<template>
  <div>
    <!-- chart title start -->
    <div style="display: flex; align-items: center; margin-bottom: 0px;">
      <h6> Stories </h6>
      <div style="margin-left: auto;">
        <button class="btn btn-p1 bg-gradient-danger " @click="onShowCreateFactModel" > + </button>
      </div>
    </div>
    <!-- chart title end  -->
    <div style="height: 450px; overflow-y: scroll;" >
      <div v-for="(fact, index) in facts" :key="fact._id"
            v-bind:class="{'tp-1': index !== 0, 'bp-1': index !== facts.length - 1 }" >
        <story-item :fact="fact" />
      </div>
    </div>

    <!-- trade chance modal start -->
    <a-modal
        :title="factModalTitle"
        :visible="factModalVisible"
        :footer="null"
        class="local-modal"
        @cancel="onCloseFactModal"
    >
      <a-form-model :model="factFormData"
          :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="Name" required style="marginBottom: 8">
          <a-input v-model="factFormData.name"/>
        </a-form-model-item>
        <a-form-model-item label="Summary" required >
          <a-input v-model="factFormData.summary" type="textarea" />
        </a-form-model-item>
        <a-form-model-item label="Occur Time" required >
          <a-date-picker v-model="factFormData.gmt_occur" show-time
            placeholder="Select Time"
            format="YYYY-MM-DD HH:mm:ss"
            :showToday="true"
          />
        </a-form-model-item>
        <a-form-model-item label="Story" required >
          <a-select
            show-search
            mode="multiple"
            v-model="factFormData.story_ids"
            placeholder="input search text"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="onSearchStories"
            @focus="() => onSearchStories('')"
            @change="() => {}"
            >
            <div slot="dropdownRender" slot-scope="menu">
              <v-nodes :vnodes="menu" />
              <a-divider style="margin: 4px 0;" />
              <div style="padding: 4px 8px;"
                  @mousedown="e => e.preventDefault()"
                  @click="e => e.stopPropagation()" >
                <a @click="onShowAddStoryModel" > New Story </a>
              </div>
            </div>
            <a-select-option v-for="d in serachedStories" :key="d._id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
          <a-button @click="onCloseFactModal"> Cancel </a-button>
          <a-button style="margin-left: 12px;" type="primary" @click="onSubmitFactForm">
            Submit
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- trade chance modal end -->

    <a-modal
        :title="storyModelTitle"
        :visible="storyModelVisible"
        :closable="false" :width="300"
        class="local-modal"
        @cancel="onCloseStoryModal"
        @ok="onAddStoryModalOk"
    >
      <div>
        <div style="margin-bottom: 4px;">
          Story Name: <a-input v-model="newStoryName"/>
        </div>
        <div style="margin-bottom: 4px;">
          Summary: <a-input type="textarea" v-model="newStorySummary"/>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { doPost, doGet } from '@/script/ServerTools';
import StoryItem from './StoryItem.vue';

export default {
  name: 'Stories',
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
    StoryItem,
  },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      factModalTitle: '',
      factModalVisible: false,
      factFormData: {
        _id: '',
        gmt_occur: '',
        name: '',
        summary: '',
        story_ids: [],
      },
      serachedStories: [ ],

      storyModelTitle: '',
      storyModelVisible: false,
      newStoryName: '',
      newStorySummary: '',

      facts: [ ],

    };
  },
  methods: {
    resetFormData() {
      this._id = '';
      this.factFormData.name = '';
      this.factFormData.summary = '';
      this.factFormData.story_ids = [];
    },
    onShowCreateFactModel() {
      this.factModalTitle = 'New Fact';
      this.resetFormData();
      this.factModalVisible = true;
    },
    onCloseFactModal() {
      this.factModalVisible = false;
    },
    onSubmitFactForm() {
      const data = { ...this.factFormData };
      data.gmt_occur = data.gmt_occur.unix();
      if (!this.factFormData._id) {
        console.log(data);
        doPost('/api/story/createFact', data).then((rsp) => {
          this.$message({ message: rsp.message, type: 'success' });
          this.onCloseFactModal();
          this.loadFacts();
        });
      }
    },
    onSearchStories(name) {
      doGet('/api/story/queryStories', { page_no: 0, page_size: 20, name }).then((rsp) => {
        this.serachedStories = rsp.data.items;
      });
    },
    onShowAddStoryModel() {
      this.storyModelTitle = 'New Story';
      this.newStoryName = '';
      this.newStorySummary = '';
      this.storyModelVisible = true;
    },
    onCloseStoryModal() {
      this.storyModelVisible = false;
    },
    onAddStoryModalOk() {
      doPost('/api/story/createStory', { name: this.newStoryName, summary: this.newStorySummary }).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
        this.onCloseStoryModal();
      });
    },

    // ============================
    loadFacts() {
      doGet('/api/story/queryFactsWithStory', {}).then((rsp) => {
        this.facts = rsp.data.items;
      });
    },
  },
  mounted() {
    this.loadFacts();
  },
};
</script>
