export function now() {
  return new Date();
}

export function formatDatetime(date) {
  return date.getFullYear()
    + '-' + ('0' + (date.getMonth()+1) ).slice(-2)
    + '-' + ('0' + date.getDate() ).slice(-2)
    + ' ' + ('0' + date.getHours()).slice(-2)
    + ':' + ('0' + date.getMinutes()).slice(-2)
    + ':' + ('0' + date.getSeconds()).slice(-2);
}

export function formatDate(date) {
  return date.getFullYear()
    + '-' + ( '0' + (date.getMonth()+1) ).slice(-2)
    + '-' + ( '0' + date.getDate() ).slice(-2);
}

export function formatDate2(date) {
  return date.getFullYear()
    + '/' + ( '0' + (date.getMonth()+1) ).slice(-2)
    + '/' + ( '0' + date.getDate() ).slice(-2);
}

export function timestampToFormatDateTime(timestamp) {
  const date = new Date(timestamp * 1000);
  return formatDatetime(date);
}

export function timestampToFormatDate(timestamp) {
  const date = new Date(timestamp * 1000);
  return formatDate(date);
}

export function timestampToFormatDate2(timestamp) {
  const date = new Date(timestamp * 1000);
  return formatDate2(date);
}

export function formatDateShort(date) {
  return date.getFullYear()
    + ( '0' + (date.getMonth()+1) ).slice(-2)
    + ( '0' + date.getDate() ).slice(-2);
}

export function addMonths(date, months) {
  let d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}
