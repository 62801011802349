/* eslint-disable camelcase */
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
import Antd from 'ant-design-vue';
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import loadDrillDown from 'highcharts/modules/drilldown';
import darkUnica from "highcharts/themes/dark-unica";
import darkBlue from "highcharts/themes/dark-blue";
import stockInit from 'highcharts/modules/stock';
import ElementUI from 'element-ui';
import mavonEditor from 'mavon-editor';
import jquery_contextmenu from 'jquery-contextmenu';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueMoment from 'vue-moment';

import 'ant-design-vue/dist/antd.css';
import 'mavon-editor/dist/css/index.css';
import 'element-ui/lib/theme-chalk/index.css';
import 'jquery-contextmenu/dist/jquery.contextMenu.css';
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme
import './assets/softui.css';

loadDrillDown(Highcharts);

stockInit(Highcharts);
// darkUnica(Highcharts);
Vue.use(HighchartsVue, { Highcharts });
Vue.use(Antd);
Vue.use(ElementUI);
Vue.use(mavonEditor);
Vue.use(jquery_contextmenu);
// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
Vue.use(VueMoment);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
