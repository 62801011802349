import axios from 'axios';
import { backEndHost } from './ServerTools';

export function queryRepos() {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/repo/page_query`;
    axios.get(path).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function createRepo(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/repo/create`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function updateRepo(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/repo/update`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function deleteRepo(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/repo/delete`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function queryRepoById(repoid) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/repo/querybyid?repoid=${repoid}`;
    axios.get(path).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function queryDocsByRepo(repoid) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/repo/query_docments?repoid=${repoid}`;
    axios.get(path).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function createChapter(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/chapter/create`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function updateChapter(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/chapter/update`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function deleteChapter(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/chapter/delete`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function createDocument(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/document/create`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function updateDocument(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/document/update`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function saveDocumentContent(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/document/save_content`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function deleteDocument(data) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/document/delete`;
    axios.post(path, data).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}

export function queryDocumentById(docid) {
  return new Promise((resolve, reject) => {
    const path = `${backEndHost()}/api/doc/document/querybyid?docid=${docid}`;
    axios.get(path).then((result) => {
      resolve(result.data);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  });
}
