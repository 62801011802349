<template>
  <div id="body">

    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            Pages
          </p>
          <!-- head buttons -->
          <div style="margin-left: auto;">
            <el-button size="mini" type="primary" round @click="on_showAddForm">+ Page</el-button>
          </div>
        </div>

        <!-- row -->
        <div class="mr-row">
          <div class="mr-chart-common">
            <el-table :data="pageRowsData" stripe style="width: 100%">
              <el-table-column
                  v-for="{ key, title} in columns"
                  :prop="key"
                  :label="title"
                  :key="key"/>

              <el-table-column label="Operation">
                <template slot-scope="scope">
                  <el-button @click="on_goToPage(scope.row._id)" type="text" size="small">
                    查看
                  </el-button>
                  <el-button @click="on_showUpdateForm(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click="on_submitDelete(scope.row._id)" type="text" size="small">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!-- form modal -->
    <el-dialog :title="formTitle" :visible.sync="formDialogVisible">
      <el-form :model="formData" ref="pageForm" :rules="formValidRules"
          label-position="left" label-width="80px" size="mini">
        <el-form-item label="Title" prop="title">
          <el-input v-model="formData.title" autocomplete="off" placeholder="enter page title">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="on_closeForm">取 消</el-button>
        <el-button type="primary" size="small" @click="on_submitAddOrUpdate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  remote_createPage, remote_pageQueryPages, remote_updatePage,
  remote_deletePage,
} from '@/script/PageAPIs.js';
import { timestampToFormatDateTime } from '@/script/DateTools';

export default {
  name: 'Pages',
  data() {
    return {
      pageRowsData: [],
      columns: [
        { key: 'title', title: 'Title' },
        { key: 'creator', title: 'Creator' },
        { key: 'gmt_create', title: 'Create Time' },
      ],
      formDialogVisible: false,
      formTitle: 'Add New Page',
      formData: {
        _id: '',
        title: '',
      },
      formValidRules: {
        name: [
          { required: true, message: 'Name cannot be empty', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    on_showAddForm() {
      this.formTitle = 'Create New Page';
      this.formData._id = '';
      this.formDialogVisible = true;
    },
    on_showUpdateForm(row) {
      this.formTitle = 'Update Page';
      this.formData = row;
      this.formDialogVisible = true;
    },
    on_closeForm() {
      this.formDialogVisible = false;
      this.$refs.pageForm.resetFields();
      this.formData._id = '';
    },
    on_goToPage(pageId) {
      this.$router.push(`/page/${pageId}`);
    },
    on_submitAddOrUpdate() {
      this.$refs.pageForm.validate((valid) => {
        if (valid) {
          if (this.formData._id) {
            remote_updatePage(this.formData).then((result) => {
              this.on_closeForm();
              this.$message({ message: result.message, type: 'success' });
              this.logic_queryPages();
            });
          } else {
            remote_createPage(this.formData.title).then((result) => {
              this.on_closeForm();
              this.$message({ message: result.message, type: 'success' });
              this.logic_queryPages();
            });
          }
        }
      });
    },
    on_submitDelete(page_id) {
      if (window.confirm('Are you sure to delete?')) {
        remote_deletePage(page_id).then((data) => {
          this.$message({ message: data.message, type: 'success' });
          this.logic_queryPages();
        });
      }
    },
    logic_queryPages() {
      remote_pageQueryPages().then((result) => {
        const rowData = [];
        result.data.items.forEach((it) => {
          const rowItem = { ...it };
          rowItem.gmt_create = timestampToFormatDateTime(rowItem.gmt_create);
          rowData.push(rowItem);
        });
        this.pageRowsData = rowData;
      });
    },
  },
  mounted() {
    this.logic_queryPages();
  }
};
</script>

<style sc
MrMenuoped>

</style>
