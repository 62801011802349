<template>
  <div id="body">
    <!-- 菜单 -->
    <mr-menu/>

    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            Forms
          </p>
          <!-- head buttons -->
          <div style="margin-left: auto;">
            <el-button size="mini" type="primary" round @click="showAddForm">+ Form</el-button>
          </div>
        </div>

        <!-- row -->
        <div class="mr-row">
          <div class="mr-chart-common">
            <b-table
                :fields="columns"
                :items="formRowData"
                >
              <template #cell(name)="data">
                <b-link :href='`/form/${data.item._id}`'>{{ data.value }}</b-link>
              </template>

              <template #cell(action)="data">
                <b-button variant="link" size="sm" @click="showUpdateForm(data.item)">编辑</b-button>
                <span style="padding: 0px 4px 0px 0px;"></span>
                <b-button variant="link" size="sm" @click="submitDelete(data.item._id)">删除</b-button>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Stream form modal -->
    <el-dialog :title="formTitle" :visible.sync="formDialogVisible">
      <el-form :model="formData" ref="createForm" :rules="formValidRules"
          label-position="left" label-width="80px" size="mini">
        <el-form-item label="Name" prop="name">
          <el-input v-model="formData.name" autocomplete="off" placeholder="enter stream name">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeForm">取 消</el-button>
        <el-button type="primary" size="small" @click="submitAddOrUpdate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MrMenu from '../../components/MrMenu.vue';
import FormAPIs from '@/script/FormAPIs';
import { timestampToFormatDateTime } from '@/script/DateTools';

export default {
  name: 'Forms',
  components: { MrMenu, },
  data() {
    return {
      formDialogVisible: false,
      formRowData: [],
      columns: [
        { key: 'name', label: '名称' },
        { key: 'creator', label: '创建者' },
        { key: 'gmt_create', label: '创建时间' },
        { key: 'action', label: '操作' },
      ],
      formTitle: '',
      formData: {
        _id: '',
        name: '',
      },
      formValidRules: {
        name: [
          { required: true, message: 'Name cannot be empty', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    queryForms() {
      FormAPIs.queryByPage().then((data) => {
        const rows = [];
        data.data.items.forEach((it) => {
          const newItem = { ...it };
          newItem.gmt_create = timestampToFormatDateTime(newItem.gmt_create);
          rows.push(newItem);
        });
        this.formRowData = rows;
      });
    },
    showAddForm() {
      this.formTitle = 'Create New Form';
      this.formData._id = '';
      this.formDialogVisible = true;
    },
    showUpdateForm(row) {
      this.formTitle = 'Update Form';
      this.formData = row;
      this.formDialogVisible = true;
    },
    closeForm() {
      this.formDialogVisible = false;
      this.$refs.createForm.resetFields();
      this.formData._id = '';
    },
    submitDelete(formId) {
      if (window.confirm('Are you sure to delete?')) {
        FormAPIs.deleteForm(formId).then((data) => {
          this.$message({ message: data.message, type: 'success' });
          this.queryForms();
        });
      }
    },
    submitAddOrUpdate() {
      this.$refs.createForm.validate((valid) => {
        if (valid) {
          if (this.formData._id) {
            FormAPIs.updateName(this.formData._id, this.formData.name).then((data) => {
              this.closeForm();
              this.$message({ message: data.message, type: 'success' });
              this.queryForms();
            });
          }
          else {
            FormAPIs.create(this.formData.name).then((data) => {
              this.closeForm();
              this.$message({ message: data.message, type: 'success' });
              this.queryForms();
            });
          }
        }
      });
    },
    gotoFormDetail(formId) {
      this.$router.push(`/form/${formId}`);
    },
  },
  mounted() {
    this.queryForms();
  },
};
</script>