<template>
  <div>
    <a-modal
      :title="title"
      :visible="true"
      :footer="null"
      @cancel="handleCancel"
    >
      <dynamic-form
        :schema="schema" :api="api"
        :editMode="false"
        :renderParams="renderParams"
        @cancel="handleCancel"
        @sucess="handleSuccess"
      >
      </dynamic-form>
    </a-modal>
  </div>
</template>

<script>
import DynamicForm from './DynamicForm.vue';

export default {
  name: 'DynamicFormModal',
  props: [ 'title', 'schema', 'api', 'onCancel', 'onSubmitSuccess', 'renderParams', ],
  components: { DynamicForm },
  data() {
    return {
    };
  },
  methods: {
    handleCancel() {
      console.log('in DynamicFormModal handleCancel');
      if (this.onCancel) {
        console.log('2222');
        this.onCancel();
      }
    },
    handleSuccess() {
      if (this.onSubmitSuccess) {
        this.onSubmitSuccess();
      }
    },
  },
};
</script>