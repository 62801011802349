<template>
  <div id="body">
    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; margin: 8px 0px; color: #515A6E">
            Research
          </p>
        </div>

        <!-- container layout start -->
        <div class="container-layout">

          <!-- 头部 start -->
          <div style="width: 100%; margin: 0px 4px 0px 4px;" class="flxrow">
            <!-- 方法论 -->
            <div style="width: 300px;" class="chart commargin re-head">
              <div>这个游戏里最重要的是<span style="color: red">活得久</span></div>
              <div><strong>凯利公式</strong></div>
              <Latext>f=\dfrac{p\left( b+1\right) -q}{b}</Latext>
              <div>p: 胜率  => 0.38</div>
              <div>b: 盈利率  => 0.5</div>
              <div>q: 失败损失率  => <span class="t-red">0.4(止损)</span></div>
              <Latext>f=\dfrac{0.38\left( 0.5+1\right) -0.4}{0.5}=0.34</Latext>
            </div>
            <div style="width: 30%;" class="chart commargin re-head">
              <div>
                <span style="font-weight: 800;">市场并不总是有效的，市场大部分时间都是在无序波动</span>
                <p style="color: blue; margin-left: 16px;">不要一直交易，等待机会，</p>
                <p style="margin-left: 16px;"> 比如2023.04.14通胀预期大幅超出预期，黄金暴跌 </p>
              </div>
              <div>
                <strong>市场往往在犹豫中(事实模糊中)走出趋势</strong>
                <p style="margin-left: 16px;">BUY ON RUMOR, SELL ON FACT</p>
                <p style="margin-left: 16px;"> 2022美股-Fed放鸽，A股-reopen，都是这样; </p>
              </div>
              <div><strong>关注边际变化</strong></div>
            </div>

            <div style="width: 23%;" class="chart commargin re-head">
              <ul>
                <li>股票主要看EPS和PE；</li>
                <li>外汇看技术分析 + 结售汇（增长、无风险利率）；</li>
                <li>黄金看实际利率；</li>
                <li>铜 -> GDP增长；</li>
                <li>票据融资是银行空转，越低越好；</li>
                <li>信用风险看信用利差；</li>
                <li>生猪看能繁母猪存栏；</li>
              </ul>
            </div>

            <div style="width: 450px;">
              <div class="chart commargin re-head">
                <p><strong>Strategy</strong></p>
                <p> 技术策略：重要技术点位做反弹，突破后止损做突破，再次反弹后止损不再交易；止损20%，盈利40%后分批获益；不隔夜；</p>
                <p> 宏观：依据宏观事件进行交易，偏主观 </p>
              </div>
              <div class="chart commargin re-head">
                <p><strong>Patience Waiting</strong></p>
                <p> 衰退风险事件 </p>
                <p> 最后一次加息之后（5月？）SELL </p>
                <p> 降息，做多科技，MSFT</p>
                <p> QE，买BTC </p>
              </div>
            </div>
          </div>
          <!-- 头部 end -->

          <!-- 叙事、策略 start -->
          <div style="width: 100%; margin: 0px 4px;" class="flxrow">
            <div style="width: 100%" class="yscroll flxrow">
              <JournalSummary class="commargin" v-for="narr in narriatives" :key="narr.id"
                style="width: 300px; min-width: 300px;"
                :id="narr.id"
                :series="narr.series"
                :images="narr.images"
                :summary="narr.summary"
                :tags="narr.tags"
                :date="narr.occur"
                :type="narr.type"
                @view="onViewJournal"
              />
            </div>
          </div>
          <!-- 叙事、策略 end -->

          <div style="width: 100%; margin: 2px 4px;" class="flxcolumn">
            <div class="flxrow">
              <div style="width: 20%;" class="commargin" >
                <div class="jour-category" style="margin-left: 4px;">
                  <div class="tag tag-red"> Events </div>
                </div>
              </div>
              <div style="width: 20%;" class="commargin" >
                <div class="jour-category" style="margin-left: 4px;">
                  <div class="tag tag-blue"> Data </div>
                </div>
              </div>
              <div style="width: 20%;" class="commargin" >
                <div class="jour-category" style="margin-left: 4px;">
                  <div class="tag tag-green"> Insts</div>
                </div>
              </div>
              <div style="width: 20%;" class="commargin" >
                <div class="jour-category" style="margin-left: 4px;">
                  <div class="tag tag-orange"> Medias</div>
                  </div>
              </div>
              <div style="width: 20%;" class="commargin" >
                <div class="jour-category" style="margin-left: 4px;">
                  <div class="tag tag-yellow"> Others </div>
                </div>
              </div>
              <!--
              <div style="width: 20%;" class="commargin" >
                <div class="jour-category" style="margin-left: 4px;">Narrative</div>
              </div>
              -->
            </div>
          </div>

          <!-- row start -->
          <div v-for="jday in journalDays" :key="jday.date" style="width: 100%; margin: 2px 4px;" class="flxcolumn">
            <!-- 日期 -->
            <div class="flxrow">
              <p style="margin-left: 4px;">
                {{jday.dateObj.getMonth() + 1}}月{{jday.dateObj.getDate()}}日 {{jday.weekDay}}
              </p>
              <div class="flxalignright">
                <button class="btn btn-p1 bg-gradient-dark"
                    @click="onShowJournalCreateForm(jday.dateObj)" > + </button>
              </div>
            </div>
            <div class="flxrow">
              <div style="width: 20%;" class="commargin" >
                <div class="flxcolumn">
                  <JournalSummary v-for="jour in jday.Event" :key="jour.id"
                    class="margintb"
                    :id="jour.id"
                    :series="jour.series"
                    :images="jour.images"
                    :summary="jour.summary"
                    :tags="jour.tags"
                    @view="onViewJournal"
                  />
                </div>
              </div>
              <div style="width: 20%;" class="commargin" >
                <div class="flxcolumn">
                  <JournalSummary v-for="jour in jday.Data" :key="jour.id"
                    class="margintb"
                    :id="jour.id"
                    :series="jour.series"
                    :images="jour.images"
                    :summary="jour.summary"
                    :tags="jour.tags"
                    @view="onViewJournal"
                  />
                </div>
              </div>
              <div style="width: 20%;" class="commargin" >
                <div class="flxcolumn">
                  <JournalSummary v-for="jour in jday.Inst" :key="jour.id"
                    class="margintb"
                    :id="jour.id"
                    :series="jour.series"
                    :images="jour.images"
                    :summary="jour.summary"
                    :tags="jour.tags"
                    @view="onViewJournal"
                  />
                </div>
              </div>
              <div style="width: 20%;" class="commargin" >
                <div class="flxcolumn">
                  <JournalSummary v-for="jour in jday.Media" :key="jour.id"
                    class="margintb"
                    :id="jour.id"
                    :series="jour.series"
                    :images="jour.images"
                    :summary="jour.summary"
                    :tags="jour.tags"
                    @view="onViewJournal"
                  />
                </div>
              </div>
              <div style="width: 20%;" class="commargin" >
                <div class="flxcolumn">
                  <JournalSummary v-for="jour in jday.Other" :key="jour.id"
                    class="margintb"
                    :id="jour.id"
                    :series="jour.series"
                    :images="jour.images"
                    :summary="jour.summary"
                    :tags="jour.tags"
                    @view="onViewJournal"
                  />
                </div>
              </div>
              <!--
              <div style="width: 20%;" class="commargin" >
                <div class="flxcolumn">
                  <JournalSummary v-for="jour in jday.Narrative" :key="jour.id"
                    class="margintb"
                    :id="jour.id"
                    :series="jour.series"
                    :images="jour.images"
                    :summary="jour.summary"
                    :tags="jour.tags"
                    @view="onViewJournal"
                  />
                </div>
              </div>
              -->
            </div>
          </div>
          <!-- row end -->

          <div style="width: 100%; margin: 0px 8px 4px 8px;
              padding: 4px;
              background-color: #b5cdf7;
              align-items: center;
              box-shadow: 0 0px 27px 0 rgb(0 0 0 / 5%);
              border-radius: .3rem;
	            display: flex;
	            flex-direction: column;"
              @click="loadJournalsByRoll"
              >
            <SyncOutlined :spin="loadingJournals" />
          </div>


          <!-- 旧版journal 内容 start
          <div style="width: 100%; margin: 0px 4px 8px 4px;" class="flxrow">

            <div class="flx1">
              <div v-for="dateData in journalData" :key="dateData.date" style="width: 100%" >
                <div v-if="dateData.journals && dateData.journals.length > 0" class="compadding3" >
                  <div class="flxrow">
                    <strong>
                      {{dateData.dateObj.getMonth() + 1}}月{{dateData.dateObj.getDate()}}日 {{dateData.weekDay}}
                    </strong>
                  </div>

                  <div class="jour-date">
                    <div style="flex: 1">
                      <JournalWrapper
                          :dateObj="dateData.dateObj"
                          :weekDay="dateData.weekDay"
                          :journals="dateData.journals"
                          @updateSuccess="onUpdateSuccess"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style="width: 30%; min-width: 300px;">
              <div style="width: 100%" class="compadding3" >
                <JournalJudgement v-for="(it, index) in judgements" :key="it._id"
                        style="margin-bottom: 4px; width: 100%;"
                        :journal="it"
                        :index="index"
                        @updateSuccess="onUpdateSuccess"
                        @refreshJudgements="loadJudges"
                />
              </div>
            </div>
          </div>
          -->

        </div>
        <!-- container layout end -->

      </div>
    </div>

    <a-modal
        :visible="modalVisible" @ok="onModalOk"
        :closable="false"
        :maskClosable="false"
        :footer="null"
        wrapClassName="full-modal"
        width='auto' >
      <Journal
        :mode="mode"
        :id="modalId"
        :createSignal="createSignal"
        @cancel="onModalCancel"
        @close="onClose"
        />
    </a-modal>
  </div>
</template>

<script>
import JournalSummary from './JournalSummary.vue';
import Journal from './Journal.vue';
import { doPost, doGetN, doPostN, } from '@/script/ServerTools';
import { timestampToFormatDate, formatDate, formatDatetime, } from '@/script/DateTools';
import { SyncOutlined } from '@ant-design/icons-vue';
import Latext from '../../components/Latext.vue';

export default {
  name: 'Research',
  components: { JournalSummary, Journal, SyncOutlined, Latext },
  data() {
    return {
      journalData: [ ],
      drawerOnCreating: false,
      createJournalSignal: 0,
      createNewDate: null,
      dateMap: { },
      judgements: [ ],
      // -----------------------------
      weekDays: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
      journalsDateMap: { },
      journalDays: [ ],
      startDate: null,
      endDate: null,
      loadingJournals: false,
      // -----------------------------
      latestNarriative: null,
      narriatives: [ ],
      // -----------------------------
      modalVisible: false,
      modalId: 0,
      mode: null,
      createSignal: 0,
    };
  },
  methods: {
    onShowJournalCreateForm(dateObj) {
      this.mode = 'creating';
      this.createSignal += 1;
      this.modalVisible = true;
    },
    onModalOk() {
      this.modalVisible = false;
    },
    onModalCancel() {
      this.modalVisible = false;
    },
    onViewJournal(id) {
      this.mode = 'view';
      this.modalId = id;
      this.modalVisible = true;
    },
    onClose(date) {
      if (date !== null) {
        this.loadJournalsByDay(date);
      }
      this.modalVisible = false;
    },
    loadConfigs() {
      if (window.journalTypes === undefined || window.journalTypes === null) {
        doGetN('/api/morpho/config/query', { code: 'JOURNAL_TYPES' }).then((rsp) => {
          window.journalTypes = rsp.result;
        });
      }
      if (window.journalTags === undefined || window.journalTags === null) {
        doGetN('/api/morpho/config/query', { code: 'JOURNAL_TAGS' }).then((rsp) => {
          window.journalTags = rsp.result;
        });
      }
      if (window.journalSeries === undefined || window.journalSeries === null) {
        doGetN('/api/morpho/config/query', { code: 'JOURNAL_SERIES' }).then((rsp) => {
          window.journalSeries = rsp.result;
        });
      }
    },
    getLatestNarrative(it) {
      if (it.type === 'Narrative') {
        // if (this.latestNarriative === null) {
        //   this.latestNarriative = it;
        // } else if (this.latestNarriative.occur.localeCompare(it.occur) < 0) {
        //   this.latestNarriative = it;
        // }
        // // 更新
        // else if (it.id === this.latestNarriative.id) {
        //   this.latestNarriative = it;
        // }
        let found = false;
        let pos = 0;
        this.narriatives.forEach((narr, i) => {
          if (narr.id === it.id) {
            found = true;
            pos = i;
            return false;
          }
          return true;
        });
        if (found) {
          this.narriatives[pos] = it;
        } else {
          this.narriatives.push(it);
          this.narriatives.sort((a, b) => -a.occur.localeCompare(b.occur));
        }
      }
    },
    loadJournalsByDay(date) {
      doPostN('/api/morpho/journal/query_by_time', { endTime: date, startTime: date }).then((rsp) => {
        const dateStr = date.substring(0, 10);
        if (rsp.result.length === 0) {
          this.journalDays.forEach((it, index) => {
            if (it.date === dateStr) {
              this.journalDays.splice(index, 1);
              return false;
            }
            return true;
          });
          return;
        }

        const dateObj = new Date(rsp.result[0].occur);
        const data = {
          date: dateStr,
          dateObj,
          weekDay: this.weekDays[dateObj.getDay()],
          Event: [ ],
          Data: [ ],
          Inst: [ ],
          Media: [ ],
          Other: [ ],
        };
        rsp.result.forEach((it) => {
          if (it.type === 'Data' || it.type === 'Event' || it.type === 'Inst' || it.type === 'Media') {
            data[it.type].push(it);
          } else if (it.type === 'Narrative') {
            this.getLatestNarrative(it);
          } else {
            data.Other.push(it);
          }
        });
        let found = false;
        let index = 0;
        this.journalDays.forEach((it) => {
          if (it.date === dateStr) {
            it.Event = data.Event;
            it.Data = data.Data;
            it.Inst = data.Inst;
            it.Media = data.Media;
            it.Other = data.Other;
            found = true;
            return false;
          }
          if (it.date.localeCompare(dateStr) < 0) {
            return false;
          }
          index += 1;
          return true;
        });
        if (!found) {
          this.journalDays.splice(index, 0, data);
        }
      });
    },
    loadJournalsByRoll() {
      if (this.loadingJournals) {
        return;
      }
      if (this.startDate === null) {
        this.startDate = Date.now();
        this.endDate = this.startDate - 3600000 * 24 * 6;
      } else {
        this.startDate = this.startDate - 3600000 * 24 * 7;
        this.endDate = this.startDate - 3600000 * 24 * 6;
      }
      this.loadingJournals = true;
      doPostN('/api/morpho/journal/query_by_time', {
        endTime: formatDatetime(new Date(this.startDate)),
        startTime: formatDatetime(new Date(this.endDate)),
      }).then((rsp) => {
        this.loadingJournals = false;
        const newDates = [ ];
        const journalsDateMap = { };
        rsp.result.forEach((it) => {
          this.getLatestNarrative(it);
          const dateStr = it.occur.substring(0, 10);
          if (!(dateStr in journalsDateMap)) {
            newDates.push(dateStr);
            const dateObj = new Date(it.occur);
            journalsDateMap[dateStr] = {
              date: dateStr,
              dateObj,
              weekDay: this.weekDays[dateObj.getDay()],
              Event: [ ],
              Data: [ ],
              Inst: [ ],
              Media: [ ],
              Other: [ ],
            };
          }
          if (it.type === 'Data' || it.type === 'Event' || it.type === 'Inst' || it.type === 'Media') {
            journalsDateMap[dateStr][it.type].push(it);
          } else if (it.type !== 'Narrative') {
            journalsDateMap[dateStr]['Other'].push(it);
          }
        });
        newDates.sort().reverse().forEach((dateStr) => {
          this.journalDays.push(journalsDateMap[dateStr]);
        });
      });
    },
    // ===========================================
    onUpdateSuccess(dateStr) {
      this.loadByDay(dateStr);
    },
    initLoad() {
      let date = new Date();
      for (let i=0; i<60; ++i) {
        const dateStr = formatDate(date);
        const temp = {
          date: dateStr,
          dateObj: new Date(date.getTime()),
          weekDay: this.weekDays[date.getDay()],
          journals: [ ],
        };
        this.journalData.push(temp);
        this.dateMap[dateStr] = temp;
        // 加载数据
        date.setHours(0, 0, 0);
        this.loadByDay(dateStr);
        // 后退一天
        date.setDate(date.getDate() - 1);
      }
    },
    loadByDay(dateStr) {
      const date = new Date(dateStr);
      date.setHours(0, 0, 0);
      doPost('/api/journal/query', {
        startTime: date.getTime() / 1000,
        endTime: date.getTime() / 1000 + 24 * 3600 - 1
      }).then((rsp) => {
        this.dateMap[dateStr].journals.length = 0;
        // 延时更新数据
        setTimeout(() => {
          rsp.data.forEach((it) => {
            if (it.journalType !== 'Judgement') {
              this.dateMap[dateStr].journals.push(it);
            }
          });
        }, 100);
      });
    },
    loadJudges() {
      doPost('/api/journal/queryJudgements', { }).then((rsp) => {
        this.judgements = [ ];
        rsp.data.forEach((it) => {
          this.judgements.push(it);
        });
      });
    },
    showJourCreateForm() { },
  },
  mounted() {
    this.loadConfigs();
    this.loadJournalsByRoll();
  },
};
</script>

<style lang="less">
.re-head {
  font-size: 14px;
  margin-bottom: 6px;
}
.jour-date {
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: row;
}
.indicator-a {
  color: #1a47fc;
  padding: 0px 4px;
  cursor: pointer;
}
.full-modal {
  .ant-modal {
    height: 100%;
    width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: 96%;
    width: 1200px;
    max-width: 90%;
    margin-top: calc(2vh);
    margin-left: calc(50vw - 600px);
  }
  .ant-modal-body {
    flex: 1;
    padding: 0px;
    height: 100%;
  }
}
.jour-container {
  flex: 1;
  flex-wrap: wrap;
}
.jour-space {
  padding-right: 6px;
  padding-bottom: 6px;
}
.journal {
  flex: 1;
  max-width: 360px;
  min-width: 200;
  height: 100%;
}
.jour-title {
  margin-bottom: 6px;
  cursor: pointer;
}
.journal img {
  width: 100%;
  max-height: 200px;
  display: block;
  border-radius: 5px;
}
.jour-summary {
  font-size: 14px;
  margin-bottom: 8px;
}
.jour-category {
  font-size: 14px;
  color: #0047FC;
  font-weight: 600;
}
.jour-bottom {
  margin-top: 6px;
}
.jour-tag {
  background-color: #F1F1F1;
  font-size: 12px;
  color: #F46904;
  margin-right: 6px;
  border-radius: 8px;
  padding: 0px 8px;
}
.jour-bull {
  background-color: #64D031;
  height: 100%;
}
.jour-bear {
  background-color: #F26464;
  height: 100%;
}
.jour-rate-bg {
  width: 120px;
  height: 12px;
  background-color: #DEDEDE;
  margin-top: 4px;
}
.jour-img {
  width: 100%;
  max-height: 200px;
  display: block;
  border-radius: 5px;
  margin-bottom: 8px;
}
.jour-summary {
  margin-bottom: 6px;
  cursor: pointer;
}
.img-container {
  height: 100px;
  width: 100%;
  overflow-x: scroll;
}
.img-container:empty::before {
  border: 1px solid #ddd;
  content: 'Paste Image Here';
  display: block;
  text-align: center;
  font-size: 30px;
  line-height: 100px;
  opacity: .6;
  width: 100%;
}
.img-wrapper {
  height: 100%;
  margin-right: 6px;
  position: relative;
}
.img-wrapper img {
  height: 100%;
  display: block;
  border-radius: 5px;
}
.img-x {
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  border-radius: 3px;
}
</style>
