<template>
  <div class="app-basic" style="height: 100%">
    <!-- header start -->
    <div class="flxcolumn">
      <div class="bt-sd header-wrapper">

        <!-- title & button -->
        <div class="flxrow vtaligncenter item-wrapper">
          <div class="page-title" >
            {{ name }}
          </div>
          <div style="margin-left: auto;">
            <button class="btn btn-p1 bg-gradient-dark" @click="toInsertValue" >插入新值</button>
            <a-popconfirm
              title="Are you sure delete all values ?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="toDeleteAll"
              @cancel="() => {}"
            >
              <button class="btn btn-p1 bg-gradient-danger marginl2" >删除所有值</button>
            </a-popconfirm>
          </div>
        </div>
      </div>

      <div class="chart commargin">
        <el-table :data="rows" stripe class="width100">
          <el-table-column
              v-for="{ key, title} in columns"
              :prop="key"
              :label="title"
              :key="key"/>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <button class="btn btn-p1 bg-gradient-dark margin-r"
                @click="showValueDrawer(scope.row)" > 编辑 </button>
              <a-popconfirm
                title="Are you sure delete this value ?"
                ok-text="Yes"
                cancel-text="No"
                @confirm="delValue(scope.row)"
                @cancel="() => {}"
              >
                <button class="btn btn-p1 bg-gradient-danger" > 删除 </button>
              </a-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="width100 flxrow">
          <el-pagination background layout="prev, pager, next"
            style="margin-top: 8px; margin-left: auto"
            :page-size="pageSize"
            :current-page="pageNo"
            :total="total"
            @current-change="onChangePage"
            >
          </el-pagination>
        </div>
      </div>
    </div>

    <a-modal
      :visible="modalVisible" :title="modalTitle" :closable="true" @ok="handleOk" @cancel="handleCancel">
      <div class="flxrow">
      <a-date-picker
        v-model="modalDatetime"
        show-time
        style="width: 200px; margin-right: 8px;"
      />
      <button class="btn btn-p1 bg-gradient-info"
        style="margin-right: 8px;"
        @click="setZeroClock" > 0点 </button>
      <a-input-number v-model="modalValue" :step="0.01"
        style="width: 200px; margin-right: 8px;"
      />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getValuesByPage, saveValue, deleteValue, deleteAllValues, } from '@/script/IndicatorAPIs';
import { timestampToFormatDateTime, } from '@/script/DateTools';
import moment from 'moment';

export default {
  name: 'IndicatorValueMng',
  props: [ 'name', 'code', ],
  data() {
    return {
      total: 0,
      pageSize: 10,
      pageNo: 1,
      columns: [ {
        title: 'Date',
        key: 'date',
        dataIndex: 'date',
      }, {
        title: 'Value',
        key: 'value',
        dataIndex: 'value',
      } ],
      rows: [ ],
      modalVisible: false,
      modalTitle: '',

      modalId: null,
      modalDatetime: null,
      modalValue: 0.0,
    };
  },
  watch: {
    code(oldVal, newVal) {
      this.queryValues();
    },
  },
  methods: {
    toInsertValue() {
      this.modalTitle = '插入指标值';
      this.modalId = null;
      if (this.modalDatetime === null) {
        this.modalDatetime = moment();
      }
      this.modalValue = 0.0;
      this.modalVisible = true;
    },
    handleCancel() {
      this.modalVisible = false;
    },
    handleOk() {
      const data = {
        indicatorCode: this.code,
        timestamp: this.modalDatetime.unix(),
        value: this.modalValue,
      };
      if (this.modalId !== null) {
        data.id = this.modalId;
      }
      saveValue(data).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
        this.modalVisible = false;
        this.queryValues();
      });
    },
    showValueDrawer(data) {
      this.modalId = data.id;
      this.modalDatetime = moment(data.timestamp * 1000);
      this.modalValue = data.value;
      this.modalVisible = true;
    },
    setZeroClock() {
      this.modalDatetime = moment(parseInt(this.modalDatetime.unix() / 86400) * 86400000);
    },
    queryValues() {
      const rows = [ ];
      getValuesByPage(this.code, this.pageSize, this.pageNo).then((rsp) => {
        this.pageNo = rsp.result.pageNo;
        this.pageSize = rsp.result.pageSize;
        this.total = rsp.result.total;
        rsp.result.list.forEach((it) => {
          it.key = it.id;
          it.date = timestampToFormatDateTime(it.timestamp);
          rows.push(it);
        });
        this.rows = rows;
      });
    },
    onChangePage(pageNo) {
      this.pageNo = pageNo;
      this.queryValues();
    },
    delValue(data) {
      deleteValue({
        indicatorCode: data.indicatorCode,
        timestamp: data.timestamp,
      }).then((rsp) => {
        if (rsp.errorCode === 'SUCCESS') {
          this.$message({ message: rsp.message, type: 'success' });
          this.queryValues();
        } else {
          this.$message({ message: rsp.message, type: 'error' });
        }
      });
    },
    toDeleteAll() {
      const formData = new FormData();
      formData.append('indicatorCode', this.code);
      deleteAllValues(formData).then((rsp) => {
        if (rsp.errorCode === 'SUCCESS') {
          this.$message({ message: rsp.message, type: 'success' });
          this.queryValues();
        } else {
          this.$message({ message: rsp.message, type: 'error' });
        }
      });
    },
  },
  mounted() {
    this.queryValues();
  },
};
</script>
