<template>
  <div>
    <div :id="id" class="height100p"
      @mousedown="onStartDrag"
      @mousemove="onMove"
      @mouseup="onEndDrag"
      @mouseleave="onEndDrag"
      ></div>
  </div>
</template>

<script>
import { uuid4, } from '@/script/CommonTools';
import HighCharts from 'highcharts';
import highcharts3d from 'highcharts/highcharts-3d';

export default {
  name: 'Common3DChart',
  props: [ 'data', ],
  data() {
    return {
      id: uuid4(),
      chartId: null,
      chartOptions: null,
      colors: [
        { lineColor: 'rgb(180,90,50)', color: 'rgb(200,110,50)', fillColor: 'rgb(200,110,50)', },
        { lineColor: 'rgb(120,160,180)', color: 'rgb(140,180,200)', fillColor: 'rgb(140,180,200)', },
        { lineColor: 'rgb(200, 190, 140)', color: 'rgb(200, 190, 140)', fillColor: 'rgb(230, 220, 180)', },
      ],
      render: false,
      chart: null,
      alpha: 10,
      beta: 30,
      sensitivity: 50,
      draging: false,
    };
  },
  watch: {
    data(val, oldVal) {
      if (val !== null && val !== undefined) {
        this.initOptions();
        highcharts3d(HighCharts);
        this.chart = HighCharts.chart(this.id, this.chartOptions);
      }
    },
  },
  methods: {
    initOptions() {
      const xAxis = [ ];
      const series = [ ];
      let index = 0;
      this.data.forEach((it) => {
        xAxis.push({
          type: 'datetime',
          visible: false,
        });
        series.push({
          name: it.name,
          data: it.data,
          xAxis: index,
          // ...this.colors[index]
        });
        index = index + 1;
      } );

      this.chartOptions = {
        chart: {
          type: 'area',
          renderTo: this.chartId,
          margin: 100,
          // options3d: {
          //   enabled: true,
          //   depth: 250,
          //   alpha: this.alpha,
          //   beta: this.beta,
          //   viewDistance: 10,
          //   fitToPlot: false,
          //   // frame: {
          //   //   bottom: { size: 1, color: 'rgba(0,0,0,0.02)' },
          //   //   back: { size: 1, color: 'rgba(0,0,0,0.04)' },
          //   //   side: { size: 1, color: 'rgba(0,0,0,0.06)' }
          //   // },
          // },
        },
        title: { text: 'Visual comparison of Mountains Panorama' },
        yAxis: {
          title: null,
          gridLineDashStyle: 'Dash',
          enabled: false,
        },
        xAxis: xAxis,
        plotOptions: {
          area: {
            depth: 10,
            marker: {
              enabled: false
            },
            states: {
              inactive: { enabled: false }
            },
            fillOpacity: 0.4,
          }
        },
        series,
      };
    },
    onEndDrag() {
      this.draging = false;
    },
    onStartDrag(e) {
      const event = this.chart.pointer.normalize(e);
      this.posX = event.pageX;
      this.posY = event.pageY;
      this.draging = true;
    },
    onMove(e) {
      if (!this.draging) {
        return;
      }
      console.log(this.draging);
      // Run beta
      this.beta = this.beta + (this.posX - e.pageX) / this.sensitivity;
      // newBeta = Math.min(100, Math.max(-100, newBeta));
      // if (this.beta < -30) {
      //   this.beta = -30;
      // }
      // if (this.beta > 100) {
      //   this.beta = 100;
      // }
      this.chart.options.chart.options3d.beta = this.beta;
      // Run alpha
      this.alpha = this.alpha + (e.pageY - this.posY) / this.sensitivity;
      // newAlpha = Math.min(100, Math.max(-100, newAlpha));
      if (this.alpha < -30) {
        this.alpha = -30;
      }
      if (this.alpha > 100) {
        this.alpha = 100;
      }
      this.chart.options.chart.options3d.alpha = this.alpha;
      this.chart.redraw(false);
    },
  },
  mounted() {
    this.chartId = uuid4();
  },
  created() { },
};
</script>
