<template>
  <div>
    <button type="button">计算</button>
    <ul>
      <div v-for="item in stockList" :key="item.code">
        <li><a :href="'stock?code=' + item.code" target="_blank">{{ item.name }}</a></li>
      </div>
    </ul>
  </div>
</template>

<script>
// import CandleStick from '@/components/CandleStick.vue';

export default {
  name: 'BoxBreak',
  components: {
    // CandleStick,
  },
  data() {
    return {
      stockList: [
        { code: '000037.SZ', name: '测试' },
      ],
    };
  },
};
</script>

<style>

</style>
