<template>
  <div>
    <div :id="chartId" class="HighStock flx1" style="height: 370px"></div>
  </div>
</template>

<script>
import Highcharts from 'highcharts/highstock';
import { uuid4, } from '@/script/CommonTools';
import { doPost, doGet } from '@/script/ServerTools';
export default {
  name: 'SomaSummary',
  data() {
    return {
      chartId: null,
      chartOptions: { },
      loadFinish: false,
    };
  },
  methods: {
    loadSummary() {
      doGet('/api/macroIndicators/query', { name: 'SomaSummary' }).then((rsp) => {
        const bills = [ ];
        const notesbonds = [ ];
        const frn = [ ];
        const tips = [ ];
        const agencies = [ ];
        const mbs = [ ];
        const cmbs = [ ];
        const total = [ ];
        const delta = [ ];
        let last = null;
        rsp.data.data.forEach((it) => {
          bills.push([it.asOfDate, it.bills]);
          notesbonds.push([it.asOfDate, it.notesbonds]);
          frn.push([it.asOfDate, it.frn]);
          tips.push([it.asOfDate, it.tips]);
          agencies.push([it.asOfDate, it.agencies]);
          mbs.push([it.asOfDate, it.mbs]);
          cmbs.push([it.asOfDate, it.cmbs]);
          total.push([it.asOfDate, it.total]);
          if (last !== null) {
            delta.push([it.asOfDate, it.total - last]);
          }
          last = it.total;
        });

        const opts = {
          title: { text: null },
          time: {
            useUTC: false,
          },
          chart: {
            type: 'line',
          },
          xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
              second: '%Y-%m-%d<br/>%H:%M:%S',
              minute: '%Y-%m-%d<br/>%H:%M',
              hour: '%Y-%m-%d<br/>%H:%M',
              day: '%Y/%m/%d',
              week: '%Y/%m/%d',
              month: '%Y-%m',
              year: '%Y',
            },
          },
          yAxis: [ {
            title: null,
            opposite: true,
          }, {
            title: null,
            opposite: false,
          } ],
          legend: {
            enabled: true,
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
          },
          plotOptions: {
            series: {
              states: {
                inactive: {
                  opacity: 1,
                }
              }
            },
            // column: { grouping: false, },
            column: { dataGrouping: { enabled: false, }, },
            line: { dataGrouping: { enabled: false, }, },
            dataGrouping: { enabled: false, },
          },
          navigator: {
            enabled: true,
            height: 20,
          },
          rangeSelector: {
            buttons: [{
              type: 'month',
              count: 6,
              text: '6m',
            }, {
              type: 'year',
              count: 1,
              text: '1y',
            }, {
              type: 'year',
              count: 2,
              text: '2y',
            }, {
              type: 'year',
              count: 3,
              text: '3y',
            }, {
              type: 'year',
              count: 5,
              text: '5y',
            }, {
              type: 'all',
              text: 'All'
            }],
            selected: 3,
          },
          tooltip: {
            xDateFormat: '%Y-%m-%d',
            split: false,
            shared: true,
          },
          series: [
            { name: 'delta', yAxis: 1, type: 'column', data: delta },
            { name: 'bills', yAxis: 0, type: 'line', data: bills, visible: false, },
            { name: 'notesbonds', yAxis: 0, type: 'line', data: notesbonds, visible: false, },
            { name: 'frn', yAxis: 0, type: 'line', data: frn, visible: false, },
            { name: 'tips', yAxis: 0, type: 'line', data: tips, visible: false, },
            { name: 'agencies', yAxis: 0, type: 'line', data: agencies, visible: false, },
            { name: 'mbs', yAxis: 0, type: 'line', data: mbs, visible: false, },
            { name: 'cmbs', yAxis: 0, type: 'line', data: cmbs, visible: false, },
            { name: 'total', yAxis: 0, type: 'line', data: total },
          ],
          credits: { enabled: false, },
        };
        this.chartOptions = opts;
        this.loadFinish = true;
        // Highcharts.setOptions(this.chartOptions);
      });
    },
    setUp() {
      if (this.loadFinish) {
        Highcharts.stockChart(this.chartId, this.chartOptions);
      } else {
        setTimeout(() => this.setUp(), 1000);
      }
    },
  },
  created() {
    this.chartId = uuid4();
    this.loadSummary();
  },
  mounted() {
    this.setUp();
  },
};
</script>
