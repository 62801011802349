<template>
  <div>
    <!-- chart title start
    <div style="display: flex; align-items: center;">
      <h6>Transaction</h6>
      <div style="margin-left: auto;">
        <button class="btn btn-p1 bg-gradient-dark" @click="onShowCreateTransModal" > + </button>
      </div>
    </div>
    chart title end  -->

    <div v-for="(tran, index) in trans" :key="tran._id" >
      <div v-bind:class="{'tp-1': index !== 0, 'bp-1': index !== trans.length - 1 }" >
      <div class="transaction-item">
        <div style="padding: 6px 8px; display: flex;">
          <div style="width: 300px; min-width: 300px;">
            <div style="cursor: pointer; margin: 8px 0px;" @click="onShowDrawer(tran)" >
              <h5 class="font-weight-bolder mb-3" > {{ tran.name }} </h5>
              <p> {{ tran.summary }} </p>
            </div>
            <div style="display: flex">
              <p style="margin: 0px;">{{ formatDate(tran.gmt_create) }}</p>
              <p class="link-text"
                 style="font-size: 12px; margin: 2px 0px 0px 8px;"
                 >
                {{ categoryIdNameMap[tran.category] }} </p>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; overflow-y: scroll; margin: 8px 8px;">
            <div v-for="daily in tran.dailys " :key="daily._id"
                style="width: 280px; height: 100%; flex-shrink: 0; margin-right: 12px;">
              <div class="card card-background shadow-none card-background-mask-info " style="height: 100%;">
                <div class="full-background"
                    :style="{backgroundImage: 'url(' + require('../../assets/white-curved.jpeg') + ')'}"></div>
                <div class="card-body text-start p-2 w-100" >
                  <div style="cursor: pointer; margin: 8px 0px;" @click="onShowDailyDrawer(daily)" >
                    <h6 class="text-white font-weight-bolder mb-2"> {{ daily.name }} </h6>
                    <p style="font-size:11px; margin-bottom: 4px;">{{ formatDate(daily.gmt_create) }}</p>
                    <p class="text-white mb-2"> {{ daily.summary }} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>

    <!-- trade chance modal start -->
    <a-modal
        :title="transModalTitle"
        :visible="transModalVisible"
        :footer="null"
        class="local-modal"
        @cancel="onCloseChanceModal"
    >
      <a-form-model :model="transFormData"
          :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="Name" required style="marginBottom: 8">
          <a-input v-model="transFormData.name"/>
        </a-form-model-item>
        <a-form-model-item label="Summary" required >
          <a-input v-model="transFormData.summary" type="textarea" />
        </a-form-model-item>
        <a-form-model-item label="Trade Logic" required >
          <a-select
            show-search
            v-model="transFormData.logic_id"
            placeholder="input search text"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="searchLogicForSelect"
            @focus="() => searchLogicForSelect('')"
            @change="onSelectLogic"
            >
            <a-select-option v-for="d in searchedLogics" :key="d._id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Category" required >
          <a-tree-select
            v-model="transFormData.category"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="searchedCategories"
            placeholder="Please select"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
          <a-button @click="onCloseChanceModal"> Cancel </a-button>
          <a-button style="margin-left: 12px;" type="primary" @click="onSubmitChanceForm">
            Submit
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- trade chance modal end -->

    <!-- drawer start -->
    <a-drawer width="900" placement="right" :closable="false"
        :visible="drawerVisible"
        @close="onCloseDrawer"
        style="z-index: 10;"
        :bodyStyle="{background: 'rgb(245, 245, 245)', padding: '18px', minHeight: '100%'}" >
      <div>
        <!-- header -->
        <div style="padding: 0px 6px;">
          <div style="margin: 0px; display: flex; align-items: center;">
            <h4 v-if="!drawerEditMode"> {{ drawerData.name }} </h4>
            <a-input v-else v-model="drawerData.name" style="width: 600px;" />

            <div style="margin-left: auto;">
              <button v-if="!drawerEditMode" class="btn btn-p1 bg-gradient-dark" @click="onStartEditTrans" >Edit</button>
              <button v-else class="btn btn-p1 bg-gradient-dark" @click="onEndEditTrans" >Save</button>
              <!-- delete button -->
              <a-popconfirm
                title="Are you sure delete this?"
                ok-text="Yes" cancel-text="No"
                @confirm="onDeleteTrans" >
                <button v-if="!drawerEditMode" class="btn btn-p1 bg-gradient-danger" style="margin-left: 8px;" >Delete</button>
              </a-popconfirm>
            </div>
          </div>

          <div>
            <p v-if="!drawerEditMode"><i> {{ drawerData.summary }} </i></p>
            <a-textarea v-else v-model="drawerData.summary" style="margin: 12px 0px 12px 0px; width: 600px;" />
          </div>

          <!-- Category -->
          <p v-if="!drawerEditMode" class="link-text" style="font-size: 12px;">
              {{ categoryIdNameMap[drawerData.category] }}
          </p>
          <a-tree-select v-else
            v-model="drawerData.category"
            style="margin-top: 0px; margin-bottom: 12px; width: 300px;"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="searchedCategories"
            placeholder="Please select"
            tree-default-expand-all
          >
          </a-tree-select>
        </div>

        <!-- Trade Dailys -->
        <div class="card" style="" >
          <div class="card-content">
            <!-- chart title start -->
            <div style="display: flex; align-items: center; margin-bottom: 12px;">
              <h6> Daily </h6>
              <div style="margin-left: auto;">
                <button class="btn btn-p1 bg-gradient-dark" @click="onShowAddDailyForm" > + </button>
              </div>
            </div>
            <!-- chart title end  -->

            <div style="display: flex; flex-direction: row; overflow-x: scroll;">
              <div v-for="item in drawerData.dailys " :key="item._id"
                  style="width: 280px; flex-shrink: 0; margin-right: 12px;">
                <div class="bg-gradient-info border-radius-md text-white">
                  <div style="padding: 6px 8px 6px 8px;">
                    <div style="cursor: pointer; margin: 8px 0px;" @click="onShowDailyDrawer(item)" >
                      <h6 class="text-white font-weight-bolder mb-2" > {{ item.name }} </h6>
                      <p class="mb-2"> {{ item.summary }} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- chart end -->

        <!-- 正文 -->
        <div class="card" style="height: 100%; margin-top: 12px;" >
          <div class="card-content">
            <!-- chart title start -->
            <div style="display: flex; align-items: center; margin-bottom: 12px;">
              <h6> 正文 </h6>
              <div style="margin-left: auto;"> </div>
            </div>
            <!-- chart title end  -->

            <div v-if="!drawerEditMode" v-html="drawerData.content"> </div>
            <div v-else> <mr-quill-editor v-model="drawerData.content" /> </div>
          </div>
        </div>
      </div>
    </a-drawer>
    <!-- drawer end -->

    <a-modal
        :title="dailyModalTitle"
        :visible="dailyModalVisible"
        :footer="null"
        class="local-modal"
        @cancel="onCloseDailyModal"
    >
      <a-form-model :model="dailyFormData"
          :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="Name" required style="marginBottom: 8">
          <a-input v-model="dailyFormData.name"/>
        </a-form-model-item>
        <a-form-model-item label="Summary" required >
          <a-input v-model="dailyFormData.summary" type="textarea" />
        </a-form-model-item>
      </a-form-model>
      <div style="display: flex; width: 100%;">
      <button class="btn btn-p2 bg-gradient-dark" style="width: 200px; margin: 0 auto;"
          @click="onSumitDailyForm" > Submit </button>
      </div>
    </a-modal>

    <!-- daily drawer start -->
    <a-drawer
        width="800" placement="right" :closable="false"
        :visible="dailyDrawerVisible"
        @close="onCloseDailyDrawer"
        style="z-index: 100;"
        :bodyStyle="{background: 'rgb(245, 245, 245)', padding: '18px', minHeight: '100%'}" >
      <div>
        <!-- header -->
        <div style="padding: 0px 6px;">
          <div style="margin: 0px; display: flex; align-items: center;">
            <h4 v-if="!dailyDrawerEditMode"> {{ dailyDrawerData.name }} </h4>
            <a-input v-else v-model="dailyDrawerData.name" style="width: 600px;" />

            <div style="margin-left: auto;">
              <button v-if="!dailyDrawerEditMode" class="btn btn-p1 bg-gradient-dark" @click="onStartEditDaily" >Edit</button>
              <button v-else class="btn btn-p1 bg-gradient-dark" @click="onEndEditDaily" >Save</button>
              <!-- delete button -->
              <a-popconfirm
                title="Are you sure delete this?"
                ok-text="Yes" cancel-text="No"
                @confirm="onDeleteDaily" >
                <button v-if="!dailyDrawerEditMode" class="btn btn-p1 bg-gradient-danger" style="margin-left: 8px;" >Delete</button>
              </a-popconfirm>
            </div>
          </div>

          <div>
            <p v-if="!dailyDrawerEditMode"><i> {{ dailyDrawerData.summary }} </i></p>
            <a-textarea v-else v-model="dailyDrawerData.summary" style="margin: 12px 0px 12px 0px; width: 600px;" />
          </div>
        </div>

        <!-- Facts -->
        <div class="card" style="" >
          <div class="card-content">
            <!-- chart title start -->
            <div style="display: flex; align-items: center; margin-bottom: 0px;">
              <h6> Facts </h6>
              <div style="margin-left: auto;">
                <button class="btn btn-p1 bg-gradient-danger" @click="onShowLinkFactForm" > + </button>
              </div>
            </div>
            <!-- chart title end  -->

            <div style="display: flex; flex-direction: row; overflow-x: scroll;">
              <div v-for="fact in factsForDailyDrawer" :key="fact._id"
                  style="width: 280px; flex-shrink: 0; margin-right: 4px;">
                <div style="position: relative; padding: 8px 8px 8px 0px;">
                  <button class="btn btn-p1 bg-gradient-dark"
                      style="position:absolute; top:0; right:0; z-index: 100;" @click="onRemoveFactLink(fact._id)" > x </button>
                  <story-item :fact="fact" style="z-index: 10;"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- chart end -->

        <!-- 正文 -->
        <div class="card" style="height: 100%; margin-top: 12px;" >
          <div class="card-content">
            <!-- chart title start -->
            <div style="display: flex; align-items: center; margin-bottom: 12px;">
              <h6> 正文 </h6>
              <div style="margin-left: auto;"> </div>
            </div>
            <!-- chart title end  -->
            <div v-if="!dailyDrawerEditMode" v-html="dailyDrawerData.content"> </div>
            <mr-quill-editor v-else v-model="dailyDrawerData.content" />
          </div>
        </div>
      </div>
    </a-drawer>
    <!-- drawer end -->

    <!-- link fact modal start -->
    <a-modal title="Link Fact"
        :visible="linkFactModalVisible"
        :footer="null"
        class="local-modal"
        @cancel="onCloseLinkFactModal"
    >
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-model-item label="Fact" required >
          <a-select
            show-search
            mode="singel"
            v-model="toLinkFactId"
            placeholder="input search text"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="onSearchFacts"
            @focus="() => onSearchFacts('')"
            @change="() => {}"
            >
            <a-select-option v-for="d in serachedFacts" :key="d._id">
              {{ d.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
          <a-button @click="onCloseLinkFactModal"> Cancel </a-button>
          <a-button style="margin-left: 12px;" type="primary" @click="onAddLinkFact"> Add Link </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- trade chance modal end -->


  </div>
</template>

<script>
import axios from 'axios';
import { doPost, doGet } from '@/script/ServerTools';
import { timestampToFormatDate } from '@/script/DateTools';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import MrQuillEditor from '../../components/MrQuillEditor.vue';
import StoryItem from './StoryItem.vue';

export default {
  name: 'TradeTrans',
  components: { MrQuillEditor, StoryItem, },
  props: [ 'category', 'createTransSignal', ],
  data() {
    return {
      trans: [ ],

      labelCol: { span: 6 },
      wrapperCol: { span: 16 },

      categoryIdNameMap: {},

      // chance form
      transModalVisible: false,
      transModalTitle: 'Create New Transaction',
      searchedCategories: [],
      searchedLogics: [],
      transFormData: {
        _id: '',
        name: '',
        summary: '',
        category: '',
        logic_id: '',
      },

      drawerVisible: false,
      drawerEditMode: false,
      drawerData: {
        _id: '',
        name: '',
        summary: '',
        category: '',
      },

      // daily form
      dailyModalTitle: 'Add Daily',
      dailyModalVisible: false,
      dailyFormData: {
        id: '',
        name: '',
        summary: '',
      },

      // daily drawer
      dailyDrawerVisible: false,
      dailyDrawerEditMode: false,
      dailyDrawerData: {},
      factsForDailyDrawer: [ ],

      linkFactModalVisible: false,
      toLinkFactId: '',
      serachedFacts: [ ],
    };
  },
  methods: {
    onShowCreateTransModal() {
      if (this.transModalVisible) {
        return;
      }
      this.transFormData._id = '';
      this.transModalTitle = 'Create New Transaction';
      this.loadCategoryTree();
      this.transModalVisible = true;
    },
    onCloseChanceModal() {
      this.transFormData._id = '';
      this.transModalVisible = false;
    },
    onSubmitChanceForm() {
      if (this.transFormData._id) {
        doPost('/api/trade/updateTradeTrans', this.transFormData).then((rsp) => {
          this.$message({ message: rsp.message, type: 'success' });
          this.loadTrans();
        });
      } else {
        doPost('/api/trade/createTradeTrans', this.transFormData).then((rsp) => {
          this.$message({ message: rsp.message, type: 'success' });
          this.loadTrans();
        });
      }
      this.transModalVisible = false;
    },
    onSelectLogic(value) {
      this.searchedLogics.forEach((it) => {
        if (it._id === value) {
          this.transFormData.category = it.category;
        }
      });
    },
    searchLogicForSelect(text) {
      doGet('/api/trade/queryTradeLogics', { name: text, page_size: 20, page_no: 0 }).then((rsp) => {
        this.searchedLogics = rsp.data.items;
      });
    },

    // ------------------------------------------------
    onShowDrawer(item) {
      if (item._id !== this.drawerData._id) {
        this.drawerEditMode = false;
      }
      this.drawerData = item;
      this.loadCategoryTree();
      this.loadDailysForTransId(this.drawerData._id);
      this.drawerVisible = true;
    },
    onCloseDrawer() {
      this.drawerVisible = false;
    },
    onDeleteTrans() {
      doPost('/api/trade/deleteTradeTrans', { id: this.drawerData._id }).then((rsp) => {
        this.onCloseDrawer();
        this.loadTrans();
        this.$message({ message: rsp.message, type: 'success' });
      });
    },
    onStartEditTrans() {
      this.drawerEditMode = true;
    },
    onEndEditTrans() {
      this.drawerEditMode = false;
      this.saveTrans();
    },
    saveTrans() {
      doPost('/api/trade/updateTradeTrans', this.drawerData).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
      });
    },

    // ------------------------------------------------
    onShowAddDailyForm() {
      this.dailyModalTitle = 'Create new Daily';
      this.dailyFormData.id = '';
      this.dailyFormData.name = '';
      this.dailyFormData.summary = '';
      this.dailyModalVisible = true;
    },
    onCloseDailyModal() {
      this.dailyModalVisible = false;
    },
    onSumitDailyForm() {
      console.log('dailyFormData: ', this.dailyFormData);
      if (!this.dailyFormData.id) {
        doPost('/api/trade/createTradeDaily', {
          ...this.dailyFormData, transaction_id: this.drawerData._id,
        }).then((rsp) => {
          this.$message({ message: rsp.message, type: 'success' });
          this.loadDailysForTransId(this.drawerData._id);
        });
      }
      this.dailyModalVisible = false;
    },

    // ------------------------------------------------
    onShowDailyDrawer(data) {
      if (data._id !== this.dailyDrawerData._id) {
        this.dailyDrawerEditMode = false;
      }
      this.dailyDrawerData = data;
      doPost('/api/story/queryFactsById', { ids: this.dailyDrawerData.fact_ids, }).then((rsp) => {
        this.factsForDailyDrawer = rsp.data;
        this.factsForDailyDrawer.sort((a, b) => b.gmt_occur - a.gmt_occur);
        this.dailyDrawerVisible = true;
      });
    },
    onCloseDailyDrawer() {
      this.dailyDrawerVisible = false;
    },
    onStartEditDaily() {
      this.dailyDrawerEditMode = true;
    },
    onEndEditDaily() {
      this.dailyDrawerEditMode = false;
      this.saveDaily();
    },
    onDeleteDaily() {
      doPost('/api/trade/deleteTradeDaily', { id: this.dailyDrawerData._id }).then((rsp) => {
        this.onCloseDailyDrawer();
        this.loadDailysForTransId(this.drawerData._id);
        this.$message({ message: rsp.message, type: 'success' });
      });
    },
    saveDaily() {
      doPost('/api/trade/updateTradeDaily', this.dailyDrawerData).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
      });
    },
    // ------------------------------------------------
    // daily link fact
    onShowLinkFactForm() {
      this.linkFactModalVisible = true;
    },
    onCloseLinkFactModal() {
      this.linkFactModalVisible = false;
    },
    onAddLinkFact() {
      if (!this.dailyDrawerData.fact_ids) {
        this.dailyDrawerData.fact_ids = [ this.toLinkFactId, ];
      } else {
        this.dailyDrawerData.fact_ids.push(this.toLinkFactId);
      }
      // 提交更新
      this.updateDailyLinkFacts(
        this.dailyDrawerData._id,
        this.dailyDrawerData.fact_ids
      );
      // 关闭表单
      this.linkFactModalVisible = false;
      // 刷新前端数据
      this.loadDailyFacts();
    },
    onRemoveFactLink(removeId) {
      const newArr = [ ];
      this.dailyDrawerData.fact_ids.forEach((id) => {
        if (id !== removeId) {
          newArr.push(id);
        }
      });
      this.dailyDrawerData.fact_ids = newArr;
      this.updateDailyLinkFacts(this.dailyDrawerData._id, newArr);
      this.loadDailyFacts();
    },
    onSearchFacts(name) {
      doGet('/api/story/queryFacts', { name, }).then((rsp) => {
        this.serachedFacts = rsp.data.items;
      });
    },
    loadDailyFacts() {
      doPost('/api/story/queryFactsById', { ids: this.dailyDrawerData.fact_ids, }).then((rsp) => {
        this.factsForDailyDrawer = rsp.data;
        this.factsForDailyDrawer.sort((a, b) => b.gmt_occur - a.gmt_occur);
      });
    },
    updateDailyLinkFacts(id, factIds) {
      doPost('/api/trade/updateTradeDaily', { _id: id, fact_ids: factIds, }).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
      });
    },

    // ------------------------------------------------
    buildCategoryTree(l) {
      const ll = [];
      l.forEach((it) => {
        const item = { key: it._id, title: it.name, value: it._id };
        if (it.children) {
          item.children = this.buildCategoryTree(it.children);
        }
        ll.push(item);
      });
      return ll;
    },
    buildCategoryMap(l) {
      let result = {};
      l.forEach((it) => {
        result[it._id] = it.name;
        if (it.children) {
          result = Object.assign(result, this.buildCategoryMap(it.children));
        }
      });
      return result;
    },

    // ------------------------------------------------
    // data loading methods
    loadCategoryTree(text) {
      doGet('/api/category/queryTradeCategory', { name: text, }).then((rsp) => {
        this.searchedCategories = this.buildCategoryTree(rsp.data);
        this.categoryIdNameMap = this.buildCategoryMap(rsp.data);
      });
    },
    loadTrans() {
      const data = { page_size: 50, page_no: 0, };
      if (this.category) {
        data.category = this.category;
      }
      doGet('/api/trade/queryTradeTrans', data).then((rsp) => {
        this.trans = [];
        this.loadDailysForTrans(rsp.data.items);
      });
    },
    loadDailysForTrans(rest) {
      if (!rest || rest.length === 0) {
        return;
      }
      const trans = rest.shift();
      doGet('/api/trade/queryTradeDaily', { transaction_id: trans._id }).then((rsp) => {
        const item = { ...trans, dailys: rsp.data.items };
        this.trans.push(item);
        this.loadDailysForTrans(rest);
      });
    },
    loadDailysForTransId(transId) {
      doGet('/api/trade/queryTradeDaily', { transaction_id: transId }).then((rsp) => {
        this.trans.forEach((trans) => {
          if (trans._id === transId) {
            trans.dailys = rsp.data.items;
          }
        });
      });
    },
    formatDate(ts) {
      return timestampToFormatDate(ts);
    },
  },
  watch: {
    category(val) {
      this.loadTrans();
    },
    createTransSignal(val) {
      this.onShowCreateTransModal();
    },
  },
  mounted() {
    this.loadCategoryTree();
    this.loadTrans();
  },
};
</script>

<style>
.column-container {
  columns: 2;
  column-gap: 10px;
}
.trans-item {
  break-inside: avoid;
  padding-top: 4px;
  padding-bottom: 4px;
}
.transaction-item {
  background-color: #ffffff;
  box-shadow: 0 0px 10px 0 rgb(0 0 0 / 5%);
  border-radius: .5rem;
}
</style>
