<template>
  <div style="padding: 16px">
    <table class="mr-table">
      <thead class="mr-table-thead" >
        <tr>
          <th v-for="column in columns" :key="column.code">
            {{ column.name }}
          </th>
        </tr>
      </thead>
      <tbody class="mr-table-tbody">
        <tr v-for="row in rows" :key="row.rowKey">
          <td v-for="column in columns" :key="column.code">
            {{ row[column.code] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</template>

<!--
通用table组件
参数，格式如下：
  columns: [  // 列定义
    { code: 'value1', name: '1' },
    { code: 'value2', name: '2' },
  ],
  rows: [   // 行数据
    { rowKey: 1, value1: 'hohoho', value2: 'hahahaha' },
    { rowKey: 2, value1: 'hohoho', value2: 'hahahaha' },
  ],
  headButtons: [ // 顶部按钮列表，放置在顶部左侧
    {
      name: '',
      actionType: 'popupForm',
      formData: {
        form: [
          { name: '名称', type: 'textEdit', value: '' }
        ],
        actions: [
          {
            name: '确定', actionType: 'post' },
          { name: '取消', actionType: 'close' },
        ]
      }
    }
  ]
}
-->

<script>
export default {
  name: 'CommonTable',
  props: ['title', 'columns', 'rows', 'headButtons'],
  components: {
  },
  data() {
    return {
      selectedRow: {},
      selectedKey: '',
    };
  },
  methods: {
    onSelectRow(row) {
      console.log('==== onSelectRow ====', row);
      this.selectedRow = row;
      this.selectedKey = row[this.keyAttr];
      if (this.selectCallback) {
        this.selectCallback(row);
      }
    },
    rowColor(row) {
      if (row.bg_color) {
        return { 'background-color': row.bg_color };
      }
      return {};
    },
  },
};
</script>

<style>
.it-container {
  overflow: auto;
}
.it-view-table {
  display: table;
  width: 100%;
  height: 500px;
  word-break: keep-all;
  overflow: scroll;
}
.it-view-table.active {
  background: #ddd;
  color: #eafaf9;
  display: block;
  transition: .25s;
}
.it-view-thead {
  display: table-row;
  background: #d9d9d9;
}
.it-view-thead > div {
  display: table-cell;
}
.it-view-row {
  display: table-row;
}
.it-view-row > div {
  display: table-cell;
}
.it-view-td {
  padding: 2px 8px;
  text-align: left;
}
.it-view-td a {
  color: #108ee9;
  font-size: 11px;
}
.it-view-td.highlight {
  background: #ffce3d;
}

.it-view-row.active {
  background: #ddd;
  color: #eafaf9;
  transition: .25s;
}
.it-view-table .it-view-row:not(.active):hover{
  background-color:#ddd
}

.mr-table {
  width: 100%;
  text-align: left;
  border-spacing: 0;
}
.mr-table-thead>tr>th, .mr-table-tbody>tr>td {
  padding: 8px;
  font-size: 14px;
}
.mr-table-thead>tr>th {
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
}
.mr-table-tbody>tr>td {
  border-bottom: 1px solid #f0f0f0;
}
.mr-content-auto-size {
  display: inline-block;
  width: auto;
  height: auto;
}
</style>
