<template>
  <div>
    <!-- chart title start -->
    <div style="display: flex; align-items: center;">
      <h6>交易逻辑</h6>
      <div style="margin-left: auto;">
        <button class="btn btn-p1 bg-gradient-dark" @click="onShowCreateLogicModal" > + </button>
      </div>
    </div>

    <div style="height: 450px; overflow-y: scroll;">
    <div class="column-container" >
      <div class="logic-item "
          v-for="item in tradeLogics" :key="item._id">
        <div class="card card-background shadow-none card-background-mask-dark ">
          <div class="full-background" :style="{backgroundImage: 'url(' + require('../../assets/white-curved.jpeg') + ')'}"></div>
          <div class="card-body text-start p-2 w-100" >
            <div style="cursor: pointer; margin: 8px 0px;" @click="onShowLogicDrawer(item)" >
              <h6 class="text-white font-weight-bolder mb-2" > {{ item.name }} </h6>
              <p class="text-white mb-2" > {{ item.summary }} </p>
            </div>
            <p class="link-text" style="font-size: 12px; margin-top: -4px; margin-bottom: 0px;">
                {{ categoryIdNameMap[item.category] }} </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!--
    </RecycleScroller>
    -->

    <!-- trade logic modal start -->
    <a-modal
        :title="logicModalTitle"
        :visible="logicModalVisible"
        :footer="null"
        class="local-modal"
        @cancel="onCloseLogicModal"
    >
      <a-form-model :model="logicFormData"
          :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-model-item label="Name" required style="marginBottom: 8">
          <a-input v-model="logicFormData.name"/>
        </a-form-model-item>
        <a-form-model-item label="Summary" required >
          <a-input v-model="logicFormData.summary" type="textarea" />
        </a-form-model-item>

        <a-form-model-item label="Category" required >
          <a-tree-select
            v-model="logicFormData.category"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="searchedCategories"
            placeholder="Please select"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-model-item>

        <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
          <a-button @click="onCloseLogicModal"> Cancel </a-button>
          <a-button style="margin-left: 12px;" type="primary" @click="onSubmitLogicForm">
            Submit
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- trade logic modal end -->

    <!-- logic drawer start -->
    <a-drawer
        width="900" placement="right" :closable="false"
        :visible="logicDrawerVisible" @close="onCloseLogicDrawer"
        :bodyStyle="{background: 'rgb(245, 245, 245)', padding: '18px', minHeight: '100%'}"
      >
      <!-- header start -->
      <div style="padding: 0px 6px;">
        <div style="margin: 0px; display: flex; align-items: center;">
          <h4 v-if="!logicDrawerEditMode"> {{ logicDrawerData.name }} </h4>
          <a-input v-else v-model="logicDrawerData.name" style="width: 600px;" />

          <div style="margin-left: auto;">
            <button v-if="!logicDrawerEditMode" class="btn btn-p1 bg-gradient-dark" @click="onStartEditLogic" >Edit</button>
            <button v-else class="btn btn-p1 bg-gradient-dark" @click="onEndEditLogic" >Save</button>
            <!-- delete button -->
            <a-popconfirm
              title="Are you sure delete this?"
              ok-text="Yes" cancel-text="No"
              @confirm="onDeleteLogic" >
              <button v-if="!logicDrawerEditMode" class="btn btn-p1 bg-gradient-danger" style="margin-left: 8px;"
                >Delete</button>
            </a-popconfirm>
          </div>
        </div>

        <div>
          <p v-if="!logicDrawerEditMode"><i> {{ logicDrawerData.summary }} </i></p>
          <a-textarea v-else v-model="logicDrawerData.summary" style="margin: 12px 0px 12px 0px; width: 600px;" />
        </div>

        <!-- Category -->
        <p v-if="!logicDrawerEditMode" class="link-text" style="font-size: 12px;">
            {{ categoryIdNameMap[logicDrawerData.category] }} </p>
        <a-tree-select v-else
          v-model="logicDrawerData.category"
          style="margin-top: 0px; margin-bottom: 12px; width: 300px;"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="searchedCategories"
          placeholder="Please select"
          tree-default-expand-all
        >
        </a-tree-select>
      </div>
      <!-- header end -->

        <div class="card" style="height: 100%; margin-top: 12px;" >
          <div class="card-content">
            <div v-if="!logicDrawerEditMode" v-html="logicDrawerData.content"> </div>
            <mr-quill-editor v-else v-model="logicDrawerData.content" />
          </div>
        </div>
    </a-drawer>
    <!-- logic drawer end -->
  </div>
</template>

<script>
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

import MrIconButton from '../../components/MrIconButton.vue';
import MrQuillEditor from '../../components/MrQuillEditor.vue';
import { doPost, doGet } from '@/script/ServerTools';

export default {
  name: 'TradeLogics',
  components: { MrQuillEditor, },
  props: [ 'category' ],
  data() {
    return {
      tradeLogics: [ ],

      labelCol: { span: 5 },
      wrapperCol: { span: 15 },

      categoryIdNameMap: {},

      // logic form
      logicModalVisible: false,
      logicModalTitle: 'Create New TradeLogic',
      searchedCategories: [],
      logicFormData: {
        _id: '',
        name: '',
        summary: '',
        category: '',
      },

      // logic drawer
      logicDrawerVisible: false,
      logicDrawerData: {
        _id: '',
      },
      logicDrawerEditMode: false,
    };
  },
  methods: {
    onShowCreateLogicModal() {
      this.logicFormData._id = '';
      this.logicModalTitle = 'Create New TradeLogic';
      this.loadCategoryTree();
      this.logicModalVisible = true;
    },
    onCloseLogicModal() {
      this.logicFormData._id = '';
      this.logicModalVisible = false;
    },
    onSubmitLogicForm() {
      if (this.logicFormData._id) {
        doPost('/api/trade/updateTradeLogic', this.logicFormData).then((rsp) => {
          this.$message({ message: rsp.message, type: 'success' });
          this.loadLogics();
        });
      } else {
        doPost('/api/trade/createTradeLogic', this.logicFormData).then((rsp) => {
          this.$message({ message: rsp.message, type: 'success' });
          this.loadLogics();
        });
      }
      this.logicModalVisible = false;
    },
    onShowLogicDrawer(logicData) {
      this.logicDrawerData = logicData;
      this.loadCategoryTree();
      this.logicDrawerVisible = true;
    },
    onCloseLogicDrawer() {
      this.logicDrawerVisible = false;
      this.logicDrawerEditMode = false;
    },
    onSaveLogic() {
      this.saveTradeLogic(this.logicDrawerData);
    },
    onStartEditLogic() {
      this.logicDrawerEditMode = true;
    },
    onEndEditLogic() {
      this.logicDrawerEditMode = false;
      this.onSaveLogic();
    },
    onDeleteLogic() {
      doPost('/api/trade/deleteTradeLogic', { id: this.logicDrawerData._id }).then((rsp) => {
        this.onCloseLogicDrawer();
        this.loadLogics();
        this.$message({ message: rsp.message, type: 'success' });
      });
    },

    // ------------------------------------------------
    buildCategoryTree(l) {
      const ll = [];
      l.forEach((it) => {
        const item = { key: it._id, title: it.name, value: it._id };
        if (it.children) {
          item.children = this.buildCategoryTree(it.children);
        }
        ll.push(item);
      });
      return ll;
    },
    buildCategoryMap(l) {
      let result = {};
      l.forEach((it) => {
        result[it._id] = it.name;
        if (it.children) {
          result = Object.assign(result, this.buildCategoryMap(it.children));
        }
      });
      return result;
    },

    // ------------------------------------------------
    // saving methods
    saveTradeLogic(data) {
      doPost('/api/trade/updateTradeLogic', data).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
      });
    },

    // ------------------------------------------------
    // data loading methods
    loadLogics() {
      const data = { page_size: 50, page_no: 0, };
      if (this.category) {
        data.category = this.category;
      }
      doGet('/api/trade/queryTradeLogics', data).then((rsp) => {
        this.tradeLogics = rsp.data.items;
      });
    },
    loadCategoryTree(text) {
      doGet('/api/category/queryTradeCategory', { name: text, }).then((rsp) => {
        this.searchedCategories = this.buildCategoryTree(rsp.data);
        this.categoryIdNameMap = this.buildCategoryMap(rsp.data);
      });
    },
  },
  watch: {
    category(val) {
      this.loadLogics();
    },
  },
  mounted() {
    this.loadCategoryTree();
    this.loadLogics();
  },
};
</script>

<style>
.column-container {
  columns: 1;
  column-gap: 10px;
}
.logic-item {
  break-inside: avoid;
  padding-top: 4px;
  padding-bottom: 4px;
}
</style>
