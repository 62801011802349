var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"body"}},[_c('div',{attrs:{"id":"content"}},[_c('div',{staticClass:"mr-main"},[_vm._m(0),_c('div',{staticClass:"container-layout"},[_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","height":"350px","margin":"0px 4px 4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr height100p",staticStyle:{"width":"50%"},attrs:{"title":'利差',"indicators":[
                {code: 'US10Y_3M', type: 'line', },
                {code: 'US10Y_2Y', type: 'line', },
                {code: 'US2Y_3M', type: 'column', } ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":3}}),_c('YieldCurve',{staticClass:"marginlr",staticStyle:{"width":"50%","height":"100%"}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('FedRateExp',{staticClass:"marginlr",staticStyle:{"width":"40%","height":"608px"}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"60%","height":"608px"},attrs:{"title":'Fed Balance Sheet - Assets',"indicators":[
                {code: 'FED_BS_ASSETS_Bills', type: 'area', stack: 'stack' },
                {code: 'FED_BS_ASSETS_Notes_and_bonds_nominal', type: 'area', stack: 'stack', title: 'Notes and Bonds', },
                {code: 'FED_BS_ASSETS_Notes_and_bonds_inflation_indexed', type: 'area', stack: 'stack', title: 'TIPS', },
                {code: 'FED_BS_ASSETS_Inflation_compensation', type: 'area', stack: 'stack' },
                {code: 'FED_BS_ASSETS_Federal_agency_debt_securities', type: 'area', stack: 'stack', title: '联邦机构债务证券' },
                {code: 'FED_BS_ASSETS_Mortgage-backed_securities', type: 'area', stack: 'stack', title: 'MBS', },
                {code: 'FED_BS_ASSETS_Unamortized_premiums_on_securities_held_outright', type: 'area', stack: 'stack', title: 'Securities premiums' },
                {code: 'FED_BS_ASSETS_Unamortized_discounts_on_securities_held_outright', type: 'area', stack: 'stack', title: 'Securities discounts' },
                {code: 'FED_BS_ASSETS_Repurchase_agreements', type: 'area', stack: 'stack', title: 'Repurchase' },
                {code: 'FED_BS_ASSETS_Loans', type: 'area', stack: 'stack', title: 'Loans(Discount Window)' },
                {code: 'FED_BS_ASSETS_Net_portfolio_holdings_of_MS_Facilities_LLC', type: 'area', stack: 'stack', title: 'MS Facilities LLC', },
                {code: 'FED_BS_ASSETS_Other_Federal_Reserve_assets', type: 'area', stack: 'stack', title: '美联储其他资产' },
                {code: 'FED_BS_ASSETS_Foreign_currency_denominated_assets', type: 'area', stack: 'stack', title: '外币计价资产' },
                {code: 'FED_BS_ASSETS_Gold_stock', type: 'area', stack: 'stack' },
                // {code: 'FED_BS_ASSETS_Special_drawing_rights_certificate_account', type: 'area', stack: 'stack' },
                {code: 'FED_BS_ASSETS_Treasury_currency_outstanding', type: 'area', stack: 'stack', title: '未偿国库券' },
                {code: 'FED_BS_LIABILITIES_Currency_in_circulation', type: 'area', stack: 'stack', yAxis: 'reverse' },
                {code: 'FED_BS_LIABILITIES_Reverse_repurchase_agreements', type: 'area', stack: 'stack', title: 'Reverse repurchase', yAxis: 'reverse'},
                // {code: 'FED_BS_LIABILITIES_Treasury_cash_holdings', type: 'area', stack: 'stack' },
                // {code: 'FED_BS_LIABILITIES_Term_deposits_held_by_depository_institutions', type: 'area', stack: 'stack' },
                {code: 'FED_BS_LIABILITIES_US_Treasury_General_Account', type: 'area', stack: 'stack', title: 'TGA',yAxis: 'reverse' },
                {code: 'FED_BS_LIABILITIES_Foreign_official', type: 'area', stack: 'stack', yAxis: 'reverse' },
                {code: 'FED_BS_LIABILITIES_Other_Deposits_with_FR_Banks', type: 'area', stack: 'stack', title: 'Other Deposits',yAxis: 'reverse' },
                {code: 'FED_BS_LIABILITIES_Treasury_contributions_to_credit_facilities', type: 'area', stack: 'stack', title: 'Treasury contributions to CF',yAxis: 'reverse' },
                {code: 'FED_BS_LIABILITIES_Other_liabilities_and_capital', type: 'area', stack: 'stack',yAxis: 'reverse' },
                {code: 'FED_BS_LIABILITIES_Reserve_balances_with_Federal_Reserve_Banks', type: 'area', stack: 'stack', title: 'Reserves',yAxis: 'reverse' } ],"type":'stackingArea',"show-value":true,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":1}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","height":"350px","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr height100p",staticStyle:{"width":"34%"},attrs:{"title":'S&P500 PE',"indicators":[
                {code: 'SP500_PE_WSJ', type: 'line', },
                {code: 'SP500_12M_FORWARD_PE_WSJ', type: 'line', },
                {code: 'Shiller_PE', type: 'line', yAxis: 'right', }
              ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', '10y', 'All'],"rangeSelectorSelected":3}}),_c('IndicatorChart',{staticClass:"marginlr height100p",staticStyle:{"width":"34%"},attrs:{"title":'Quarter EPS Forecast',"show-value":false,"indicators":_vm.epsForecastIndicators,"legend":'center'}}),_c('IndicatorChart',{staticClass:"marginlr height100p",staticStyle:{"width":"34%"},attrs:{"title":'Last Quarter EPS Full Fill',"show-value":false,"indicators":_vm.epsFullFillIndicators,"legend":'center'}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'CPI & Core CPI MoM',"indicators":[
                {code: 'US_CPI_MoM_All_items', },
                {code: 'US_CPI_MoM_L1_All_items_less_food_and_energy', } ],"type":'column',"show-value":true,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":1,"recordApiPath":'/cpi'}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"67.6%","height":"400px"},attrs:{"title":'CPI Components',"indicators":[
                {code: 'US_CPI_MoM_L1_Food', type: 'column', },
                {code: 'US_CPI_MoM_L2_Energy_commodities', type: 'column', },
                {code: 'US_CPI_MoM_L2_Energy_services', type: 'column', },
                {code: 'US_CPI_MoM_L2_Commodities_less_food_and_energy_commodities', type: 'column', title: '核心商品' },
                {code: 'US_CPI_MoM_L3_Shelter', type: 'column',},
                {code: 'US_CPI_MoM_L3_Medical_care_services', type: 'column',},
                {code: 'US_CPI_MoM_L3_Transportation_services', type: 'column',} ],"type":'column',"show-value":true,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":1}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'PCE & Core PCE MoM',"indicators":[{code: 'US_PCE_MOM', type: 'column', }, {code: 'US_CORE_PCE_MOM', type: 'column', }],"type":'column',"show-value":true,"legend":null,"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":1}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"67.6%","height":"400px"},attrs:{"title":'CPI Components Contribution',"indicators":[
                {code: 'US_CPI_MoM_All_items', type: 'line', },
                {code: 'US_CPI_MONTH_EFFECT_FOOD_AT_HOME', type: 'column', stack: 'stack' },
                {code: 'US_CPI_MONTH_EFFECT_FOOD_AWAY_FROM_HOME', type: 'column', stack: 'stack' },
                {code: 'US_CPI_MONTH_EFFECT_ENERGY_COMMODITIES', type: 'column', stack: 'stack' },
                {code: 'US_CPI_MONTH_EFFECT_ENERGY_SERVICES', type: 'column', stack: 'stack' },
                {code: 'US_CPI_MONTH_EFFECT_COMMODITIES_LESS_FOOD_AND_ENERGY_COMMODITIES', type: 'column', stack: 'stack', title: '核心商品'},
                {code: 'US_CPI_MONTH_EFFECT_RENT_OF_SHELTER', type: 'column', stack: 'stack' },
                {code: 'US_CPI_MONTH_EFFECT_MEDICAL_CARE_SERVICES', type: 'column', stack: 'stack' },
                {code: 'US_CPI_MONTH_EFFECT_TRANSPORTATION_SERVICES', type: 'column', stack: 'stack' },
                {code: 'US_CPI_MONTH_EFFECT_RECREATION_SERVICES', type: 'column', stack: 'stack' },
                {code: 'US_CPI_MONTH_EFFECT_EDUCATION_AND_COMMUNICATION_SERVICES', type: 'column', stack: 'stack', title: '教育和通信服务' },
                {code: 'US_CPI_MONTH_EFFECT_OTHER_PERSONAL_SERVICES', type: 'column', stack: 'stack' } ],"type":'column',"show-value":true,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":1}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'US 二手车价值指数',"indicators":[
                { code: 'MANHEIM_USED_VEHICLE_VALUE_INDEX', yAxis: 'left', },
                { code: 'MANHEIM_USED_VEHICLE_VALUE_INDEX_MOM', yAxis: 'right', },
                { code: 'US_CPI_MoM_L3_Used_cars_and_trucks', yAxis: 'right', }
              ],"show-value":false,"legend":null,"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":1}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'Pending Home Sales',"indicators":[
                { code: 'US_PENDING_HOME_SALES_INDEX', yAxis: 'left', type: 'column', },
                { code: 'US_PENDING_HOME_SALES_INDEX_MOM', yAxis: 'right', },
                { code: 'US_PENDING_HOME_SALES_INDEX_YOY', yAxis: 'right', } ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":2}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'Gas Price',"indicators":[
                { code: 'EMM_EPMRU_PTE_NUS_DPG', },
                { code: 'EMM_EPMRU_PTE_R10_DPG' },
                { code: 'EMM_EPMRU_PTE_R20_DPG' },
                { code: 'EMM_EPMRU_PTE_R30_DPG' },
                { code: 'EMM_EPMRU_PTE_R40_DPG' },
                { code: 'EMM_EPMRU_PTE_R50_DPG' } ],"type":'column',"show-value":true,"legend":'center',"rangeSelector":['1y', '3y', '5y', 'All'],"rangeSelectorSelected":2}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'PMI',"indicators":[{code: 'US_ISM_MANUFACTURING_PMI', }, { code: 'US_ISM_SERVICES_PMI' },
                { code: 'US_ISM_MANUFACTURING_NEW_ORDERS_INDEX', yAxis: 'right', type: 'line', color: 'orange', } ],"type":'column',"show-value":true,"legend":'center',"rangeSelector":['1y', '3y', '5y', 'All'],"rangeSelectorSelected":2}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"66.7%","height":"400px"},attrs:{"title":' Global PMI',"indicators":[
                { code: 'US_ISM_MANUFACTURING_PMI', },
                { code: 'US_ISM_SERVICES_PMI' },
                { code: 'CN_CAIXIN_MANUFACTURING_PMI' },
                { code: 'CN_CAIXIN_SERVICE_PMI' },
                { code: 'EU_MANUFACTURING_PMI' },
                { code: 'EU_SERVICE_PMI' },
                { code: 'GE_MANUFACTURING_PMI' },
                { code: 'GE_SERVICE_PMI' },
                { code: 'JP_MANUFACTURING_PMI' },
                { code: 'JP_SERVICE_PMI' } ],"type":'line',"show-value":true,"legend":'center',"rangeSelector":['1y', '3y', '5y', 'All'],"rangeSelectorSelected":2}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'就业',"indicators":[ {
                code: 'US_UNEMPLOYMENT_RATE',
              }, {
                code: 'US_NONFARM_PAYROLL_TOTAL', yAxis: 'right', type: 'line',
              } ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":3}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'就业',"indicators":[ {
                code: 'US_NONFARM_PAYROLL_TOTAL', yAxis: 'left', type: 'line',
              }, {
                code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE', yAxis: 'right', type: 'line',
              } ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":3}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'就业',"indicators":[ {
                code: 'US_UNEMPLOYMENT_RATE',
              }, {
                code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE', yAxis: 'right', type: 'line',
              } ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":3}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"66.6%","height":"400px"},attrs:{"title":'Nonfarm Payroll Components',"indicators":[
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_TOTAL', type: 'line', },
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_MINING_AND_LOGGING', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_CONSTRUCTION', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_MANUFACTURING', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_M_CHANGE_TRADE_TRANSPORTATION_AND_UTILITIES', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_INFORMATION', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_FINANCIAL_ACTIVITIES', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_M_CHANGE_PROFESSIONAL_AND_BUSINESS_SERVICES', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_EDUCATION_AND_HEALTH_SERVICES', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_LEISURE_AND_HOSPITALITY', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_OTHER_SERVICES', type: 'column', stack: 'stack' },
                {code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_GOVERNMENT', type: 'column', stack: 'stack' } ],"show-value":true,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":1}}),_c('IndicatorPieChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'参与率',"indicators":[ {
                code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_WOMEN',
              }, {
                code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_MEN',
              }, {
                code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_16_19', yAxis: 'right'
              }, {
                code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_20_24',
              }, {
                code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_25_54',
              }, {
                code: 'US_NONFARM_PAYROLL_PARTICIPATION_RATE_55_OVER', yAxis: 'right'
              } ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":3}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'失业率-人群',"indicators":[ {
                code: 'US_UNEMPLOYMENT_RATE_OVER20_WOMEN',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_OVER20_WOMEN_WHITE',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_OVER20_WOMEN_BLACK_OR_AFRICAN',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_OVER20_WOMEN_HISPANIC_OR_LATINO',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_OVER20_MEN',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_OVER20_MEN_WHITE',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_OVER20_MEN_BLACK_OR_AFRICAN',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_OVER20_MEN_HISPANIC_OR_LATINO',
              } ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":3}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33.3%","height":"400px"},attrs:{"title":'失业率-教育',"indicators":[ {
                code: 'US_UNEMPLOYMENT_RATE_LESS_THAN_A_HIGH_SCHOOL_DIPLOMA_OVER25',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_HIGH_SCHOOL_GRADUATES_NO_COLLEGE_OVER25',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_SOME_COLLEGE_OR_ASSOCIATE_DEGREE_OVER25',
              }, {
                code: 'US_UNEMPLOYMENT_RATE_BACHELOR_DEGREE_AND_HIGHER_OVER25',
              } ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":3}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33%","height":"350px"},attrs:{"title":'收入 & 消费',"indicators":[
                { code: 'US_PERSONAL_SPENDING_MOM', type: 'column', yAxis: 'left', },
                { code: 'US_PERSONAL_INCOME_MOM', type: 'column',  yAxis: 'left', } ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":0}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33%","height":"350px"},attrs:{"title":'储蓄率',"indicators":[
                { code: 'US_PERSONAL_SAVINGS_RATE', type: 'column', }
              ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":2}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33%","height":"350px"},attrs:{"title":'实际利率(TIPS)',"indicators":[ {
                code: 'US5YTIP',
              }, {
                code: 'US10YTIP',
              } ],"show-value":false,"legend":null,"rangeSelector":['3m', '1y', '3y', '5y', 'All'],"rangeSelectorSelected":0}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33%","height":"350px"},attrs:{"title":'实际利率(Cleveland Fed)',"indicators":[ {
                code: 'CLEVELANDFED_REAL_RATE_1M',
              }, {
                code: 'CLEVELANDFED_REAL_RATE_1Y',
              }, {
                code: 'CLEVELANDFED_REAL_RATE_10Y',
              } ],"show-value":false,"legend":null,"rangeSelector":['1y', '2y', '3y', '5y', '10y', 'All'],"rangeSelectorSelected":3}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33%","height":"350px"},attrs:{"title":'通胀预期(by TIPS)',"indicators":[ {
                code: 'US10YTIP_INFLATION_EXP', yAxis: 'left',
              }, {
                code: 'US5YTIP_INFLATION_EXP', yAxis: 'left',
              }, {
                code: 'CLEVELANDFED_1Y_INFLATION_EXP', yAxis: 'left',
              } ],"show-value":false,"legend":null,"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":0}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"40%","height":"400px"},attrs:{"title":'Fed Liquidity and Bitcoin',"indicators":[
                {code: 'FED_BALANCE_SHEET', yAxis: 'left', },
                {code: 'FED_LIQUIDITY', yAxis: 'left', },
                {code: 'BITCOIN_PRICE', yAxis: 'right',}],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":1}}),_c('div',{staticClass:"chart marginlr roundcorner",staticStyle:{"width":"60%"}},[_c('SomaSummary')],1)],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"40%","height":"400px"},attrs:{"title":'新房成交',"show-value":false,"indicators":[
                {code: 'BEIJING_NEW_HOUSE_SALES', type: 'line', },
                {code: 'SHANGHAI_NEW_HOUSE_SALES', type: 'line', },
                {code: 'GUANGZHOU_NEW_HOUSE_SALES', type: 'line', },
                {code: 'SHENZHEN_NEW_HOUSE_SALES', type: 'line', },
                {code: 'HANGZHOU_NEW_HOUSE_SALES', type: 'line', },
                {code: 'NANJING_NEW_HOUSE_SALES', type: 'line', },
                {code: 'SUZHOU_NEW_HOUSE_SALES', type: 'line', },
                {code: 'CHENGDU_NEW_HOUSE_SALES', type: 'line', }
              ],"legend":'center',"rangeSelector":['1y', '3y', '5y', 'All'],"rangeSelectorSelected":1}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"30%","height":"400px"},attrs:{"title":null,"show-value":false,"indicators":[ {
                code: 'CN_ABLE_TO_BREED_SOWS',
                yAxis: 'left',
                type: 'line',
              }, {
                code: 'CN_ZHUWANG_PORK_CORN_PRICE_RATE',
                yAxis: 'right',
                type: 'line',
              }, {
                code: 'CN_ABLE_TO_BREED_SOWS_REDUCTION',
                yAxis: 'left',
                type: 'line',
                sideChart: true,
              } ],"legend":'center',"rangeSelector":['1y', '3y', '5y', 'All'],"rangeSelectorSelected":1}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"30%","height":"400px"},attrs:{"title":null,"show-value":false,"indicators":[ {
                code: 'CN_PORK_WHOLESALE_PRICE',
                yAxis: 'left',
                type: 'line',
              }, {
                code: 'CN_ZHUWANG_PORK_PRICE',
                yAxis: 'left',
                type: 'line',
              }, {
                code: 'CN_ABLE_TO_BREED_SOWS_RECIPROCAL',
                yAxis: 'right',
                type: 'line',
              } ],"legend":'center',"rangeSelector":['1y', '3y', '5y', 'All'],"rangeSelectorSelected":1}})],1),_c('div',{staticClass:"flxrow",staticStyle:{"width":"100%","margin":"4px 4px"}},[_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33%","height":"400px"},attrs:{"title":'货币增速',"indicators":[{code: 'CN_M0_YOY'}, {code: 'CN_M1_YOY'}, {code: 'CN_M2_YOY'}, {code: 'CN_M1_M2_YOY_DIFF', type: 'column'}],"show-value":false,"legend":'center',"rangeSelector":['1y', '3y', '5y', 'All'],"rangeSelectorSelected":2}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33%","height":"400px"},attrs:{"title":'社融',"indicators":[{code: 'CN_AFRE_FLOW', type: 'column', yAxis: 'left'}, {code: 'CN_AFRE_STOCK_YOY', yAxis: 'right'}],"show-value":false,"legend":'center',"rangeSelector":['1y', '3y', '5y', 'All'],"rangeSelectorSelected":2}}),_c('IndicatorChart',{staticClass:"marginlr",staticStyle:{"width":"33%","height":"400px"},attrs:{"title":'贷款 & 存款',"indicators":[
                {code: 'CN_HOUSEHOLD_FIX_DEPOSIT_ADD', type: 'column', },
                {code: 'CN_HOUSEHOLD_LONG_DEBT_ADD', type: 'column', },
                {code: 'CN_ENTERPRISE_LONG_DEBT_ADD', type: 'column', }
              ],"show-value":false,"legend":'center',"rangeSelector":['1y', '2y', '3y', '5y', 'All'],"rangeSelectorSelected":1}})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"4px 4px 4px 4px","display":"flex","align-items":"center"},attrs:{"name":"pageHead"}},[_c('p',{staticStyle:{"font-size":"26px","font-weight":"400","margin":"8px 0px","color":"#515A6E"}},[_vm._v(" US Macro Data ")])])}]

export { render, staticRenderFns }