<template>
  <div id="body">

    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            Streams
          </p>
          <!-- head buttons -->
          <div style="margin-left: auto;">
            <el-button size="mini" type="primary" round @click="showAddForm">+ Stream</el-button>
          </div>
        </div>

        <!-- row -->
        <div class="mr-row">
          <div class="mr-chart-common">
            <el-table :data="streamRowData" stripe style="width: 100%">
              <el-table-column
                  v-for="{ key, title} in columns"
                  :prop="key"
                  :label="title"
                  :key="key"/>

              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button @click="gotoStreamDetail(scope.row._id)" type="text" size="small">
                    查看
                  </el-button>
                  <el-button @click="showUpdateForm(scope.row)" type="text" size="small">
                    编辑
                  </el-button>
                  <el-button @click="submitDeleteStream(scope.row._id)" type="text" size="small">
                    删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Stream form modal -->
    <el-dialog :title="formTitle" :visible.sync="formDialogVisible">
      <el-form :model="formData" ref="streamForm" :rules="formValidRules"
          label-position="left" label-width="80px" size="mini">
        <el-form-item label="Name" prop="name">
          <el-input v-model="formData.name" autocomplete="off" placeholder="enter stream name">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeForm">取 消</el-button>
        <el-button type="primary" size="small" @click="submitAddOrUpdate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import {
  pageQueryStreams, createStream, updateStream, deleteStream,
} from '@/script/StreamOperations';
import { timestampToFormatDateTime } from '@/script/DateTools';

export default {
  name: 'Streams',
  components: { },
  data() {
    return {
      formDialogVisible: false,
      columns: [
        { key: 'name', title: '名称' },
        { key: 'creator', title: '创建者' },
        { key: 'gmt_create', title: '创建时间' },
      ],
      streamRowData: [],
      formTitle: 'Create New Repo',
      formData: {
        _id: '',
        name: '',
      },
      formValidRules: {
        name: [
          { required: true, message: 'Name cannot be empty', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    queryStreamsData() {
      pageQueryStreams({}).then((data) => {
        const rowData = [];
        data.data.items.forEach((it) => {
          const newItem = { ...it };
          newItem.gmt_create = timestampToFormatDateTime(newItem.gmt_create);
          rowData.push(newItem);
        });
        this.streamRowData = rowData;
      });
    },
    showAddForm() {
      this.formTitle = 'Create New Stream';
      this.formData._id = '';
      this.formDialogVisible = true;
    },
    showUpdateForm(row) {
      this.formTitle = 'Update Stream';
      this.formData = row;
      this.formDialogVisible = true;
    },
    closeForm() {
      this.formDialogVisible = false;
      this.$refs.streamForm.resetFields();
      this.formData._id = '';
    },
    submitAddOrUpdate() {
      this.$refs.streamForm.validate((valid) => {
        if (valid) {
          if (this.formData._id) {
            updateStream(this.formData).then((data) => {
              console.log(data);
              this.closeForm();
              this.$message({ message: data.message, type: 'success' });
              this.queryStreamsData();
            });
          } else {
            createStream(this.formData).then((data) => {
              console.log(data);
              this.closeForm();
              this.$message({ message: data.message, type: 'success' });
              this.queryStreamsData();
            });
          }
        }
      });
    },
    submitDeleteStream(streamId) {
      if (window.confirm('Are you sure to delete?')) {
        deleteStream({ _id: streamId }).then((data) => {
          this.$message({ message: data.message, type: 'success' });
          this.queryStreamsData();
        });
      }
    },
    gotoStreamDetail(streamid) {
      this.$router.push(`/streamdetail/${streamid}`);
    },
  },
  mounted() {
    this.queryStreamsData();
  },
};
</script>
