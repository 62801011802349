<template>
  <div>
    <div v-if="content" v-html="content"> </div>
  </div>
</template>

<script>
import { doGet } from '@/script/ServerTools';

export default {
  name: 'WordParaphrase',
  props: ['word'],
  components: { },
  data() {
    return {
      content: 'Please input a word to query',
    };
  },
  methods: {
  },
  watch: {
    word(val) {
      this.content = 'searching...';
      doGet('/api/dictionary/search', { word: val, }).then((rsp) => {
        this.content = rsp.data;
      });
    },
  },
};
</script>

<style>
.dphrase-block {
    margin-bottom: 10px;
    padding: 10px;
    background: #ddfaff;
}
.dpos-h_hw {
    margin: 5px 0;
    font-size: 36px;
    color: #1d2a57;
    font-weight: 400
}

.dpos-h_hw b {
    font-weight: 400
}
.tc-bd, .tc-bd a {
    color: #1d2a57!important
}
a.tc-bd {
    color: #1d2a57
}
.dpos-g {
    margin-bottom: 5px
}

.dpos-g .dpos {
    font-size: 14px;
    font-weight: bold;
    font-style: italic
}
.dsense_h {
    margin-bottom: 12px;
    padding-top: 10px;
    color: #5d2fc1;
    font-size: 1rem!important;
    font-weight: 700;
}
.dsense_gw {
    font-size: 14px;
    text-transform: uppercase;
}
.ddef_d {
    margin-top: 10px;
    font-size: 18px;
    line-height: 1.5;
}
.db {
    font-weight: 700;
}
.dtrans {
    color: #0580e8;
}
.ddef_b>.trans {
    font-size: 17px;
}
.dlab, .dgram, .ddomain, .duse, .dx-lab {
    font-size: .75rem;
}
.dreg {
    margin-right: 5px;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase;
}
.break-cj {
    word-break: break-word;
}
.dexamp {
    position: relative;
    padding-left: 12px;
    display: block
}
.daccord_b .dexamp, .ddef_b .dexamp {
    margin-right: 22px;
    position: relative;
    font-size: 17px;
    margin-bottom: 10px;
    line-height: 1.4;
}
.dexamp::before {
    content: "•";
    position: absolute;
    top: 0;
    left: 0
}
.dexamp .eg, .dexamp.eg {
    padding-right: 15px;
    font-style: italic;
    vertical-align: initial;
}
.dwl {
    position: relative;
    margin-top: 2px;
    border-top: solid thin #fec400;
}
.hax, .hax:hover, .hax a, .hax a:hover {
    text-decoration: none;
}
.daccord {
    margin: 10px 0;
    background: #fff8e4;
}
.fs16 {
    font-size: 1rem!important;
}
.bb {
    background: #ffe797;
}
.ddef_b>.dtrans-se {
    display: block;
    margin: 0px 0 20px;
}
.i-amphtml-element {
    display: inline-block;
}
.i-amphtml-blurry-placeholder, .i-amphtml-fill-content {
    display: block;
    height: 0;
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
    width: 0;
    margin: auto;
}
audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
}
.i-volume-up:before {
    content: "\f028"
}
.i-caret-right:before {
    content: "\f0da";
}
.i {
    display: inline-block;
    font: normal normal normal 1rem/1 ico-c;
    color: #292929;
    vertical-align: middle;
    text-rendering: auto;
    webkit-font-smoothing: antialiased;
    moz-osx-font-smoothing: grayscale
}
.i,
.i:before {
    speak: none
}
.c_aud {
    line-height: 26px
}

.c_aud audio {
    opacity: 0;
    width: 100px;
    max-width: none;
    margin-left: -15px
}
.htc {
    text-align: center
}
.hdib {
    display: inline-block
}
.hp {
    cursor: pointer
}
.hv-1 {
    vertical-align: -1px
}
.fon {
    outline: 0
}
.tcu {
    text-transform: uppercase
}
.tc-bd,
.tc-bd a {
    color: #1d2a57
}
.lmr-10 {
    margin-right: 10px
}
.lpt-3 {
    padding-top: 3px
}
</style>