import { doPost, } from './ServerTools';

export function follow(_id) {
  return doPost('/api/ma5short/follow', { _id });
}

export function toBuy(_id) {
  return doPost('/api/ma5short/tobuy', { _id });
}

export function choice(_id) {
  return doPost('/api/ma5short/choice', { _id });
}

export function trendGoFar(_id) {
  return doPost('/api/ma5short/trendgofar', { _id });
}

export function buy(_id, price, date, ratio, amount) {
  return doPost('/api/ma5short/buy', {
    _id,
    price,
    date,
    ratio,
    amount
  });
}

export function sell(_id, price, date) {
  return doPost('/api/ma5short/sell', {
    _id,
    price,
    date,
  });
}

export function close(_id, opState) {
  return doPost('/api/ma5short/close', {
    _id,
    opState,
  });
}

const MA5ShortStrategyAPIs = { follow, choice, trendGoFar, toBuy, buy, sell, close };
export default MA5ShortStrategyAPIs;
