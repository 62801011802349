<template>
  <div class="view flxcolumn">
    <div class="bt-sd" style="width: 100%; padding: 18px 12px 8px 12px;">
      <div style="margin: 0px; display: flex; align-items: center;">
        <!--
        <h4> {{ docName }} </h4>
        -->
        <div style="margin-left: auto; min-width: 120px;">
          <button v-if="!editMode" class="btn btn-p1 bg-gradient-dark" @click="onStartEdit" >Edit</button>
          <button v-else class="btn btn-p1 bg-gradient-dark" @click="onEndEdit" >Save</button>
          <!-- delete button -->
          <a-popconfirm
            title="Are you sure delete this?"
            ok-text="Yes" cancel-text="No"
            @confirm="onDelete" >
            <button v-if="!editMode" class="btn btn-p1 bg-gradient-danger" style="margin-left: 8px;"
              >Delete</button>
          </a-popconfirm>
        </div>
      </div>

      <!-- Create toolbar container -->
      <EditorToolbar v-if="editMode" id="toolbar"></EditorToolbar>
      <!-- toolbar end -->
    </div>
    <!-- header end -->

    <!-- content start -->
    <div class="main">
      <div v-if="!editMode" v-html="content" class="ql-editor"> </div>
      <mr-quill-editor v-else v-model="content" />
    </div>
    <!-- content end -->
  </div>
</template>

<script>
import {
  queryDocumentById, saveDocumentContent,
} from '@/script/DocOperations';
import MrQuillEditor from '@/components/MrQuillEditor.vue';
import EditorToolbar from './EditorToolbar.vue';
import { doPost, doGet } from '@/script/ServerTools';

export default {
  name: 'DocEditor',
  components: { MrQuillEditor, EditorToolbar, },
  props: [ 'docId', ],
  data() {
    return {
      editMode: false,
      doc: { },
      docName: '',
      content: '',
    };
  },
  methods: {
    onStartEdit() {
      this.editMode = true;
    },
    onEndEdit() {
      this.editMode = false;
      this.saveTradeLogic({
        _id: this.docId,
        content: this.content,
      });
    },
    onDelete() {
    },
    loadDoc() {
      queryDocumentById(this.docId).then((data) => {
        this.doc = data.data;
        this.docName = this.doc.name;
        this.content = this.doc.content;
      });
    },
    saveTradeLogic(data) {
      doPost('/api/doc/document/save_content', data).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
      });
    },
  },
  watch: {
    docId(val1, val2) {
      this.loadDoc();
    },
  },
  mounted() {
    this.loadDoc();
  },
};
</script>

<style>
.view {
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.header {
    flex-shrink: 0;
}
.main {
  height: 100%;
  overflow-y: scroll;
  flex: 1;
}
.ql-container {
  font-size: 1rem!important;
  color: #212529;
}
.ql-editor {
  font-size: 1rem!important;
  color: #212529;
}
.ql-editor p {
  margin-top: 6px!important;
  margin-bottom: 6px!important;
}
.ql-editor img {
  max-width: 100%;
}
.ql-snow{ border: none !important;}
.ql-size-large {
  font-size: 1.2rem !important;
}
li.ql-indent-1:not(.ql-direction-rtl) {
  padding-left: 2em!important;
}
.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.ql-editor ol > li, .ql-editor ul > li {
  list-style-type: none;
}
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 0.8em;
  padding-top: 2px;
  padding-bottom: 2px;
}
.ql-editor ul > li::before {
  content: '•';
  font-weight: bold;
}
.ql-editor ol, .ql-editor ul {
  padding-left: 0em;
}
.ql-editor pre.ql-syntax {
  overflow: visible;
}
.ql-editor pre {
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px 10px;
}
.ql-editor code, .ql-editor pre {
  background-color: #f0f0f0;
  border-radius: 3px;
}
.ql-editor blockquote {
  border-left: 4px solid #ccc;
  padding-left: 16px;
}
.ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  padding-left: 16px;
}
blockquote {
  background-color: #e3f2fd;
  border-left: 0.2rem solid #6495ed !important;
  padding: 12px 8px !important;
}
</style>
