<template>
  <div id="body">
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            Doc Repos
          </p>
          <!-- head buttons -->
          <div style="margin-left: auto;">
            <el-button size="mini" type="primary" round @click="showAddForm">+ Repo</el-button>
          </div>
        </div>

        <div class="container-layout">
          <div style="width: 100%;">
            <!-- chart start -->
              <div class="chart commargin" >
                <el-table :data="rowData" stripe style="width: 100%">
                  <el-table-column
                      v-for="{ key, title} in columns"
                      :prop="key"
                      :label="title"
                      :key="key"/>

                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button @click="gotoRepoDetail(scope.row._id)" type="text" size="small">
                        查看
                      </el-button>
                      <el-button @click="showUpdateForm(scope.row)" type="text" size="small">
                        编辑
                      </el-button>
                      <el-button @click="submitDeleteRepo(scope.row._id)" type="text" size="small">
                        删除
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add repo form modal -->
    <el-dialog :title="formTitle" :visible.sync="formDialogVisible">
      <el-form :model="repoFormData" ref="repoForm" :rules="repoFormValidRules"
          label-position="left" label-width="80px" size="mini">
        <el-form-item label="Name" prop="name">
          <el-input v-model="repoFormData.name" autocomplete="off" placeholder="enter repo name">
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeForm">取 消</el-button>
        <el-button type="primary" size="small" @click="submitAddOrUpdate">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  queryRepos, createRepo, updateRepo, deleteRepo,
} from '@/script/DocOperations';
import { timestampToFormatDateTime } from '@/script/DateTools';

export default {
  name: 'DocRepos',
  components: { },
  data() {
    return {
      columns: [
        { key: 'name', title: '名称' },
        { key: 'creator', title: '创建者' },
        { key: 'gmt_create', title: '创建时间' },
      ],
      rowData: [
        { name: '' },
      ],
      formTitle: 'Add New Repo',
      formDialogVisible: false,
      repoFormData: {
        _id: '',
        name: '',
      },
      repoFormValidRules: {
        name: [
          { required: true, message: 'Name cannot be empty', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    queryRepoTableData() {
      queryRepos().then((data) => {
        const rowData = [];
        data.data.items.forEach((it) => {
          const newItem = { ...it };
          newItem.gmt_create = timestampToFormatDateTime(newItem.gmt_create);
          rowData.push(newItem);
        });
        this.rowData = rowData;
      });
    },
    showAddForm() {
      this.formTitle = 'Create New Repo';
      /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
      this.repoFormData._id = '';
      this.formDialogVisible = true;
    },
    showUpdateForm(repo) {
      console.log('========== showUpdateForm ========');
      console.log(repo);
      this.formTitle = 'Update Repo';
      this.repoFormData = repo;
      this.formDialogVisible = true;
    },
    closeForm() {
      this.formDialogVisible = false;
      this.$refs.repoForm.resetFields();
      /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
      this.repoFormData._id = '';
    },
    gotoRepoDetail(repoid) {
      this.$router.push(`/repodetail/${repoid}`);
    },
    submitAddOrUpdate() {
      this.$refs.repoForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          // UPDATE
          /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
          if (this.repoFormData._id) {
            updateRepo(this.repoFormData).then((data) => {
              console.log(data);
              this.closeForm();
              this.$message({ message: data.message, type: 'success' });
              this.queryRepoTableData();
            });
          } else {
            // ADD
            createRepo(this.repoFormData).then((data) => {
              console.log(data);
              this.closeForm();
              this.$message({ message: data.message, type: 'success' });
              this.queryRepoTableData();
            });
          }
        }
      });
    },
    submitDeleteRepo(repoId) {
      if (window.confirm('Are you sure to delete?')) {
        deleteRepo({ _id: repoId }).then((data) => {
          this.$message({ message: data.message, type: 'success' });
          this.queryRepoTableData();
        });
      }
    },
  },
  mounted() {
    this.queryRepoTableData();
  },
};
</script>
