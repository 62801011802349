<template>
  <!-- main layout -->
  <div class="mr-main">
      <!-- row one -->
    <div class="mr-card" style="margin-left: -4px; margin-right: -4px; height: 300px">
      <div class="mr-scroll-container" style="flex: 0 0 15%;">
        <common-table class="mr-chart-common" :options="testData" />
      </div>
      <div class="mr-scroll-container" style="flex: 0 0 15%;">
        <common-table class="mr-chart-common" :options="testData" />
      </div>
      <highcharts class="mr-chart-common"
          :constructor-type="'stockChart'"
          :options="{}">
      </highcharts>
    </div>
  </div>
</template>

<script>
import CommonTable from '@/components/CommonTable.vue';

export default {
  name: 'Scanners',
  components: {
    CommonTable,
    // CandleStick,
  },
  data() {
    return {
      testData: {
        columns: [
          { code: 'value1', name: '1' },
          { code: 'value2', name: '2' },
        ],
        rows: [
          { rowKey: 1, value1: 'hohoho', value2: 'hahahaha' },
          { rowKey: 2, value1: 'hohoho', value2: 'hahahaha' },
          { rowKey: 4, value1: '图片图片', value2: 'hahahaha' },
          { rowKey: 5, value1: '图片图片', value2: 'hahahaha' },
          { rowKey: 6, value1: '图片图片', value2: 'hahahaha' },
          { rowKey: 7, value1: '图片图片', value2: 'hahahaha' },
          { rowKey: 8, value1: '图片图片', value2: 'hahahaha' },
          { rowKey: 9, value1: '图片图片', value2: 'hahahaha' },
          { rowKey: 10, value1: '图片图片', value2: 'hahahaha' },
          { rowKey: 11, value1: '图片图片', value2: 'hahahaha' },
          { rowKey: 12, value1: '图片图片', value2: 'hahahaha' },
        ],
      },
    };
  },
};
</script>

<style>

</style>
