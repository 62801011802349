<template>
  <div class="mr-main">
    <!--
    <div class="mr-row" style="height: 600px">
      -->
    <div class="mr-row" style="height: 500px; margin-left: -4px; margin-right: -4px">
      <!-- 指数列表 -->
      <div id="mr-chart-common index-list-panel">
        <div id="index-category-selection">
          <select style="width: 98%" v-model="selectedLevel">
            <option v-for="it in swLevelList" :key=it.code :value="it.code">
              {{ it.name }}
            </option>
          </select>
        </div>
        <CommonTable :options=indexListOptions />
      </div>
      <!-- 指数蜡烛图 -->
      <CandleStick class="mr-chart-common"
          :stockConfig='{ stockCode: selectedIndex.code, stockName: selectedIndex.name}'
          :options='options' :extremes='extremes'
          :quoteData='indexQuoteData'
          v-on:extremeChangeEvent='onChildExtremeChange'
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import CandleStick from '@/components/CandleStick.vue';
import CommonTable from '@/components/CommonTable.vue';
import { queryIndexQuotes } from '@/script/QuotesQuery';
import { backEndHost } from '@/script//ServerTools';

export default {
  name: 'Industry',
  components: {
    CandleStick, CommonTable,
  },
  data() {
    const self = this;
    return {
      selectedIndex: { name: '', code: '' },
      swLevelList: [
        { code: 'CSI', name: '中证指数' },
        { code: 'SSE', name: '上交所指数' },
        { code: 'SZSE', name: '深交所指数' },
        { code: 'CICC', name: '中金指数' },
        { code: 'SW', name: '申万指数' },
      ],
      selectedLevel: 'SW',
      // 指数列表配置
      indexListOptions: {
        tableData: [],
        columnDef: [
          { code: 'name', name: '行业' },
          { code: 'above_or_below', name: '状态' },
          { code: 'days', name: '天数' },
        ],
        showHeader: false,
        keyAttr: 'code',
        selectCallback: self.onSelectIndex,
      },
      options: {
        showRecentLowHigh: false,
        showNavigator: false,
      },
      extremes: [null, null, ''],
      indexQuoteData: {},
      indexAnalysisTable: [],
      indexAnalysisColumns: [
        { code: 'name', name: '行业' },
      ],
      indexAnalysisKeyAttr: 'name',
    };
  },
  watch: {
    selectedLevel(newValue) {
      this.querySwIndexes(newValue);
    },
  },
  methods: {
    onChildExtremeChange(e) {
      console.log('==== onChildExtremeChange ====', e);
      // this.extremes = e;
    },
    onSelectIndex(data) {
      this.selectedIndex = data;
      queryIndexQuotes(data.code).then((indexQuotes) => {
        if (indexQuotes !== null) {
          this.indexQuoteData = indexQuotes;
        }
      });
    },
    querySwIndexes(market) {
      let path = `${backEndHost()}/api/quotes/index_list`;
      path = `${path}?market=${market}`;
      axios.get(path).then((result) => {
        const resultList = [];
        result.data.data.forEach((it) => {
          let bgColor = '#e9e9e9';
          if (it.above_or_below === 'above') {
            bgColor = '#fde3cf';
          }
          if (it.above_or_below === 'below') {
            bgColor = '#d2eafb';
          }
          resultList.push({
            code: it.code,
            name: it.name,
            above_or_below: it.above_or_below,
            days: it.days,
            bg_color: bgColor,
          });
        });
        this.indexListOptions.tableData = resultList;
        if (this.indexListOptions.tableData.length > 0) {
          this.onSelectIndex(this.indexListOptions.tableData[0]);
        }
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  mounted() {
    this.querySwIndexes('SW');
  },
};
</script>

<style>
#index-list-panel {
  width: 250px;
  height: 100%;
  background: #e9e9e9;
  display: flex;
  flex-direction: column;
}
#index-category-selection {
  flex-basis: 30px;
}
</style>
