<template>
  <div class="flxcolumn body roundcorner">
    <div class="title">
      Yield Curve
      <DataSource :sources="sources" />
    </div>
    <highcharts
        class="flx1"
        :options="chartOptions"
        style="height: 300px"
      />

  </div>
</template>

<script>
import moment from 'moment';
import { doPostN, doGetN, } from '@/script/ServerTools';
import DataSource from '@/components/charts/DataSource.vue';

export default {
  name: 'YieldCurve',
  components: { DataSource, },
  data() {
    return {
      sources: [ 'https://www.cnbc.com/quotes/US10Y?qsearchterm=US10Y', ],
      categories: ['today', '1Dago', '2Dago', '3Dago', '1Wago', '2Wago', '3Wago', '1Mago', '2Mago'],
      series: [ ],
      colors: [
        '#c41d7f',
        // '#002c8c',
        '#003eb3',
        '#0958d9',
        '#1677ff',
        '#4096ff',
        '#69b1ff',
        '#91caff',
        '#bae0ff',
      ],
      recordDate: null,
      recordContent: '',
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: { type: 'line' },
        title: { text: null },
        xAxis: {
          categories: ['3M', '6M', '1Y', '2Y', '3Y', '5Y', '7Y', '10Y', '20Y'],
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
        },
        yAxis: {
          allowDecimals: false,
          title: { text: null, }
        },
        tooltip: {
          xDateFormat: '%Y-%m-%d %H:%M',
          split: false,
          shared: true,
        },
        series: this.series,
        credits: { enabled: false, },
      };
    },
  },
  methods: {
    load() {
      doGetN('/api/morpho/yield_curve/compare', { }).then((rsp) => {
        this.series = [ ] ;
        let i = 0;
        this.categories.forEach((dateStr) => {
          const list = rsp.result[dateStr];
          const data = [ ];
          list.forEach((it) => {
            data.push([ it.indicatorCode.substring(2) , it.value ]);
          });
          this.series.push({ name: dateStr, data, color: this.colors[i], });
          i += 1;
        });
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  position: relative;
  min-height: 20px;
}
.record {
  position: absolute;
  left: 16px;
  top: 3px;
  font-size: 13px;
  z-index: 1000;
}
.body {
  padding: 8px;
  background-color: #ffffff;
}
</style>