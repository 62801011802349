<template>
  <div id="body">
    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; margin: 8px 0px; color: #515A6E">
            US Macro Data
          </p>
        </div>

        <!-- container layout start -->
        <div class="container-layout">
          <!-- 价格跟踪 start -->
          <div style="width: 100%; margin: 2px 4px;" class="flxrow">
            <div style="width: 200px; height: 80px" class="chart commargin">
            </div>
            <div style="width: 80px; height: 80px" class="chart commargin">
            </div>
            <div style="width: 80px; height: 80px" class="chart commargin">
            </div>
            <div style="width: 80px; height: 80px" class="chart commargin">
            </div>
          </div>
          <!-- 价格跟踪 end -->

          <!-- row 1 end -->
          <div style="width: 100%; height: 400px; margin: 0px 4px 4px 4px;" class="flxrow">
            <Common3DChart :data="deltaChartData"/>
          </div>
          <!-- row 1 end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doPostN, doGetN, } from '@/script/ServerTools';
import Common3DChart from '@/components/charts/Common3DChart.vue';

export default {
  name: 'OptionObserver',
  components: { Common3DChart },
  data() {
    return {
      delta: {},
      deltaChartData: [ ],
      chartOptions: {},
      chart: null,
    };
  },
  methods: {
    load(right) {
      doGetN('/api/morpho/option/get_latest_stats', { symbol: 'SPY', expire: '2023-03-24', right, limit: 40, }).then((rsp) => {
        const factor = right === 'CALL' ? 1 : -1;
        rsp.result.forEach((it) => {
          if (!(it.strike in this.delta)) {
            this.delta[it.strike] = [ ];
          }
          this.delta[it.strike].push([it.recordTimestamp * 1000, factor * it.delta]);
        });

        const deltaChartData = [ ];
        Object.entries(this.delta).forEach((entry) => {
          deltaChartData.push({
            name: entry[0],
            data: entry[1]
          });
        });
        deltaChartData.sort((a, b) => factor * (parseFloat(a.name) - parseFloat(b.name)) );
        this.deltaChartData = deltaChartData;
      });
    },
  },
  mounted() {
    this.load('PUT');
  },
};
</script>
