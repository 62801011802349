<template>
  <div id="scrolling-container">
    <div id="float-tooltip" ref="tooltipControls"
        v-show="tooltipVisible"
        :style="{left: tooltipLeft + 'px', top: tooltipTop + 'px'}">
      <div class="flxrow">
        <strong>Text: </strong>
        <div class="color-button" style="background-color: #000000" @click="setTextColor('#000000')"></div>
        <div class="color-button" style="background-color: #c00000" @click="setTextColor('#c00000')"></div>
        <div class="color-button" style="background-color: #ff0000" @click="setTextColor('#ff0000')"></div>
        <div class="color-button" style="background-color: #d8a200" @click="setTextColor('#ffc000')"></div>
        <div class="color-button" style="background-color: #ffff00" @click="setTextColor('#ffff00')"></div>
        <div class="color-button" style="background-color: #417c28" @click="setTextColor('#6aa84f')"></div>
        <div class="color-button" style="background-color: #03a003" @click="setTextColor('#00ba00')"></div>
        <div class="color-button" style="background-color: #0047FC" @click="setTextColor('#0047FC')"></div>
        <div class="color-button" style="background-color: #0066cc" @click="setTextColor('#0066cc')"></div>
        <div class="color-button" style="background-color: #002060" @click="setTextColor('#002060')"></div>
        <div class="color-button" style="background-color: #6f30a0" @click="setTextColor('#6f30a0')"></div>
      </div>
      <div class="flxrow">
        <strong>Backgroud: </strong>
        <div class="color-button" style="background-color: #ffffff" @click="setBackgroundColor('#ffffff')"></div>
        <div class="color-button" style="background-color: #dfbab1" @click="setBackgroundColor('#dfbab1')"></div>
        <div class="color-button" style="background-color: #eecdcd" @click="setBackgroundColor('#eecdcd')"></div>
        <div class="color-button" style="background-color: #f8e5d0" @click="setBackgroundColor('#f8e5d0')"></div>
        <div class="color-button" style="background-color: #fdf2d0" @click="setBackgroundColor('#fdf2d0')"></div>
        <div class="color-button" style="background-color: #dce9d5" @click="setBackgroundColor('#dce9d5')"></div>
        <div class="color-button" style="background-color: #d3dfe2" @click="setBackgroundColor('#d3dfe2')"></div>
        <div class="color-button" style="background-color: #ccdaf5" @click="setBackgroundColor('#ccdaf5')"></div>
      </div>
    </div>
    <div id="quill-container"></div>
  </div>
</template>

<script>
import Quill from 'quill';
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import "quill/dist/quill.snow.css";
import hljs from "highlight.js";
// import "highlight.js/styles/github.css";
// import "highlight.js/styles/monokai-sublime.css";

import axios from 'axios';
import ImageResize from 'quill-image-resize-module';
import ImageUploader from 'quill-image-uploader';
Quill.register('modules/ImageResize', ImageResize);
Quill.register('modules/imageUploader', ImageUploader);

export default {
  name: 'MrQuillEditor',
  props: { content: String, clear: Number, toolbarId: String, },
  model: {
    prop: 'content',
    event: 'contentChange',
  },
  data() {
    return {
      editor: null,
      qillOptions: {
        placeholder: 'Compose an epic...',
        modules: {
          toolbar: '#toolbar',
          // toolbar: [
          //   ['bold', 'italic', 'underline', 'strike'],
          //   ['blockquote', 'code-block'],
          //   [{ 'header': 1 }, { 'header': 2 }],
          //   [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          //   [{ 'script': 'sub' }, { 'script': 'super' }],
          //   [{ 'indent': '-1' }, { 'indent': '+1' }],
          //   [{ 'direction': 'rtl' }],
          //   [{ 'size': ['small', false, 'large', 'huge'] }],
          //   [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          //   [{ 'font': [] }],
          //   [{ 'color': [] }, { 'background': [] }],
          //   [{ 'align': [] }],
          //   ['clean'],
          //   ['link', 'image', 'video']
          // ],
          // syntax: {
          //   highlight: text => hljs.highlightAuto(text).value
          // },
          imageUploader: {
            upload: this.uploadImage,
          },
          ImageResize: {
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
            // handleStyles: {
            //     backgroundColor: 'black',
            //     border: 'none',
            //     color: white
            //     // other camelCase styles for size display
            // },
            // toolbarStyles: {
            //     backgroundColor: 'black',
            //     border: 'none',
            //     color: white
            //     // other camelCase styles for size display
            // },
          },
        },
      },
      tooltipVisible: false,
      tooltipLeft: 0,
      tooltipTop: 0,
      selectedText: null,
    };
  },
  watch: {
    clear(val, oldVal) {
      this.editor.on('text-change', () => {});
      this.editor.setText('');
      this.editor.on('text-change', () => this.update());
    },
  },
  methods: {
    onEditorBlur(quill) {
      console.log('editor blur!', quill);
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill);
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill);
    },
    onEditorChange({ quill, html, text }) {
      this.$emit('contentChange', html);
    },
    uploadImage(file) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        axios({
          url: '/api/image/upload',
          method: 'post',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then((rsp) => {
          resolve(rsp.data.data.url);
        });
      });
    },
    update() {
      this.$emit('contentChange', this.editor.getText() ? this.editor.root.innerHTML : '');
    },
    setTextColor(color) {
      if (this.selectedText != null) {
        this.editor.formatText(this.selectedText.index, this.selectedText.length, { 'color': color });
        this.tooltipVisible = false;
      }
    },
    setBackgroundColor(color) {
      if (this.selectedText != null) {
        this.editor.formatText(this.selectedText.index, this.selectedText.length, { 'background': color });
        this.tooltipVisible = false;
      }
    },
  },
  mounted() {
    this.editor = new Quill('#quill-container', {
      placeholder: 'Compose an epic...',
      modules: {
        toolbar: (this.toolbarId === null || this.toolbarId === undefined) ? '#toolbar' : `#${this.toolbarId}`,
        // toolbar: [
        //   [{ header: [1, 2, 3, 4, false] }],
        //   ['bold', 'italic', 'underline'],
        // ],
        imageUploader: {
          upload: this.uploadImage,
        },
        ImageResize: {
          modules: [ 'Resize', 'DisplaySize', 'Toolbar' ],
        },
        syntax: {
          highlight: (text) => hljs.highlightAuto(text).value
        },
      },
      scrollingContainer: '#scrolling-container',
      theme: 'snow',
      formats: [
        'background', 'bold', 'color', 'font',
        'code', 'italic', 'link', 'size', 'strike',
        'script', 'underline', 'blackquote', 'header',
        'indent', 'list', 'align', 'direction',
        'code-block', 'formula', 'image', 'video',
        'blockquote',
      ],
    });

    this.editor.root.innerHTML = this.content;
    this.editor.on('text-change', () => this.update());
    this.editor.on('selection-change', (range) => {
      if (range == null) {
        return;
      }
      const bounds = this.editor.getBounds(range);
      if (range && range.length > 0) {
        this.tooltipLeft = bounds.left + bounds.width / 2 - 100;
        this.tooltipTop = bounds.bottom + 10;
        this.tooltipVisible = true;
        this.selectedText = range;
      } else {
        this.tooltipVisible = false;
        this.selectedText = null;
      }
    });
    this.editor.getModule("toolbar").container.addEventListener("mousedown", (e) => {
      e.preventDefault();
    });
  },
};
</script>

<style>
/* Tell Quill not to scroll */
#quill-container {
  height: auto;
  min-height: 100%;
}
#quill-container .ql-editor {
  overflow-y: visible;
}
#scrolling-container {
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
  position: relative;
}
#float-tooltip {
  background-color: rgba(255, 255, 255, 1);
  border: 2px;
  border-color: black;
  border-radius: 4px;
  padding: 5px 10px;
  position: absolute;
  box-shadow: black 0px 0px 10px;
  z-index: 1000;
}
.color-button {
  width: 16px;
  height: 16px;
  margin: 3px;
  cursor: pointer;
}
.ql-snow .ql-editor pre.ql-syntax {
  background-color: rgb(31, 31, 31) !important;
}
</style>
