import { render, staticRenderFns } from "./MrIconButton.vue?vue&type=template&id=665e5bca&scoped=true&"
import script from "./MrIconButton.vue?vue&type=script&lang=js&"
export * from "./MrIconButton.vue?vue&type=script&lang=js&"
import style0 from "./MrIconButton.vue?vue&type=style&index=0&id=665e5bca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "665e5bca",
  null
  
)

export default component.exports