<template>
  <div>
    <highcharts
        :options="chartOptions"
        class="height100p"
      />
  </div>
</template>

<script>
export default {
  name: 'CommonChart',
  props: [ 'inhertParentHeight', 'title', 'data', 'yTitle', 'legend', 'xFormat', 'type', 'showValue', ],
  data() {
    return {
    };
  },
  computed: {
    chartOptions() {
      const newData = [ ...this.data ];
      let yAxisLeft = false;
      let yAxisRight = false;
      const yAxis = [ ];
      newData.forEach((it) => {
        if (it.yAxisSide === 'left') {
          yAxisLeft = true;
          it.yAxis = 0;
          it.marker = { enabled: false, };
        }
        if (it.yAxisSide === 'right') {
          yAxisRight = true;
          it.yAxis = 1;
          it.marker = { enabled: false, };
        }
      });
      if (yAxisLeft || (!yAxisLeft && !yAxisRight)) {
        yAxis.push({ title: null, opposite: false, });
      }
      if (yAxisRight) {
        yAxis.push({ title: null, opposite: true, });
      }

      const chartType = this.type ? this.type : 'line';
      const opts = {
        title: { text: null, },
        time: {
          useUTC: false,
        },
        chart: {
          type: chartType,
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            millisecond: this.xFormat === 'day' ? '%Y/%m/%d' : '%H:%M:%S.%L',
            second: this.xFormat === 'day' ? '%Y/%m/%d' : '%Y/%m/%d<br/>%H:%M:%S',
            minute: this.xFormat === 'day' ? '%Y/%m/%d' : '%Y/%m/%d<br/>%H:%M',
            hour: this.xFormat === 'day' ? '%Y/%m/%d' : '%Y/%m/%d<br/>%H:%M',
            day: '%Y/%m/%d',
            week: '%Y/%m/%d',
            month: '%Y-%m',
            year: '%Y',
          },
        },
        yAxis: yAxis,
        legend: {
          enabled: this.legend !== null,
          layout: this.legend === 'center' ? 'horizontal' : 'vertical',
          align: this.legend,
          verticalAlign: this.legend === 'center' ? 'top' : 'middle'
        },
        plotOptions: {
          series: {
            states: {
              inactive: {
                opacity: 1,
              }
            }
          }
        },
        tooltip: {
          xDateFormat: '%Y-%m-%d %H:%M',
          split: false,
          shared: true,
        },
        series: newData,
        credits: { enabled: false, },
      };
      if (this.showValue) {
        if (chartType === 'line') {
          opts.plotOptions.line = {
            dataLabels: { enabled: true },
          };
        }
        else if (chartType === 'bar') {
          opts.plotOptions.bar = {
            dataLabels: { enabled: true },
          };
        }
      }
      return opts;
    },
  },
};
</script>

<style>
.height100p {
  height: 100%;
}
</style>
