<template>
  <div class="button-basic"  @click="on_clicked" :style="{ 'fontSize': size }">
    <div class="button-child button-icon" >
      <b-icon v-if="icon" :icon="icon" />
    </div>
    <div v-if="icon && name" style="width: 4px;" />
    <span class="button-child" v-if="name" :style="{ 'fontSize': size }"> {{ name }} </span>
  </div>
</template>

<script>
export default {
  name: 'MrIconButton',
  props: [ 'name', 'icon', 'size' ],
  methods: {
    on_clicked(event) {
      this.$emit('click');
      event.stopPropagation();
    },
  },
};
</script>

<style scoped>
.button-basic {
  color: #007BFF;
  cursor: pointer;
  padding: 0px 6px 0px 6px;
  display: table;
}
.button-icon {
  margin: 0px 4px 0px 0px;
}
.button-child {
  display: table-cell;
  vertical-align: middle;
}
</style>
