<template>
  <div id="body">
    <!-- 页面内容 -->
    <div id='content'>
      <div class="mr-main">
        <!-- PAGE HEAD -->
        <div name="pageHead" style="margin: 4px 4px 4px 4px; display: flex; align-items: center;">
          <!-- table title -->
          <p style="font-size: 26px; font-weight: 400; color: '#515A6E'; margin: 8px 0px;">
            ShortTrend
          </p>
          <!-- head buttons -->
          <div style="margin-left: auto;">
            <el-button size="mini" type="primary" round @click="remote_startScan">Do Scan</el-button>
          </div>
        </div>

        <div class="container-layout">
          <div style="width: 70%;">
              <div class="chart">
                <div v-if="!candle.loading" style="height: 100%;">
                  <highcharts
                    style="height: 600px;"
                    :constructor-type="'stockChart'"
                    :options="candle.options"/>
                </div>
                <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
                  <div style="font-size: 24px; height: 600px">
                    <i class="el-icon-loading"></i>
                  </div>
                </div>
              </div>

              <div class="chart">
                <b-tabs >
                  <b-tab title="Break">
                    <div v-if="!breakData.loading" style="height: 100%; overflow-y: scroll;">
                      <b-table :fields="tableFields"
                          :items="breakRowItemProvider"
                          :current-page="breakData.currentPage"
                          :per-page="breakData.perPage"
                          :small="true"
                          :filter="breakData.filter"
                          :filter-included-fields="breakData.filterOn"
                          sort-by="industry"
                          selectable
                          select-mode="single"
                          @row-clicked="on_rowClicked"
                          @row-selected="on_rowSelected"
                          ref="breakTable"
                          >
                        <template v-slot:cell()="{ item, field: { key, unit } }">
                          {{ `${item[key]}${unit ? unit : '' }` }}
                        </template>
                        <template #cell(action)="data">
                          <div>
                            <span class="link-text"
                              @click="onFollowOp('close', { opState: 'CHECK' }, data.item, data.index, 'Break')"
                              > Check </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('follow', { }, data.item, data.index, 'Break')"
                              > Follow </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('choice', { }, data.item, data.index, 'Break')"
                              > Choice </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('tobuy', { }, data.item, data.index, 'Break')"
                              > To Buy </span>
                          </div>
                          <div>
                            <span class="link-text" style="margin-left: 0px"
                              @click="onFollowOp('trendgofar', { }, data.item, data.index, 'Break')"
                              > Trend Go Far </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('close', { opState: 'FAKE_TREND' }, data.item, data.index, 'Break')"
                              > Fake Trend </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('close', { opState: 'MISS' }, data.item, data.index, 'Break')"
                              > Miss </span>
                          </div>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="breakData.currentPage"
                        :total-rows="breakData.items.length"
                        :per-page="breakData.perPage"
                        align="right" size="sm"
                      ></b-pagination>
                    </div>
                    <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
                      <div style="font-size: 24px;">
                      <i class="el-icon-loading"></i>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Follow" @click="on_clickFollowTab">
                    <div v-if="!followData.loading" style="height: 100%; overflow-y: scroll;">
                      <b-table :fields="tableFields"
                          :items="followRowItemProvider"
                          :current-page="followData.currentPage"
                          :per-page="followData.perPage"
                          :small="true"
                          :filter="followData.filter"
                          :filter-included-fields="followData.filterOn"
                          sort-by="industry"
                          selectable
                          select-mode="single"
                          @row-clicked="on_rowClicked"
                          @row-selected="on_rowSelected"
                          ref="followTable"
                          >
                        <template v-slot:cell()="{ item, field: { key, unit } }">
                          {{ `${item[key]}${unit ? unit : '' }` }}
                        </template>
                        <template #cell(action)="data">
                          <div>
                            <span class="link-text" style="margin-left: 0px"
                              @click="onFollowOp('close', { opState: 'CHECK' }, data.item, data.index, 'Follow')"
                              > Check </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="passRow(data.index, 'Follow')"
                              > Pass </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('choice', { }, data.item, data.index, 'Follow')"
                              > Choice </span>
                          </div>
                          <div>
                            <span class="link-text" style="margin-left: 0px"
                              @click="onFollowOp('tobuy', { }, data.item, data.index, 'Follow')"
                              > To Buy </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('trendgofar', { }, data.item, data.index, 'Follow')"
                              > Trend Go Far </span>
                          </div>
                          <div>
                            <span class="link-text" style="margin-left: 0px"
                              @click="onFollowOp('close', { opState: 'FAKE_TREND' }, data.item, data.index, 'Follow')"
                              > Fake Trend </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('close', { opState: 'MISS' }, data.item, data.index, 'Follow')"
                              > Miss </span>
                          </div>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="followData.currentPage"
                        :total-rows="followData.items.length"
                        :per-page="followData.perPage"
                        align="right" size="sm"
                      ></b-pagination>
                    </div>
                    <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
                      <div style="font-size: 24px;">
                      <i class="el-icon-loading"></i>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Choice" @click="on_clickChoiceTab">
                    <div v-if="!choiceData.loading" style="height: 100%; overflow-y: scroll;">
                      <b-table :fields="tableFields"
                          :items="choiceRowItemProvider"
                          :current-page="choiceData.currentPage"
                          :per-page="choiceData.perPage"
                          :small="true"
                          :filter="choiceData.filter"
                          :filter-included-fields="choiceData.filterOn"
                          sort-by="industry"
                          selectable
                          select-mode="single"
                          @row-clicked="on_rowClicked"
                          @row-selected="on_rowSelected"
                          ref="choiceTable"
                          >
                        <template v-slot:cell()="{ item, field: { key, unit } }">
                          {{ `${item[key]}${unit ? unit : '' }` }}
                        </template>
                        <template #cell(action)="data">
                          <div>
                            <span class="link-text" style="margin-left: 0px"
                              @click="onFollowOp('follow', { }, data.item, data.index, 'Choice')"
                              > Follow </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="passRow(data.index, 'Choice')"
                              > Pass </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('tobuy', { }, data.item, data.index, 'Choice')"
                              > To Buy </span>
                          </div>
                          <div>
                            <span class="link-text" style="margin-left: 0px"
                              @click="onFollowOp('trendgofar', { }, data.item, data.index, 'Choice')"
                              > Trend Go Far </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('close', { opState: 'FAKE_TREND' }, data.item, data.index, 'Choice')"
                              > Fake Trend </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('close', { opState: 'MISS' }, data.item, data.index, 'Choice')"
                              > Miss </span>
                          </div>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="choiceData.currentPage"
                        :total-rows="choiceData.items.length"
                        :per-page="choiceData.perPage"
                        align="right" size="sm"
                      ></b-pagination>
                    </div>
                    <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
                      <div style="font-size: 24px;">
                      <i class="el-icon-loading"></i>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="To Buy" @click="on_clickToBuyTab">
                    <div v-if="!toBuyData.loading" style="height: 100%; overflow-y: scroll;">
                      <b-table :fields="tableFields"
                          :items="toBuyRowItemProvider"
                          :current-page="toBuyData.currentPage"
                          :per-page="toBuyData.perPage"
                          :small="true"
                          :filter="toBuyData.filter"
                          :filter-included-fields="toBuyData.filterOn"
                          sort-by="industry"
                          selectable
                          select-mode="single"
                          @row-clicked="on_rowClicked"
                          @row-selected="on_rowSelected"
                          ref="toBuyTable"
                          >
                        <template v-slot:cell()="{ item, field: { key, unit } }">
                          {{ `${item[key]}${unit ? unit : '' }` }}
                        </template>
                        <template #cell(action)="data">
                          <div>
                            <span class="link-text" style="margin-left: 0px"
                              @click="onFollowOp('choice', { }, data.item, data.index, 'ToBuy')"
                              > Choice </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="passRow(data.index, 'ToBuy')"
                              > Pass </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('trendgofar', { }, data.item, data.index, 'ToBuy')"
                              > Trend Go Far </span>
                          </div>
                          <div>
                            <span class="link-text" style="margin-left: 0px"
                              @click="onFollowOp('close', { opState: 'FAKE_TREND' }, data.item, data.index, 'ToBuy')"
                              > Fake Trend </span>
                            <span class="link-text" style="margin-left: 8px"
                              @click="onFollowOp('close', { opState: 'MISS' }, data.item, data.index, 'ToBuy')"
                              > Miss </span>
                          </div>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="toBuyData.currentPage"
                        :total-rows="toBuyData.items.length"
                        :per-page="toBuyData.perPage"
                        align="right" size="sm"
                      ></b-pagination>
                    </div>
                    <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
                      <div style="font-size: 24px;">
                      <i class="el-icon-loading"></i>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Bought" @click="on_clickBoughtTab">
                    <div v-if="!boughtData.loading" style="height: 100%; overflow-y: scroll;">
                      <b-table :fields="tableFields"
                          :items="boughtRowItemProvider"
                          :current-page="boughtData.currentPage"
                          :per-page="boughtData.perPage"
                          :small="true"
                          :filter="boughtData.filter"
                          :filter-included-fields="boughtData.filterOn"
                          sort-by="industry"
                          selectable
                          select-mode="single"
                          @row-clicked="on_rowClicked"
                          @row-selected="on_rowSelected"
                          ref="boughtTable"
                          >
                        <template v-slot:cell()="{ item, field: { key, unit } }">
                          {{ `${item[key]}${unit ? unit : '' }` }}
                        </template>
                        <template #cell(action)="">
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="boughtData.currentPage"
                        :total-rows="boughtData.items.length"
                        :per-page="boughtData.perPage"
                        align="right" size="sm"
                      ></b-pagination>
                    </div>
                    <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
                      <div style="font-size: 24px;">
                      <i class="el-icon-loading"></i>
                      </div>
                    </div>
                  </b-tab>
                  <b-tab title="Trend Go Far" @click="on_clickTrendGofarTab">
                    <div v-if="!goFarData.loading" style="height: 100%; overflow-y: scroll;">
                      <b-table :fields="tableFields"
                          :items="trendGoFarRowItemProvider"
                          :current-page="goFarData.currentPage"
                          :per-page="goFarData.perPage"
                          :small="true"
                          :filter="goFarData.filter"
                          :filter-included-fields="goFarData.filterOn"
                          sort-by="industry"
                          selectable
                          select-mode="single"
                          @row-clicked="on_rowClicked"
                          @row-selected="on_rowSelected"
                          ref="trendGoFarTable"
                          >
                        <template v-slot:cell()="{ item, field: { key, unit } }">
                          {{ `${item[key]}${unit ? unit : '' }` }}
                        </template>
                        <template #cell(action)="data">
                          <span class="link-text" style="margin-left: 8px"
                            @click="onFollowOp('tobuy', { }, data.item, data.index, 'Choice')"
                            > To Buy </span>
                          <span class="link-text" style="margin-left: 8px"
                            @click="onFollowOp('trendgofar', { }, data.item, data.index, 'Choice')"
                            > Trend Go Far </span>
                        </template>
                      </b-table>
                      <b-pagination
                        v-model="goFarData.currentPage"
                        :total-rows="goFarData.items.length"
                        :per-page="goFarData.perPage"
                        align="right" size="sm"
                      ></b-pagination>
                    </div>
                    <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
                      <div style="font-size: 24px;">
                      <i class="el-icon-loading"></i>
                      </div>
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>

          <div style="width: 30%">
              <div class="chart">
                <h4>{{ stockName }}</h4>
                <h5> 主营 </h5>
                {{ mainBusiness }}<br>
                <h5 style="margin-top: 0.5em;"> 业务范围 </h5>
                {{ businessScope }}
              </div>

              <div class="chart">
                <b-table :fields="revenueData.fields"
                    :items="revenueData.items"
                    :current-page="revenueData.currentPage"
                    :per-page="revenueData.perPage"
                    :small="true"
                    :filter="revenueData.filter"
                    :filter-included-fields="revenueData.filterOn"
                    sort-by="industry"
                    selectable
                    select-mode="single"
                    ref="revenueTable"
                    >
                  <template v-slot:cell()="{ item, field: { key, unit } }">
                    {{ `${item[key]}${unit ? unit : '' }` }}
                  </template>
                </b-table>
              </div>

              <div class="chart">
                <b-table :fields="debtToAssetData.fields"
                    :items="debtToAssetData.items"
                    :current-page="debtToAssetData.currentPage"
                    :per-page="debtToAssetData.perPage"
                    :small="true"
                    :filter="debtToAssetData.filter"
                    :filter-included-fields="debtToAssetData.filterOn"
                    sort-by="industry"
                    selectable
                    select-mode="single"
                    ref="debtToAssetTable"
                    >
                  <template v-slot:cell()="{ item, field: { key, unit } }">
                    {{ `${item[key]}${unit ? unit : '' }` }}
                  </template>
                </b-table>
              </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doPost, doGet } from '@/script/ServerTools';

export default {
  name: 'ShortTrend',
  components: { },
  data() {
    return {
      stockName: '',
      mainBusiness: '',
      businessScope: '',
      tableFields: [ {
        label: 'Industry', key: 'industry', sortable: true,
      }, {
        label: 'Name', key: 'name', sortable: true,
      }, {
        label: 'pe', key: 'peTtm', sortable: true,
      }, {
        label: 'pb', key: 'pb', sortable: true,
      }, {
        label: 'return', key: 'returnRate', unit: '%', sortable: true,
      }, {
        label: 'cap', key: 'totalCap', unit: '亿元', sortable: true,
      }, {
        label: 'debt', key: 'debtRatio', unit: '%', sortable: true,
      }, {
        label: 'Hint', key: 'hint', sortable: true,
      }, {
        label: 'Days', key: 'days', sortable: true,
      }, {
        label: 'Slope', key: 'trendSlope', sortable: true,
      }, {
        label: 'Action', key: 'action',
      } ],
      loadQuotesFinish: false,
      loadOpHistoryFinish: false,
      quotesTemp: null,
      opHistoryTemp: null,
      candle: {
        loading: false,
        options: {
        },
      },
      breakData: {
        items: [],
        currentPage: 1,
        perPage: 10,
        loading: false,
        filter: null,
        filterOn: [ ],
      },
      followData: {
        items: [],
        currentPage: 1,
        perPage: 10,
        loading: false,
        filter: null,
        filterOn: [ ],
      },
      choiceData: {
        items: [],
        currentPage: 1,
        perPage: 10,
        loading: false,
        filter: null,
        filterOn: [ ],
      },
      toBuyData: {
        items: [],
        currentPage: 1,
        perPage: 10,
        loading: false,
        filter: null,
        filterOn: [ ],
      },
      boughtData: {
        items: [],
        currentPage: 1,
        perPage: 10,
        loading: false,
        filter: null,
        filterOn: [ ],
      },
      goFarData: {
        items: [],
        currentPage: 1,
        perPage: 10,
        loading: false,
        filter: null,
        filterOn: [ ],
      },
      revenueData: {
        fields: [ {
          label: 'Date', key: 'date', sortable: true,
        }, {
          label: '业务', key: 'bizName', sortable: true,
        }, {
          label: '收入', key: 'bizIncome', sortable: true,
        }, {
          label: '利润', key: 'bizProfit', sortable: true,
        }, {
          label: '毛利', key: 'grossMargin', sortable: true, unit: '%',
        } ],
        items: [ ],
      },
      debtToAssetData: {
        fields: [ {
          label: 'Date', key: 'date', sortable: true,
        }, {
          label: '负债率', key: 'debtToAssets', sortable: true, unit: '%',
        } ],
        items: [ ],
      },
    };
  },
  methods: {
    remote_startScan() {
      doPost('/api/ma5short/doScan', {}).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
      });
    },
    on_clickFollowTab() {
      this.loadFollows();
    },
    on_clickChoiceTab() {
      this.loadChoices();
    },
    on_clickToBuyTab() {
      this.loadToBuy();
    },
    on_clickBoughtTab() {
      this.loadBought();
    },
    on_clickTrendGofarTab() {
      this.loadTrendGoFar();
    },
    on_rowClicked(row, index, e) {
      if (e.target.className === 'link-text') {
        return;
      }
      this.loadStock(row.code, row.name);
    },
    on_rowSelected() {
    },
    onFollowOp(op, params, row, index, tableName) {
      doPost(`/api/ma5short/${op}`, { _id: row._id, ...params }).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
        this.passRow(index, tableName);
      });
    },
    passRow(index, tableName) {
      console.log("passRow", tableName);
      if (tableName === 'Break') {
        const trueIndex = this.breakData.perPage * (this.breakData.currentPage - 1 ) + index;
        this.breakData.items.splice(trueIndex, 1);
        this.$refs.breakTable.refresh();
        setTimeout(() => {
          this.$refs.breakTable.selectRow(index);
        }, 100);
        this.on_rowClicked(this.breakData.items[trueIndex], index, { target: { className: '' } });
      }
      else if (tableName === 'Follow') {
        const trueIndex = this.followData.perPage * (this.followData.currentPage - 1 ) + index;
        this.followData.items.splice(trueIndex, 1);
        this.$refs.followTable.refresh();
        setTimeout(() => {
          this.$refs.followTable.selectRow(index);
        }, 100);
        this.on_rowClicked(this.followData.items[trueIndex], index, { target: { className: '' } });
      }
      else if (tableName === 'Choice') {
        const trueIndex = this.choiceData.perPage * (this.choiceData.currentPage - 1 ) + index;
        this.choiceData.items.splice(trueIndex, 1);
        this.$refs.choiceTable.refresh();
        setTimeout(() => {
          this.$refs.choiceTable.selectRow(index);
        }, 100);
        this.on_rowClicked(this.choiceData.items[trueIndex], index, { target: { className: '' } });
      }
      else if (tableName === 'ToBuy') {
        const trueIndex = this.toBuyData.perPage * (this.toBuyData.currentPage - 1 ) + index;
        this.toBuyData.items.splice(trueIndex, 1);
        this.$refs.toBuyTable.refresh();
        setTimeout(() => {
          this.$refs.toBuyTable.selectRow(index);
        }, 100);
        this.on_rowClicked(this.toBuyData.items[trueIndex], index, { target: { className: '' } });
      }
    },
    loadBreaks(callback) {
      this.breakData.loading = true;
      doGet('/api/ma5short/queryStateData', { state: 'BREAK', }).then((rsp) => {
        this.breakData.loading = false;
        this.breakData.items = rsp.data.data;
        if (callback) {
          callback();
        }
      });
    },
    loadFollows() {
      this.followData.loading = true;
      doGet('/api/ma5short/queryStateData', { state: 'FOLLOW', }).then((rsp) => {
        this.followData.loading = false;
        this.followData.items = rsp.data.data;
      });
    },
    loadChoices() {
      this.choiceData.loading = true;
      doGet('/api/ma5short/queryStateData', { state: 'CHOICE', }).then((rsp) => {
        this.choiceData.loading = false;
        this.choiceData.items = rsp.data.data;
      });
    },
    loadToBuy() {
      this.toBuyData.loading = true;
      doGet('/api/ma5short/queryStateData', { state: 'TO_BUY', }).then((rsp) => {
        this.toBuyData.loading = false;
        this.toBuyData.items = rsp.data.data;
      });
    },
    loadBought() {
      this.boughtData.loading = true;
      doGet('/api/ma5short/queryStateData', { state: 'BOUGHT', }).then((rsp) => {
        this.boughtData.loading = false;
        this.boughtData.items = rsp.data.data;
      });
    },
    loadTrendGoFar() {
      this.goFarData.loading = true;
      doGet('/api/ma5short/queryStateData', { state: 'TREND_GO_FAR', }).then((rsp) => {
        this.goFarData.loading = false;
        this.goFarData.items = rsp.data.data;
      });
    },
    rowItemProvider(ctx, items) {
      const start = (ctx.currentPage - 1) * ctx.perPage;
      let end = ctx.currentPage * ctx.perPage;
      if (start >= items.length) {
        return [];
      }
      if (end > items.length) {
        end = items.length;
      }
      if (ctx.sortBy !== undefined) {
        const compareValue = ctx.sortDesc === false ? 1 : -1;
        items.sort((a, b) => {
          if (a[ctx.sortBy] < b[ctx.sortBy]) {
            return -compareValue;
          }
          if (a[ctx.sortBy] > b[ctx.sortBy]) {
            return compareValue;
          }
          return 0;
        });
      }
      return items.slice(start, end);
    },
    breakRowItemProvider(ctx) {
      return this.rowItemProvider(ctx, this.breakData.items);
    },
    followRowItemProvider(ctx) {
      return this.rowItemProvider(ctx, this.followData.items);
    },
    choiceRowItemProvider(ctx) {
      return this.rowItemProvider(ctx, this.choiceData.items);
    },
    toBuyRowItemProvider(ctx) {
      return this.rowItemProvider(ctx, this.toBuyData.items);
    },
    boughtRowItemProvider(ctx) {
      return this.rowItemProvider(ctx, this.boughtData.items);
    },
    trendGoFarRowItemProvider(ctx) {
      return this.rowItemProvider(ctx, this.goFarData.items);
    },
    loadStock(code, name) {
      this.stockName = name;
      this.loadCanndle(code, name);
      this.loadCompanyIndo(code);
      this.loadRevenue(code);
      this.loadDebtToAsset(code);
    },
    loadCompanyIndo(code) {
      doGet('/api/cnStock/getCompanyInfo', { code }).then((rsp) => {
        const data = rsp.data;
        this.mainBusiness = data.mainBusiness;
        this.businessScope = data.businessScope;
      });
    },
    loadRevenue(code) {
      doGet('/api/cnStock/getBizRevenue', { code }).then((rsp) => {
        this.revenueData.items = rsp.data.data;
      });
    },
    loadDebtToAsset(code) {
      doGet('/api/cnStock/getDebtToAsset', { code }).then((rsp) => {
        this.debtToAssetData.items = rsp.data.data;
      });
    },
    loadCanndle(code, name) {
      this.loadQuotesFinish = false;
      this.loadOpHistoryFinish = false;
      this.candle.loading = true;
      doGet('/api/cnStock/getFullQuotes', { code }).then((rsp) => {
        this.loadQuotesFinish = true;
        this.quotesTemp = rsp.data;
        this.buildCandleData(name);
      });
      doGet('/api/ma5short/getOpHistory', { code }).then((rsp) => {
        this.loadOpHistoryFinish = true;
        this.opHistoryTemp = rsp.data;
        this.buildCandleData(name);
      });
    },
    buildCandleData(name) {
      if (!this.loadQuotesFinish || !this.loadOpHistoryFinish) {
        return;
      }
      const data = [ ];
      const ma5Data = [ ];
      const buyData = [ ];
      const sellData = [ ];
      const volData = [ ];
      const turnoverData = [ ];
      const turnoverMAData = [ ];
      const zscoreData = [ ];
      const trendSlopeData = [ ];
      const trendSlopeTrData = [ ];
      this.quotesTemp.data.forEach((it) => {
        data.push([ it.timestamp, it.open, it.high, it.low, it.close, ]);
        ma5Data.push([ it.timestamp, it.ma5, ]);
        buyData.push([ it.timestamp, it.buy, ]);
        sellData.push([ it.timestamp, it.sell, ]);
        volData.push([ it.timestamp, it.volume, ]);
        turnoverData.push([ it.timestamp, it.turnoverRate, ]);
        turnoverMAData.push([ it.timestamp, it.turnoverRate5MA, ]);
        zscoreData.push([ it.timestamp, it.zscore, ]);
        trendSlopeData.push([ it.timestamp, it.sellSlope, ]);
        trendSlopeTrData.push([ it.timestamp, 0.4, ]);
      });
      const opHistoryData = [ ];
      this.opHistoryTemp.data.forEach((it) => {
        opHistoryData.push({ x: it.timestamp, title: it.op, });
      });
      this.candle.options = {
        // title: { text: this.inIndicators[config.indicatorKey].name },
        plotOptions: {
          candlestick: {
            tooltip: {
              pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {series.name}</b><br/>' +
                'Open: {point.open}<br/>' +
                'High: {point.high}<br/>' +
                'Low: {point.low}<br/>' +
                'Close: {point.close}<br/>',
            }
          }
        },
        series : [ {
          id: 'mainSeries',
          name,
          type: 'candlestick',
          lineColor: 'green',
          upColor: 'red',
          upLineColor: 'red',
          data,
          tooltip: { valueDecimals: 2 },
        }, {
          name: 'operations',
          type: 'flags',
          width: 16,
          data: opHistoryData,
          color: 'black',
        }, {
          name: '5ma',
          type: 'line',
          lineWidth: 1,
          data: ma5Data,
          color: '#3E97F8',
        }, {
          name: 'buy',
          type: 'line',
          lineWidth: 1,
          data: buyData,
          color: '#F19737',
        }, {
          name: 'sell',
          type: 'line',
          lineWidth: 1,
          data: sellData,
          color: '#8747f6',
        }, {
          name: 'trendSlope',
          type: 'column',
          data: trendSlopeData,
          yAxis: 1,
          color: '#BE2F2A',
        }, {
          name: 'trendSlopeTr',
          type: 'line',
          lineWidth: 1,
          data: trendSlopeTrData,
          yAxis: 1,
        }, {
          name: 'turnover',
          type: 'column',
          data: turnoverData,
          yAxis: 2,
        }, {
          name: 'turnover5MA',
          type: 'line',
          data: turnoverMAData,
          lineWidth: 1,
          yAxis: 2,
        }, {
          name: 'zscore',
          type: 'column',
          data: zscoreData,
          yAxis: 3,
        }, {
          name: 'volume',
          type: 'column',
          data: volData,
          yAxis: 4,
        } ],
        rangeSelector: {
          buttons: [ {
            type: 'month',
            count: 1,
            text: '1m',
          }, {
            type: 'month',
            count: 3,
            text: '3m',
          }, {
            type: 'month',
            count: 6,
            text: '6m',
          }, {
            type: 'month',
            count: 9,
            text: '9m',
          }, {
            type: 'year',
            count: 1,
            text: '1y',
          }, {
            type: 'month',
            count: 18,
            text: '18m',
          }, {
            type: 'year',
            count: 3,
            text: '3y',
          } ],
          selected: 2,
        },
        tooltip: {
          dateTimeLabelFormats: {
            second: '%Y-%m-%d<br/>%H:%M:%S',
            minute: '%Y-%m-%d<br/>%H:%M',
            hour: '%Y-%m-%d<br/>%H:%M',
            day: '%Y<br/>%m-%d',
            week: '%Y<br/>%m-%d',
            month: '%Y-%m',
            year: '%Y',
          },
          split: false,
          shared: true,
          positioner() {
            return { x: 400, y: 0 };
          },
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            second: '%Y-%m-%d<br/>%H:%M:%S',
            minute: '%Y-%m-%d<br/>%H:%M',
            hour: '%Y-%m-%d<br/>%H:%M',
            day: '%m/%d',
            week: '%m/%d',
            month: '%Y-%m',
            year: '%Y',
          },
        },
        yAxis: [ {
          labels: { align: 'right', x: -3 },
          height: '54%',
          resize: { enabled: true },
          lineWidth: 1,
        }, {
          labels: { align: 'right', x: -3 },
          top: '54%',
          height: '16%',
          resize: { enabled: true },
          lineWidth: 1,
        }, {
          labels: { align: 'right', x: -3 },
          top: '70%',
          height: '10%',
          resize: { enabled: true },
          lineWidth: 1,
        }, {
          labels: { align: 'right', x: -3 },
          top: '80%',
          height: '10%',
          resize: { enabled: true },
          lineWidth: 1,
        }, {
          labels: { align: 'right', x: -3 },
          top: '90%',
          height: '10%',
          resize: { enabled: true },
          lineWidth: 1,
        } ],
        navigator: {
          enabled: true,
          height: 20,
        },
        credits: {
          enabled: false,
        },
      };
      this.candle.loading = false;
    },
  },
  mounted() {
    this.candle.loading = true;
    this.loadBreaks(() => {
      if (this.breakData.items.length > 0) {
        this.loadStock(this.breakData.items[0].code, this.breakData.items[0].name);
      }
    });
  },
};
</script>

