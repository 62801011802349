/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { doPost, doGet } from './ServerTools';

export function pageQueryStreams() {
  return doGet('/api/stream/page_query_streams');
}

export function createStream(data) {
  return doPost('/api/stream/create_stream', data);
}

export function updateStream(data) {
  return doPost('/api/stream/update_stream', data);
}

export function deleteStream(data) {
  return doPost('/api/stream/delete_stream', data);
}

export function queryStreamById(streamId) {
  return doGet(`/api/stream/query_stream_by_id?stream_id=${streamId}`);
}

export function queryStreamByName(name) {
  return doGet(`/api/stream/query_stream_by_name?name=${name}`);
}

export function queryStreamNodes(streamId) {
  return doGet(`/api/stream/query_stream_nodes?stream_id=${streamId}`);
}

export function queryNodeByName(streamId, name) {
  return doGet(`/api/stream/query_node_by_name?stream_id=${streamId}&name=${name}`);
}

export function convertModelToNode(model, x, y) {
  const inPorts = [ ];
  const outPorts = [ ];
  const lines = [ ];
  model.IN.forEach((param) => {
    inPorts.push({ uuid: param._id, name: param.name });
  });
  model.OUT.forEach((param) => {
    outPorts.push({ uuid: param._id, name: param.name });
    if (param.connect) {
      param.connect.forEach((connect) => {
        lines.push([ model._id, param._id, connect.node_id, connect.param_id ]);
      });
    }
  });
  return [ {
    name: model.name,
    uuid: model._id,
    pos: { left: x, top: y },
    inPorts,
    outPorts,
    nodeType: model.node_type,
    width: (Math.max(inPorts.length, outPorts.length) + 1) * 60,
  }, lines ];
}

export function buildGraph(data, posData) {
  const nodesMap = {};
  const lines = [ ];
  data.forEach((it) => {
    const [ node, tempLines ] = convertModelToNode(
      it,
      it._id in posData ? posData[it._id].left : 300,
      it._id in posData ? posData[it._id].top : 200,
    );
    nodesMap[node.uuid] = node;
    lines.push(...tempLines);
  });
  return [ nodesMap, lines ];
}

export function updateStreamRawGraph(streamId, posData) {
  return doPost('/api/stream/update_stream_rawgraph', {
    _id: streamId,
    rawgraph: JSON.stringify({ posData }),
  });
}

export function createEmptyNode(data) {
  return doPost('/api/stream/create_empty_node', data);
}

export function removeNode(nodeId) {
  return doPost('/api/stream/remove_node', { node_id: nodeId });
}

export function updateNode(data) {
  return doPost('/api/stream/update_node', data);
}

export function remote_saveScript(nodeId, script) {
  return doPost('/api/stream/save_node_script', {
    node_id: nodeId,
    script: script,
  });
}

export function remote_saveChartSettings(nodeId, settings) {
  return doPost('/api/stream/save_chart_settings', {
    node_id: nodeId,
    chart_settings: JSON.stringify(settings),
  });
}

export function remote_queryNode(nodeid) {
  return doGet(`/api/stream/query_node?node_id=${nodeid}`);
}

export function remote_addNewPort(nodeid, name, inOrOut) {
  return doPost(`/api/stream/add_${inOrOut}_param`, {
    node_id: nodeid,
    name,
    type_def: 'int',
  });
}

export function remote_updateParam(data) {
  return doPost('/api/stream/update_param', data);
}

export function remote_addNewOption(nodeId, name) {
  return doPost('/api/stream/add_option', {
    node_id: nodeId,
    name,
    type_def: 'int',
  });
}

export function remote_updateOption(data) {
  return doPost('/api/stream/update_option', data);
}

export function connectParam(streamId, outNodeId, outParamId, inNodeId, inParamId) {
  return doPost('/api/stream/connect_param', {
    stream_id: streamId,
    upstream_node_id: outNodeId,
    upstream_param_id: outParamId,
    downstream_node_id: inNodeId,
    downstream_param_id: inParamId,
  });
}

export function removeConnection(streamId, outNodeId, outParamId, inNodeId, inParamId) {
  return doPost('/api/stream/remove_connection', {
    stream_id: streamId,
    upstream_node_id: outNodeId,
    upstream_param_id: outParamId,
    downstream_node_id: inNodeId,
    downstream_param_id: inParamId,
  });
}

export function remote_submitExecute(stream_id) {
  return doPost('/api/stream/submit_execute', {
    stream_id,
  });
}

export function remote_queryExecuteState(stream_id) {
  return doGet(`/api/stream/query_execute_state?stream_id=${stream_id}`);
}

export function remote_queryStreamExeResult(stream_id) {
  return doGet(`/api/stream/query_stream_exe_result?stream_id=${stream_id}`);
}

export function remote_queryNodeExeResult(stream_id, node_id, mode='NON_REALTIME', params={}) {
  if (mode !== undefined && mode === 'REALTIME') {
    return doPost('/api/stream/query_node_exe_result_rt', {
      stream_id,
      node_id,
      params,
    });
  } else {
    return doGet(`/api/stream/query_node_exe_result_nrt?stream_id=${stream_id}&node_id=${node_id}`);
  }
}
