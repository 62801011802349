<template>
  <div class="flxcolumn body roundcorner">
    <div class="title">
      Fed Fund Rates Probabilities
      <div class="record link-text" @click="showRecordForm">Manual Record</div>
      <DataSource :sources="sources" />
    </div>
    <highcharts
        class="flx1"
        :options="chartOptions"
        style="height: 300px"
      />

    <a-modal
        :visible="modalVisible" @ok="onModalOk"
        :closable="false"
        :maskClosable="false"
        :footer="null"
        >
      <a-date-picker style="width: 240px; margin: 0px 0px 8px 0px;" v-model="recordDate" />
      <a-textarea v-model="recordContent" placeholder="Rate Probabilitiies" :rows="8" />

      <div class="flxrow margintb2">
        <div class="flxalignright">
          <button class="btn btn-p1 bg-gradient-secondary" @click="onCancel" > Cancel </button>
          <button style="margin-left: 24px;"
            class="btn btn-p1 bg-gradient-dark" @click="onSave" > Save </button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { doPostN, doGetN, } from '@/script/ServerTools';
import DataSource from '@/components/charts/DataSource.vue';
import { timestampToFormatDateTime, timestampToFormatDate2, } from '@/script/DateTools';

export default {
  name: 'FedRateExp',
  components: { DataSource, },
  data() {
    return {
      sources: [ 'https://www.cmegroup.com/markets/interest-rates/cme-fedwatch-tool.html?redirect=/trading/interest-rates/countdown-to-fomc.html', ],
      series: [ ],
      colors: [
        '#c41d7f',
        // '#002c8c',
        '#003eb3',
        '#0958d9',
        '#1677ff',
        '#4096ff',
        '#69b1ff',
        '#91caff',
        '#bae0ff',
      ],
      days: [0, 1, 2, 3, 5, 10, 30, 60],
      modalVisible: false,
      recordDate: null,
      recordContent: '',
    };
  },
  computed: {
    chartOptions() {
      return {
        chart: { type: 'line' },
        title: { text: null },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            millisecond: this.xFormat === 'day' ? '%Y/%m/%d' : '%H:%M:%S.%L',
            second: this.xFormat === 'day' ? '%Y/%m/%d' : '%Y/%m/%d<br/>%H:%M:%S',
            minute: this.xFormat === 'day' ? '%Y/%m/%d' : '%Y/%m/%d<br/>%H:%M',
            hour: this.xFormat === 'day' ? '%Y/%m/%d' : '%Y/%m/%d<br/>%H:%M',
            day: '%Y/%m/%d',
            week: '%Y/%m/%d',
            month: '%Y-%m',
            year: '%Y',
          },
        },
        legend: {
          enabled: true,
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
        },
        yAxis: {
          allowDecimals: false,
          title: { text: 'Count medals' }
        },
        tooltip: {
          xDateFormat: '%Y-%m-%d %H:%M',
          split: false,
          shared: true,
        },
        series: this.series,
        credits: { enabled: false, },
      };
    },
  },
  methods: {
    load() {
      doPostN('/api/morpho/rates/rates_exp', this.days).then((rsp) => {
        this.series = [ ] ;
        let i = 0;
        rsp.result.forEach((list) => {
          const data = [ ];
          let dataDate = null;
          list.forEach((it) => {
            dataDate = it.dataDate;
            data.push([ Date.parse(it.meetingDate) + 3600000 * 8, it.expectation ]);
          });
          const day = this.days[i];
          this.series.push({
            name: timestampToFormatDate2(moment(dataDate).unix()).substring(5),
            data, color: this.colors[i], });
          i += 1;
        });
      });
    },
    showRecordForm() {
      this.modalVisible = true;
      this.recordContent = '';
      this.recordDate = moment();
    },
    onModalOk() {
      this.modalVisible = false;
    },
    onCancel() {
      this.modalVisible = false;
    },
    onSave() {
      doPostN('/api/morpho/rates/manual_record', {
        date: timestampToFormatDateTime(this.recordDate.unix()), content: this.recordContent,
      }).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
        this.modalVisible = false;
        this.load();
      });
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.title {
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  font-family: "Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
  position: relative;
  min-height: 20px;
}
.record {
  position: absolute;
  left: 16px;
  top: 3px;
  font-size: 13px;
  z-index: 1000;
}
.body {
  padding: 8px;
  background-color: #ffffff;
}
</style>