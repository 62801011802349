<template>
  <div>
    <div class="card card-background shadow-none card-background-mask-danger" >
      <div class="full-background" :style="{backgroundImage: 'url(' + require('../../assets/white-curved.jpeg') + ')'}"></div>
      <div class="card-body text-start p-2 w-100" >
        <div style="cursor: pointer; margin: 8px 0px;" @click="onShowFactDrawer(fact)" >
          <h6 class="text-white font-weight-bolder mb-2" > {{ fact.name }} </h6>
          <p class="text-white mb-2"> {{ fact.summary }} </p>
        </div>
        <div style="display: flex">
          <p style="margin: 0px;">{{ formatDate(fact.gmt_occur) }}</p>
          <p v-for="story in fact.stories" :key="story._id"
              class="link-text"
              style="font-size: 12px; margin: 2px 0px 0px 8px;"
              @click="onShowStoryDrawer(story)"
          >
            {{ story.name }}
          </p>
        </div>
      </div>
    </div>

    <a-drawer
        :width="factDrawerWidth" placement="right" :closable="false"
        :visible="factDrawerVisible"
        @close="onCloseFactDrawer"
        style="z-index: 100;"
        :bodyStyle="{background: 'rgb(245, 245, 245)', padding: '18px', minHeight: '100%'}" >
      <div>
        <!-- header start -->
        <div style="padding: 0px 6px;">
          <div style="margin: 0px; display: flex; align-items: center;">
            <h4 v-if="!factDrawerEditMode"> {{ factDrawerData.name }} </h4>
            <a-input v-else v-model="factDrawerData.name" style="width: 600px;" />

            <div style="margin-left: auto;">
              <button v-if="!factDrawerEditMode" class="btn btn-p1 bg-gradient-dark" @click="onStartEditFact" >Edit</button>
              <button v-else class="btn btn-p1 bg-gradient-dark" @click="onEndEditFact" >Save</button>
              <!-- delete button -->
              <a-popconfirm
                title="Are you sure delete this?"
                ok-text="Yes" cancel-text="No"
                @confirm="onDeleteFact" >
                <button v-if="!factDrawerEditMode" class="btn btn-p1 bg-gradient-danger" style="margin-left: 8px;" >Delete</button>
              </a-popconfirm>
            </div>
          </div>
          <div>
            <p v-if="!factDrawerEditMode"><i> {{ factDrawerData.summary }} </i></p>
            <a-textarea v-else v-model="factDrawerData.summary" style="margin: 12px 0px 12px 0px; width: 600px;" />
          </div>
        </div>
        <!-- header end -->

        <!-- 正文 -->
        <div class="card" style="height: 100%; margin-top: 12px;" >
          <div class="card-content">
            <!-- chart title start -->
            <div style="display: flex; align-items: center; margin-bottom: 12px;">
              <h6> 正文 </h6>
              <div style="margin-left: auto;"> </div>
            </div>
            <!-- chart title end  -->
            <div v-if="!factDrawerEditMode" v-html="factDrawerData.content"> </div>
            <mr-quill-editor v-else v-model="factDrawerData.content" />
          </div>
        </div>
      </div>
    </a-drawer>

    <a-drawer
        :width="storyDrawerWidth" placement="right" :closable="false"
        :visible="storyDrawerVisible"
        @close="onCloseStoryDrawer"
        style="z-index: 10;"
        :bodyStyle="{background: 'rgb(245, 245, 245)', padding: '18px', minHeight: '100%'}" >
      <div>
        <!-- header start -->
        <div style="padding: 0px 6px;">
          <div style="margin: 0px; display: flex; align-items: center;">
            <h4 v-if="!storyDrawerEditMode"> {{ storyDrawerData.name }} </h4>
            <a-input v-else v-model="storyDrawerData.name" style="width: 600px;" />
            <div style="margin-left: auto;">
              <button v-if="!storyDrawerEditMode" class="btn btn-p1 bg-gradient-dark" @click="onStartEditStory" >Edit</button>
              <button v-else class="btn btn-p1 bg-gradient-dark" @click="onEndEditStory" >Save</button>
              <!-- delete button -->
              <a-popconfirm
                title="Are you sure delete this?"
                ok-text="Yes" cancel-text="No"
                @confirm="onDeleteStory" >
                <button v-if="!storyDrawerEditMode" class="btn btn-p1 bg-gradient-danger" style="margin-left: 8px;" >Delete</button>
              </a-popconfirm>
            </div>
          </div>
          <div>
            <p v-if="!storyDrawerEditMode"><i> {{ storyDrawerData.summary }} </i></p>
            <a-textarea v-else v-model="storyDrawerData.summary" style="margin: 12px 0px 12px 0px; width: 600px;" />
          </div>
        </div>
        <!-- header end -->

        <div style="height: 100%; overflow-y: scroll;" >
          <div v-for="(fact, index) in storyDrawerFacts " :key="fact._id"
                v-bind:class="{'tp-1': index !== 0, 'bp-1': index !== storyDrawerFacts.length - 1 }" >
            <div class="card card-background shadow-none card-background-mask-danger" >
              <div class="full-background"
                  :style="{backgroundImage: 'url(' + require('../../assets/white-curved.jpeg') + ')'}"></div>
              <div class="card-body text-start p-2 w-100" >
                <div style="cursor: pointer; margin: 8px 0px;" @click="onShowFactDrawer(fact)" >
                  <h6 class="text-white font-weight-bolder mb-2" > {{ fact.name }} </h6>
                  <p class="text-white mb-2"> {{ fact.summary }} </p>
                </div>

                <p style="margin: 0px;">{{ formatDate(fact.gmt_occur) }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { timestampToFormatDate } from '@/script/DateTools';
import { doPost, doGet } from '@/script/ServerTools';
import MrQuillEditor from '../../components/MrQuillEditor.vue';

export default {
  name: 'StoryItem',
  props: [ 'fact', ],
  components: {
    MrQuillEditor,
  },
  data() {
    return {
      showDrawerCount: 0,
      factDrawerVisible: false,
      factDrawerData: { },
      factDrawerEditMode: false,
      factDrawerWidth: 800,

      storyDrawerVisible: false,
      storyDrawerData: { },
      storyDrawerEditMode: false,
      storyDrawerFacts: [ ],
      storyDrawerWidth: 800,
    };
  },
  methods: {
    formatDate(ts) {
      return timestampToFormatDate(ts);
    },
    // ============================
    onShowFactDrawer(fact) {
      this.showDrawerCount += 1;
      this.factDrawerWidth = this.showDrawerCount === 1 ? 800 : 700;
      if (fact._id !== this.factDrawerData._id) {
        this.factDrawerEditMode = false;
      }
      this.factDrawerData = fact;
      this.factDrawerVisible = true;
    },
    onCloseFactDrawer() {
      this.factDrawerVisible = false;
      this.showDrawerCount -= 1;
    },
    onStartEditFact() {
      this.factDrawerEditMode = true;
    },
    onEndEditFact() {
      this.factDrawerEditMode = false;
      this.saveFact();
    },
    onDeleteFact() {

    },
    // ============================
    onShowStoryDrawer(story) {
      this.storyDrawerData = story;
      this.showDrawerCount += 1;
      this.storyDrawerWidth = this.showDrawerCount === 1 ? 800 : 700;
      this.storyDrawerData.facts = [ ];
      this.storyDrawerFacts = [ ];
      this.loadFactsForStory(story._id);
      this.storyDrawerVisible = true;
    },
    onCloseStoryDrawer() {
      this.storyDrawerVisible = false;
      this.showDrawerCount -= 1;
    },
    onStartEditStory() {
    },
    onEndEditStory() {
    },
    onDeleteStory() {
    },
    // ============================
    saveFact() {
      doPost('/api/story/updateFact', this.factDrawerData).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
      });
    },
    loadFactsForStory(storyId) {
      doGet('/api/story/queryFacts', { story_id: storyId, page_size: 20, page_no: 0, }).then((rsp) => {
        this.storyDrawerFacts = rsp.data.items;
      });
    },
  },
};
</script>
