<template>
  <div class="flxcolumn body roundcorner">
    <highcharts v-if="loadSuccess" :options="chartOptions" class="height100p"/>
  </div>
</template>

<script>
// import Highcharts from 'highcharts/highstock';
import { uuid4, } from '@/script/CommonTools';
import { getValue, getValues, getDef, } from '@/script/IndicatorAPIs';

export default {
  name: 'IndicatorPieChart',
  props: [ 'inhertParentHeight', 'data', 'yTitle', 'legend', 'xFormat', 'type', 'showValue', 'rangeSelector', 'rangeSelectorSelected', ],
  data() {
    return {
      chartId: null,
      chartOptions: null,
      indicators: [ {
        code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_GOODS_PRODUCING', downdrill: [
          'US_NONFARM_PAYROLL_MONTH_CHANGE_MINING_AND_LOGGING',
          'US_NONFARM_PAYROLL_MONTH_CHANGE_CONSTRUCTION',
          'US_NONFARM_PAYROLL_MONTH_CHANGE_MANUFACTURING',
        ]
      }, {
        code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_PRIVATE_SERVICE_PROVIDING', downdrill: [
          'US_NONFARM_PAYROLL_M_CHANGE_TRADE_TRANSPORTATION_AND_UTILITIES',
          'US_NONFARM_PAYROLL_MONTH_CHANGE_INFORMATION',
          'US_NONFARM_PAYROLL_MONTH_CHANGE_FINANCIAL_ACTIVITIES',
          'US_NONFARM_PAYROLL_M_CHANGE_PROFESSIONAL_AND_BUSINESS_SERVICES',
          'US_NONFARM_PAYROLL_MONTH_CHANGE_EDUCATION_AND_HEALTH_SERVICES',
          'US_NONFARM_PAYROLL_MONTH_CHANGE_LEISURE_AND_HOSPITALITY',
          'US_NONFARM_PAYROLL_MONTH_CHANGE_OTHER_SERVICES',
        ]
      }, {
        code: 'US_NONFARM_PAYROLL_MONTH_CHANGE_GOVERNMENT', downdrill: [
          'US_NONFARM_PAYROLL_MONTH_CHANGE_Federal',
          'US_NONFARM_PAYROLL_MONTH_CHANGE_State_government',
          'US_NONFARM_PAYROLL_MONTH_CHANGE_Local_government',
        ],
      }, ],
      indicatorData: [],
      loadProcess: -1,
      loadSuccess: false,
      downdrillData: {},
    };
  },
  computed: {
  },
  methods: {
    load() {
      if (this.indicators === null) {
        setTimeout(() => this.load(), 500);
        return;
      }

      this.loadProcess = this.indicators.length;
      this.indicatorData = [ ];
      this.sources = [ ];

      this.indicators.forEach((indicator) => {
        const code = indicator.code;
        getDef(code).then((rsp) => {
          const def = rsp.result;
          if (def.source !== null && def.source !== undefined && def.source !== '') {
            this.sources.push(def.source);
          }
          getValue(code).then((response) => {
            this.indicatorData.push({
              name: def.name,
              y: response.result.value,
              drilldown: def.name,
            });
            this.process();
          });

          this.downdrillData[def.name] = { name: def.name, id: def.name, data: [], };
          indicator.downdrill.forEach((childCode) => {
            getDef(childCode).then((childRsp) => {
              const childDef = childRsp.result;
              getValue(childCode).then((childResponse) => {
                this.downdrillData[def.name].data.push([ childDef.name, childResponse.result.value, ]);
              });
            });
          });
        });
      });
    },
    process() {
      this.loadProcess -= 1;
      if (this.loadProcess === 0) {
        this.loadSuccess = true;
        this.initOptions();
        // setTimeout(() => {
        //   Highcharts.stockChart(this.chartId, this.chartOptions);
        // }, 1000);
      }
    },
    initOptions() {
      const opts = {
        chart: { type: 'pie' },
        title: { text: '非农就业当月新增', align: 'center' },
        subtitle: { },
        accessibility: {
          announceNewData: { enabled: true },
          point: { valueSuffix: '%' }
        },
        plotOptions: {
          series: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: [{
                enabled: true,
                distance: 20
            }, {
                enabled: true,
                distance: -40,
                format: '{point.percentage:.1f}%',
                style: {
                    fontSize: '1.2em',
                    textOutline: 'none',
                    opacity: 0.7
                },
                filter: {
                    operator: '>',
                    property: 'percentage',
                    value: 10
                }
            }]
          }
        },
        navigator: {
          enabled: false,
        },
        rangeSelector: {
          enabled: false,
        },
        tooltip: {
          headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
          pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y} K</b> <br/>'
        },
        series: [
          {
            name: '非农当月新增',
            colorByPoint: true,
            data: this.indicatorData,
          }
        ],
        drilldown: { series: Object.values(this.downdrillData) },
        credits: { enabled: false, },
      };
      this.chartOptions = opts;
    },
  },
  mounted() {
  },
  created() {
    this.chartId = uuid4();
    this.load();
  },
};
</script>

<style scoped>
.height100p {
  height: 100%;
}
.body {
  padding: 8px;
  background-color: #ffffff;
}
</style>