<template>
  <div style="display: flex">
    <div :id="node._id + 'container'" class="node-parent">
      <div
        :id="node._id"
        class="doc-node flxrow"
        @dblclick="gotoDocDetail(node._id)"
        @contextmenu.prevent="onOpenMenu($event, node)"
      >
        <div class="flxcolumn">
          <div :id="node._id + '_start'" style="width: 1px; height: 1px; margin-top: auto; margin-bottom: auto;"></div>
        </div>
        <div v-if="node.display_content" v-html="node.content" class="doc-node-content"/>
        <div v-else class="doc-node-content">
          {{ node.name }}
        </div>
        <div class="flxcolumn">
          <div :id="node._id + '_end'" style="width: 1px; height: 1px; margin-top: auto; margin-bottom: auto;"></div>
        </div>
      </div>
    </div>

    <div
      :id="node._id + 'children'"
      v-if="node.children"
      style="display: flex; flex-direction: column"
    >
      <div v-for="child in node.children" :key="child._id">
        <doc-node
          :node="child"
          :root="false"
          :clickEvent="clickEvent"
          :refreshEvent="refreshEvent"
          @refreshTree="onRefreshTree"
          @rerenderTree="onReRender"
          @dropBesidesChild="onDropBesidesChild"
        />
      </div>
    </div>

    <ul
      v-if="menuVisible"
      :style="{ left: menuLeft + 'px', top: menuTop + 'px' }"
      class="contextmenu"
    >
      <li @click="handleAdd">Add Child</li>
      <li @click="handleRename">Rename</li>
      <li v-if="!node.display_content" @click="handleDisplayContent"> Display Content </li>
      <li v-else @click="handleHideContent">Hide Content</li>
      <li @click="handleMove(-1)">⬆</li>
      <li @click="handleMove(1)">⬇</li>
      <li @click="handleDelete">Delete</li>
    </ul>

    <!-- Add repo form modal -->
    <el-dialog :title="formTitle" :visible.sync="addFormVisible">
      <el-form
        :model="addFormData"
        ref="addForm"
        :rules="addFormRules"
        label-position="top"
        label-width="100px"
        size="medium"
      >
        <el-form-item label="Name" prop="name">
          <el-input
            v-model="addFormData.name"
            autocomplete="off"
            placeholder="enter name"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeAddForm">取 消</el-button>
        <el-button type="primary" size="small" @click="submitAdd"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <a-drawer
      width="900"
      placement="right"
      :closable="false"
      :visible="currentDocVisible"
      @close="onCloseDocDrawer"
      :bodyStyle="{
        // background: 'rgb(245, 245, 245)',
        background: 'white',
        padding: '0px',
        height: '100%',
      }"
    >
      <doc-editor :docId="currentDocId" />
    </a-drawer>
  </div>
</template>

<script>
import { jsPlumb } from 'jsplumb';
import {
  queryRepoById,
  queryDocsByRepo,
  createDocument,
  updateDocument,
  deleteDocument,
  queryDocumentById,
  saveDocumentContent,
} from '@/script/DocOperations';
import {} from '@/script/DocOperations';
import DocEditor from './DocEditor.vue';
import { doPost } from '../../script/ServerTools';

export default {
  name: 'DocNode',
  props: ['node', 'clickEvent', 'refreshEvent', 'root'],
  components: { DocEditor, },
  data() {
    return {
      currentDocId: '',
      currentDocVisible: false,
      menuVisible: false,
      menuTop: 0,
      menuLeft: 0,
      menuItem: null,
      // add form
      renaming: false,
      formTitle: '',
      addFormVisible: false,
      addFormData: { name: '', parent_id: '' },
      addFormRules: {
        name: [
          {
            required: true,
            message: 'Name cannot be empty',
            trigger: 'change',
          },
        ],
      },
      conns: [],
      dragOverElementId: '',
    };
  },
  methods: {
    handleMove(direction) {
      doPost('/api/doc/document/moveNode', {
        nodeId: this.node._id,
        direction,
      }).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
        this.onRefreshTree();
      });
    },
    // drag
    onDragStart(e, node) {
      console.log('start');
      e.dataTransfer.setData('node', node);
      e.dataTransfer.setData('nodeId', node._id);
    },
    onDragEnter(elementId) {
      this.dragOverElementId = elementId;
    },
    onDragLeave(elementId) {
      this.dragOverElementId = '';
    },
    onDropBefore(nodeId) {
      if (nodeId === this.node._id) {
        return;
      }
      this.$emit('dropBesidesChild', nodeId, 'Before', this.node._id);
    },
    onDropAfter(nodeId) {
      if (nodeId === this.node._id) {
        return;
      }
      this.$emit('dropBesidesChild', nodeId, 'After', this.node._id);
    },
    onDropBesidesChild(nodeId, pos, childId) {
      const payload = {
        parentId: this.node._id,
        nodeId: nodeId,
        anchor: childId,
        pos: pos,
      };
      console.log(payload);
      doPost('/api/doc/document/rearrange', payload).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
        this.onRefreshTree();
      });
    },
    // ======== event ========
    onRefreshTree() {
      this.$emit('refreshTree');
    },
    onReRender() {
      this.$emit('rerenderTree');
    },
    // ======== handler ========
    gotoDocDetail(docid) {
      // this.$router.push(`/docdetail/${docid}`);
      this.currentDocId = docid;
      this.currentDocVisible = true;
    },
    onCloseDocDrawer() {
      this.currentDocVisible = false;
    },
    onOpenMenu(e, node) {
      console.log(e);
      this.menuTop = e.layerY;
      this.menuLeft = e.layerX;
      this.menuItem = node;
      this.menuVisible = true;
    },
    handleAdd() {
      this.formTitle = 'Crate new doc';
      this.addFormData.name = '';
      this.menuVisible = false;
      this.renaming = false;
      this.showNewDocForm(this.menuItem._id);
    },
    handleRename() {
      this.formTitle = 'Rename';
      this.addFormData.name = this.node.name;
      this.menuVisible = false;
      this.renaming = true;
      this.showNewDocForm(this.menuItem._id);
    },
    handleDelete() {
      this.menuVisible = false;
      deleteDocument({ _id: this.node._id }).then((rsp) => {
        this.$message({ message: rsp.message, type: 'success' });
        this.onRefreshTree();
      });
    },
    handleUp() {
      this.menuVisible = false;
    },
    handleDown() {
      this.menuVisible = false;
    },
    handleDisplayContent() {
      this.displayContent(true);
    },
    handleHideContent() {
      this.displayContent(false);
    },
    // ======== add form ========
    showNewDocForm(parentId) {
      this.addFormData.parent_id = parentId;
      this.addFormVisible = true;
    },
    closeAddForm() {
      this.addFormVisible = false;
      this.$refs.addForm.resetFields();
    },
    // ======== remote ========
    loadContent() {
      queryDocumentById(this.node._id).then((data) => {
        this.content = data.data.content;
      });
    },
    submitAdd() {
      this.$refs.addForm.validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.renaming) {
          updateDocument({
            _id: this.node._id, name: this.addFormData.name, repoid: this.node.repo_id,
          }).then((rsp) => {
            this.closeAddForm();
            this.$message({ message: rsp.message, type: 'success' });
            this.onRefreshTree();
          });
        } else {
          const formData = this.addFormData;
          formData.repoid = this.node.repo_id;
          // Add Doc
          console.log('==== [Add Doc] =====');
          console.log(formData);
          createDocument(formData).then((data) => {
            this.closeAddForm();
            this.$message({ message: data.message, type: 'success' });
            this.onRefreshTree();
          });
        }
      });
    },
    displayContent(display) {
      updateDocument({
        _id: this.node._id,
        display_content: display,
      }).then((rsp) => {
        this.onReRender();
      });
    },
    // ============== connect methods =============
    clearConns() {
      let plumbIns = jsPlumb.getInstance();
      this.conns.forEach((conn) => {
        plumbIns.deleteConnection(conn, {});
      });
      this.conns = [];
      // plumbIns.reset();
    },
    buildConns2() {
      const canvas = document.getElementById('canvas');
      const ctx = canvas.getContext("2d");
      const left = document.getElementById(this.node._id + '_end');
      const startX = left.offsetParent.offsetLeft + left.offsetLeft - 0;
      const startY = left.offsetParent.offsetTop + left.offsetTop - 0;
      if (this.node.children) {
        const level = this.node.children.length;
        const D = level >= 6 ? (level >= 8 ? (level >= 10 ? 36 : 32) : 24) : 16;
        this.node.children.forEach((child, index) => {
          var delta = Math.floor(D / 3 + 2 * D / 3 / level * Math.abs(level - index));
          const right = document.getElementById(child._id + '_start');
          const endX = right.offsetParent.offsetLeft + right.offsetLeft - 0;
          const endY = right.offsetParent.offsetTop + right.offsetTop - 0;
          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.bezierCurveTo(startX + delta, startY, endX - delta, endY, endX, endY);
          // console.log('(', startX, ',', startY, '), (', startX + delta, ',', startY,'), (', endX - delta, ',',endY,'), (', endX, ',',endY, ')');
          ctx.stroke();
        });
      }
    },
    buildConns() {
      const hasChildren = this.node.children && this.node.children.length > 1;
      const left = document.getElementById(this.node._id + 'container');
      const right = document.getElementById(this.node._id + 'children');
      if (hasChildren && left.offsetHeight < right.offsetHeight) {
        console.log(this.node.name);
        left.style.height = right.offsetHeight + 'px';
      }
      jsPlumb.setContainer('#repoTreeContainer');
      let plumbIns = jsPlumb.getInstance();
      let params = {
        anchor: ['Left', 'Right'],
        connector: '',
        endpoint: 'Blank',
        paintStyle: { stroke: '#909399', strokeWidth: 1.5 }, // connector
      };
      if (hasChildren) {
        params.connector = ['Bezier', { curviness: 20 }];
      } else {
        params.connector = 'Straight';
      }
      if (this.node.children) {
        this.node.children.forEach((child) => {
          const conn = plumbIns.connect({
            source: this.node._id,
            target: child._id,
            ...params,
          });
          this.conns.push(conn);
        });
      }
    },
  },
  watch: {
    clickEvent(val1, val2) {
      if (this.menuVisible) {
        this.menuVisible = false;
      }
    },
    refreshEvent(val1, val2) {
      // this.clearConns();
      // this.buildConns();
      this.buildConns2();
    },
  },
  mounted() {
    // let plumbIns = jsPlumb.getInstance();
    // plumbIns.ready(() => {
    //   setTimeout(() => this.buildConns(), 100);
    // });
  },
};
</script>

<style scoped>
.node-parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0px 24px 0px 8px;
}
.doc-node {
  font-size: 14px;
  background-color: #f8f8f8;
  border-radius: 8px;
  margin: 4px 0px;
  user-select: none;
}
.doc-node-content {
  padding: 3px 8px;
}
.contextmenu {
  margin: 0;
  background: #e6e6e5;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  font-family:
    -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,
    "Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji",
    "Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}
.contextmenu li {
  margin: 0;
  margin: 2px 8px;
  padding: 0px 6px;
  border-radius: 3px;
}
</style>
