import axios from 'axios';

export function uploadImage(file) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);
    axios({
      url: '/api/image/upload',
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then((rsp) => {
      resolve(rsp.data.data.url);
    });
  });
}
