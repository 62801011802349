
/**
 * call lot tasks smoothly
 * @param {array} tasks the tasks
 * @param {number} delay interval time for execution
 */
export function smoothCall(tasks, delay) {
  function run() {
    const t = tasks.shift();
    if (t !== undefined) {
      t.func(...t.args);
      setTimeout(run, delay);
    }
  }
  setTimeout(run, delay);
}
